import { UIActions, START_LOADING, STOP_LOADING, SET_PRODUCT_INFO } from './ui.actions';

export interface State {
    isLoading: boolean;
    product: {
        name: string,
        term: string,
        termPlural: string
    };
}

const initialState: State = {
    isLoading: false,
    product: {
        name: 'BOD',
        term: 'Review',
        termPlural: 'Reviews'
    }
}

export function uiReducer(state = initialState, action: UIActions) {
    switch (action.type) {
        case START_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case STOP_LOADING:
            return {
                ...state,
                isLoading: false
            };
        case SET_PRODUCT_INFO:
            return {
                ...state,
                product: action.payload
            };
        default: {
            return state;
        }
    }
}

export const getIsLoading = (state: State) => state.isLoading;
export const getProductInfo = (state: State) => state.product;
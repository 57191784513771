import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable, of, ReplaySubject, throwError } from 'rxjs';

import { UIService } from './ui.service';
import { BaseService } from './base.service';
import { Member, MemberStatus } from '@core/models/member.model';
import { Organisation } from '@core/models/org.model';

import { Store } from '@ngrx/store';
import * as fromRoot from '@core/store/app.reducer';
import { UserData, UserOrg } from '@core/models';
import { IDocModel } from '@core/models/base.model';
import {
  catchError,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { Invite } from '@core/models/invite.model';

@Injectable()
export class InviteService extends BaseService {
  constructor(
    private db: AngularFirestore,
    private uiService: UIService,
    private store: Store<fromRoot.State>
  ) {
    super('invites', db);
  }

  // ***************
  // Getters/Setters
  // ***************

  //***************
  // Functions:
  //***************

  getInvite(inviteId: string): Observable<Invite> {
    return this.get<Invite>(inviteId);
  }

  getInvitesForTeam(teamId: string): Observable<Invite[]> {
    return this.list('invites', (ref) =>
      ref.where('team.id', '==', teamId).where('userId', '==', '')
    );
  }

  getInvitesForEmail(email: string): Observable<Invite[]> {
    return this.list('invites', (ref) =>
      ref.where('email', '==', email).where('userId', '==', '')
    );
  }

  createInvite(invite: Invite): Observable<Invite | string> {
    return this.list<Invite>('invites', (ref) =>
      ref
        .where('email', '==', invite.email)
        .where('team.id', '==', invite.team.id)
        .where('userId', '==', null)
    ).pipe(
      take(1),
      switchMap((invites) => {
        if (invites.length) {
          throw 'An invite already exists for this email address.';
        } else {
          return from(this.addOrUpdate<Invite>({ ...invite, userId: '' }));
        }
      }),
      catchError((error) => of(error))
    );
  }

  updateInvite(invite: Invite): Observable<boolean> {
    return from(this.update<Invite>(invite)).pipe(
      mergeMap(() => of(true)),
      catchError((error) => throwError(error))
    );
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { OrgService } from '@core/services';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as fromRoot from '@core/store/app.reducer';
import * as UI from '@core/store/ui.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from './snackbar.component';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @ViewChild('drawer') drawer: MatDrawer;
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  panels: any[] = [];
  selectedPanel: string = 'account';
  isLoading$: Observable<boolean>;
  role$: Observable<string>;

  constructor(
    private _orgService: OrgService,
    private _snackBar: MatSnackBar,
    private _store: Store<{ ui: fromRoot.State }>
  ) {}

  ngOnInit(): void {
    this._store.dispatch(new UI.StartLoading());
    this.role$ = this._store.select(fromRoot.getRole);
    this.isLoading$ = this._store.select(fromRoot.getIsLoading);

    this.role$
      .pipe(
        take(1),
        map((role) => {
          // Setup available panels
          this.panels = [
            {
              id: 'account',
              icon: 'heroicons_outline:user-circle',
              title: 'Account',
              description: 'Manage your private information',
              canAccess: true,
            },
            {
              id: 'email',
              icon: 'heroicons_outline:lock-closed',
              title: 'Email',
              description: 'Manage your email address',
              canAccess: true,
            },
            {
              id: 'security',
              icon: 'heroicons_outline:lock-closed',
              title: 'Security',
              description: 'Manage your password',
              canAccess: true,
            },
          ];

          /*
          if (member.roleId === 2) { // Admin
            this.panels.push({
              id: 'plan-billing',
              icon: 'heroicons_outline:credit-card',
              title: 'Plan & Billing',
              description: 'Manage your subscription plan, payment method and billing information',
              canAccess: true,
            })
          }
          */

          /* 
          {
            id: 'notifications',
            icon: 'heroicons_outline:bell',
            title: 'Notifications',
            description: 'Manage when you\'ll be notified on which channels',
            canAccess: true,
            },
            */

          this._store.dispatch(new UI.StopLoading());
        })
      )
      .subscribe();
  }

  openSnackBar($event) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: 5000,
      data: $event,
    });
  }
}

import { CapabilityService } from './capability.service';
import { LoggingService } from './logging.service';
import { ResultsService } from './results.service';
import { StorageService } from './storage.service';
import { TeamService } from './teams.service';
import { OrgService } from './org.service';
import { UIService } from './ui.service';
import { UserService } from './user.service';
import { NotificationsService } from './notifications.service';
import { NotesService } from './notes.service';
import { InviteService } from './invite.service';
import { MemberService } from './member.service';
import { ProductService } from './product.service';

export const services: any[] = [
  CapabilityService,
  LoggingService,
  ResultsService,
  StorageService,
  TeamService,
  OrgService,
  UIService,
  UserService,
  NotificationsService,
  NotesService,
  InviteService,
  MemberService,
  ProductService,
];

export * from './capability.service';
export * from './logging.service';
export * from './results.service';
export * from './storage.service';
export * from './teams.service';
export * from './org.service';
export * from './ui.service';
export * from './user.service';
export * from './notifications.service';
export * from './notes.service';
export * from './invite.service';
export * from './member.service';
export * from './product.service';

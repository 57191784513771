import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-action-handler',
  templateUrl: './action-handler.component.html',
})
export class ActionHandlerComponent implements OnInit {
  constructor(private _router: Router, private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this._route.queryParams.subscribe((params) => {
      if (params.continueUrl) {
        switch (params.mode) {
          case 'signIn':
            this._router.navigateByUrl(
              `login-link?apiKey=${params.apiKey}&oobCode=${params.oobCode}&mode=signIn`
            );
            break;
          case 'resetPassword':
            this._router.navigateByUrl(
              `password-reset?apiKey=${params.apiKey}&oobCode=${params.oobCode}&mode=resetPassword`
            );
            break;
        }
      }
    });
  }
}

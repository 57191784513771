/*
import { Injectable } from '@angular/core';
import { 
    AngularFireStorage, 
    AngularFireStorageReference, 
    AngularFireUploadTask 
} from '@angular/fire/storage';
import { Subject, Subscription } from 'rxjs';

@Injectable()
export class StorageService {

    ref: AngularFireStorageReference;
    task: AngularFireUploadTask;

    profileImagesDir = "profileImages/";

    constructor(
        private afStorage: AngularFireStorage
    ) {}

    uploadProfileImage(userId: string) {
        this.ref = this.afStorage.ref(userId);
        this.task = this.ref.put(event.target.files[0]);
      }
}

*/

import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
  
export interface FilesUploadMetadata {
    uploadProgress$: Observable<number>;
    downloadUrl$: Observable<string>;
}
  
@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor(private readonly storage: AngularFireStorage) {}
  
    uploadFileAndGetMetadata(
      mediaFolderPath: string,
      fileToUpload: File,
    ): FilesUploadMetadata {
      const { name } = fileToUpload;
      const filePath = `${mediaFolderPath}/${new Date().getTime()}_${name}`;
      const uploadTask: AngularFireUploadTask = this.storage.upload(
        filePath,
        fileToUpload,
      );
      return {
        uploadProgress$: uploadTask.percentageChanges(),
        downloadUrl$: this.getDownloadUrl$(uploadTask, filePath),
      };
    }
  
    private getDownloadUrl$(
      uploadTask: AngularFireUploadTask,
      path: string,
    ): Observable<string> {
      return from(uploadTask).pipe(
        switchMap((_) => this.storage.ref(path).getDownloadURL()),
      );
    }
}
  
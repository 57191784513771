import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { AuthService } from '@core/auth/auth.service';
import { InviteService } from '@core/services';
import { ConfigData, UserData, UserOrg } from '@core/models';

import { Store } from '@ngrx/store';
import * as fromRoot from '@core/store/app.reducer';

import configSettings from '../../../resources/config.json';

import { TermsAndConditionsDialog } from './terms-and-conditions-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  catchError,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Invite } from '@core/models/invite.model';
import * as UI from '@core/store/ui.actions';
import { AlertDialog } from '@shared/components/alert-dialog/alert-dialog';
import firebase from 'firebase/app';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
})
export class InviteComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isLoggingIn: boolean = false;
  configData: ConfigData = configSettings;
  invite: Invite;
  invitedByUser: UserData;
  isLoading$: Observable<boolean>;
  signUpForm: FormGroup;
  orgId: string;
  orgName: string;
  orgProductName: string;

  constructor(
    private _dialog: MatDialog,
    private _authService: AuthService,
    private _inviteService: InviteService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store<{ ui: fromRoot.State }>
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this._store.select(fromRoot.getIsLoading);
    this._store.dispatch(new UI.StartLoading());

    let inviteId = this._route.snapshot.paramMap.get('inviteId');

    this._inviteService
      .getInvite(inviteId)
      .pipe(
        take(1),
        map((invite) => {
          if (!invite || invite.userId !== '') {
            this._router.navigate(['/login']);
            return throwError('This invite does not exist');
          }

          // Pass this through as objects from the invite rather that query the org from ID
          // saves having to have DB rules for non-auth access to firestore
          if (invite.team.orgId) {
            this.orgId = invite.team.orgId;
            this.orgName = invite.team.orgName;
            this.orgProductName = invite.team.productName;
          }

          this.invite = invite;

          this.signUpForm = this._formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', [Validators.required]],
            password: ['', Validators.required, Validators.minLength(8)],
            agreements: ['', Validators.requiredTrue],
          });

          this._store.dispatch(new UI.StopLoading());
        })
      )
      .subscribe();
  }

  signUp() {
    if (this.signUpForm.invalid) {
      this.displayError(`Please check the details you've entered are correct`);
      return;
    }

    this.signUpForm.disable();

    this._authService
      .registerUserByInvite(
        this.invite.id,
        this.signUpForm.get('firstName').value,
        this.signUpForm.get('lastName').value,
        this.signUpForm.get('password').value
      )
      .pipe(
        tap(() => (this.isLoggingIn = true)),
        switchMap((user) => {
          return this._authService.login({
            email: user.email,
            password: this.signUpForm.get('password').value,
          });
        })
      )
      .subscribe({
        complete: () => {
          console.log('Complete');
          this._router.navigate(['/welcome']);
        },
        error: (error) => {
          this.isLoggingIn = false;
          this.signUpForm.enable();

          this.displayError(error);
        },
      });
  }

  displayError(message: string) {
    const dialogRef = this._dialog.open(AlertDialog, {
      width: '350px',
      data: {
        title: `Ooops`,
        message: message,
      },
    });
  }

  onTermsAndConditions() {
    const dialogRef = this._dialog.open(TermsAndConditionsDialog);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

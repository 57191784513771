<div fxLayout="column" fxLayoutAlign="center center">
  <h2 mat-dialog-title>Member License Agreement Notic</h2>
  <p>
    Bod Technology offers a SaaS web application (the Service) designed to help
    teams and team members grow professionally and to centralize progress and
    development, training, leadership, and personal analysis.
    <br /><br />
    You have been invited to sign up to a Member Account of the Service. Under
    the Terms of the License agreement for the Service
    <a href="/legal" target="_blank">available here</a>, the Lead Member has
    agreed to obligations regarding the use of the Service. As a Member You
    agree to be bound by the applicable Terms and Obligations covering the use
    of the Service through a Member Account.
  </p>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onClose()">Close</button>
  </mat-dialog-actions>
</div>

<div class="w-full">
  <!-- Form -->
  <form [formGroup]="securityForm" #signInNgForm="ngForm">
    <div
      class="flex flex-col flex-auto mt-2 bg-card shadow rounded overflow-hidden"
    >
      <div class="p-8">
        <div class="grid sm:grid-cols-4 gap-6 w-full">
          <!-- Current password -->
          <div class="sm:col-span-4">
            <mat-form-field class="app-mat-no-subscript w-full">
              <mat-label>New password</mat-label>
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:key'"
                matPrefix
              ></mat-icon>
              <input
                [formControlName]="'password'"
                autocomplete="false"
                type="password"
                matInput
              />
              <mat-error
                *ngIf="securityForm.get('password').hasError('password')"
              >
                Please enter a new password
              </mat-error>
            </mat-form-field>
          </div>
          <!-- New password -->
          <div class="sm:col-span-4">
            <mat-form-field class="app-mat-no-subscript w-full">
              <mat-label
                >Confirm new password
                <mat-error
                  *ngIf="
                    securityForm
                      .get('confirmPassword')
                      .hasError('confirmPassword')
                  "
                >
                  Please confirm your new password
                </mat-error></mat-label
              >
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:key'"
                matPrefix
              ></mat-icon>
              <input
                [formControlName]="'confirmPassword'"
                type="password"
                autocomplete="false"
                matInput
              />
            </mat-form-field>
            <mat-error
              *ngIf="
                securityForm.get('confirmPassword').hasError('confirmPassword')
              "
            >
              Please confirm your new password
            </mat-error>
            <div class="mt-1 text-md text-hint">
              Minimum 8 characters. Must include numbers, letters and special
              characters.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-center justify-end mt-10">
      <button
        class="px-6 ml-3"
        mat-flat-button
        [color]="'primary'"
        (click)="save()"
        [disabled]="!securityForm.valid"
      >
        <span *ngIf="!securityForm.disabled"> Save </span>
        <mat-progress-spinner
          *ngIf="securityForm.disabled"
          [diameter]="24"
          [mode]="'indeterminate'"
        ></mat-progress-spinner>
      </button>
    </div>
  </form>
</div>

import { Action } from '@ngrx/store';

export const SET_ADMIN = '[Admin] Set isAdmin';
export const SET_NOT_ADMIN = '[Admin] Set isNotAdmin';

export class SetIsAdmin implements Action {
    readonly type = SET_ADMIN;
}

export class SetNotAdmin implements Action {
    readonly type = SET_NOT_ADMIN;
}

export type AdminActions = SetIsAdmin | SetNotAdmin;
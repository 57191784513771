import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'survey-complete-dialog',
    templateUrl: 'survey-complete-dialog.html',
  })
  export class SurveyCompleteDialog {
  
    constructor(public dialogRef: MatDialogRef<SurveyCompleteDialog>) {}
  
    onClose(): void {
      this.dialogRef.close();
    }
  
  }
import { CapabilitySetAdapter } from './capability.model';
import { NoteAdapter } from './note.model';

export * from './capability.model';
export * from './config.model';
export * from './results.model';
export * from './role.model';
export * from './teams.model';
export * from './user.model';
export * from './note.model';
export * from './member.model';

export const adapters: any[] = [
    CapabilitySetAdapter,
    NoteAdapter
];

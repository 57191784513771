<div class="notes">
  <h2 class="notes__header border-b-2 border-primary-600">
    <span>Notes</span>
    <span>{{ capability.focus }} > {{ capability.capabilityName }}</span>
    <button
      type="button"
      title="Close"
      class="absolute right-6 top-7"
      (click)="onClose()"
    >
      <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </h2>
  <div #notes class="notes__body">
    <div class="notes__item" *ngFor="let note of notes$ | async">
      <mat-card>
        <div class="notes__note" [innerHTML]="note.note"></div>
      </mat-card>
      <span class="notes__by"
        >{{ note.createdOn.toDate() | amTimeAgo }} by
        {{ note.createdByName }}</span
      >
    </div>
  </div>
  <div class="notes__message">
    <textarea
      #textarea
      type="text"
      rows="4"
      id="note"
      (keyup)="onKeyup()"
    ></textarea>
    <span class="flex items-center text-sm py-1">
      <mat-icon
        class="text-hint h-3 h-3 pr-1"
        [svgIcon]="'heroicons_outline:information-circle'"
      ></mat-icon>
      <strong>Important:</strong>&nbsp;These notes are visible to you and your
      reviewer(s) only.</span
    >
    <button
      [disabled]="!textEntered"
      (click)="onCreateNote(textarea.value)"
      mat-flat-button
      color="primary"
    >
      Add note
    </button>
  </div>
</div>

<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0">
  <div
    class="
      w-full
      sm:w-auto
      py-8
      px-4
      sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card
    "
  >
    <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
      <!-- Title -->
      <div class="text-4xl font-extrabold tracking-tight leading-tight">
        Forgotten password
      </div>

      <!-- Sign in form -->
      <form
        class="mt-8"
        [formGroup]="forgottenPasswordForm"
        #signInNgForm="ngForm"
      >
        <!-- Email field -->
        <mat-form-field class="w-full">
          <mat-label>Email address</mat-label>
          <input id="email" matInput [formControlName]="'email'" />
          <mat-error
            *ngIf="forgottenPasswordForm.get('email').hasError('required')"
          >
            Email address is required
          </mat-error>
          <mat-error
            *ngIf="forgottenPasswordForm.get('email').hasError('email')"
          >
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <!-- Submit button -->
        <button
          class="app-mat-button-large w-full mt-6"
          mat-flat-button
          [color]="'primary'"
          [disabled]="forgottenPasswordForm.disabled"
          (click)="onSubmit()"
        >
          <span *ngIf="!forgottenPasswordForm.disabled">Submit</span>
          <mat-progress-spinner
            *ngIf="forgottenPasswordForm.disabled"
            [diameter]="24"
            [mode]="'indeterminate'"
          ></mat-progress-spinner>
        </button>
      </form>
    </div>
  </div>
</div>

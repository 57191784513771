import { Injectable } from "@angular/core";
import { Adapter } from "./adaptor";
import { IBaseModel } from "./base.model";

export interface Note extends IBaseModel {
    capabilityDocId: string;
    capabilityId: string;
    createdByUid: string;
    createdByName: string;
    createdOn: Date;
    note: string;
}

export interface NoteCount {
    capabilityId: string,
    count: number
}

@Injectable()
export class NoteAdapter implements Adapter<Note> {
    adapt(item: any): Note {
        let note = <Note>{ };
        return note;
    }
}
<div fxLayout="column" fxLayoutAlign="center center">
  <h2 mat-dialog-title>You're all done</h2>
  <p>Now check back and make sure you are happy with your selections.</p>
  <p>
    Your selections have been saved so you can come back later and you can make
    as many changes as you wish before submitting.
  </p>
  <p>You can see how you are progressing on your personal dashboard.</p>
  <p>
    Once you are happy, hit the submit button and your reviewer will be
    notified.
  </p>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onClose()">Close</button>
  </mat-dialog-actions>
</div>

<div class="app-navigation-wrapper">
  <!-- Header -->
  <div
    class="app-navigation-header h-16"
    [ngClass]="{
      'app-navigation-header--hide': navigationState === 'collapse'
    }"
  >
    <div class="flex items-center p-7 pl-5">
      <!-- Light version -->
      <a
        routerLink="/welcome"
        class="dark:hidden w-30 text-2xl logo"
        src="assets/images/logo/logo-text.svg"
        alt="Logo image"
        ><span className="text-primary-600">{{ productName }}</span>
      </a>
      <!-- Dark version -->
      <a
        routerLink="/welcome"
        class="hidden dark:flex w-30 text-2xl logo"
        src="assets/images/logo/logo-text-on-dark.svg"
        alt="Logo image"
        ><span className="text-primary-600">{{ productName }}</span></a
      >
    </div>
  </div>

  <!-- Content -->
  <div class="app-navigation-content" #navigationContent>
    <div class="app-navigation-group-item">
      <div class="app-navigation-basic-item">
        <div class="app-navigation-item-wrapper">
          <!-- Item with an internal link -->
          <a
            class="app-navigation-item"
            [routerLinkActive]="'app-navigation-item-active'"
            routerLink="/dashboard"
          >
            <!-- Icon -->
            <mat-icon
              class="app-navigation-item-icon"
              [svgIcon]="'heroicons_outline:template'"
            ></mat-icon>

            <!-- Title & Subtitle -->
            <div class="app-navigation-item-title-wrapper">
              <div class="app-navigation-item-title">
                <span> Dashboard </span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="app-navigation-basic-item">
        <div class="app-navigation-item-wrapper">
          <!-- Item with an internal link -->
          <a
            class="app-navigation-item"
            [routerLinkActive]="'app-navigation-item-active'"
            routerLink="/checkin"
          >
            <!-- Icon -->
            <mat-icon
              class="app-navigation-item-icon"
              [svgIcon]="'heroicons_outline:refresh'"
            ></mat-icon>

            <!-- Title & Subtitle -->
            <div class="app-navigation-item-title-wrapper">
              <div class="app-navigation-item-title">
                <span>
                  {{ productTerm }}
                </span>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div class="app-navigation-basic-item">
        <div class="app-navigation-item-wrapper">
          <!-- Item with an internal link -->
          <a
            class="app-navigation-item"
            [routerLinkActive]="'app-navigation-item-active'"
            routerLink="/teams"
          >
            <!-- Icon -->
            <mat-icon
              class="app-navigation-item-icon"
              [svgIcon]="'heroicons_outline:user-group'"
            ></mat-icon>

            <!-- Title & Subtitle -->
            <div class="app-navigation-item-title-wrapper">
              <div class="app-navigation-item-title">
                <span> Teams </span>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div
        class="app-navigation-basic-item"
        *ngIf="checkRoleForReviewer(role$ | async)"
      >
        <div class="app-navigation-item-wrapper">
          <!-- Item with an internal link -->
          <a
            class="app-navigation-item"
            [routerLinkActive]="'app-navigation-item-active'"
            routerLink="/team-admin"
          >
            <!-- Icon -->
            <mat-icon
              class="app-navigation-item-icon"
              [svgIcon]="'heroicons_outline:users'"
            ></mat-icon>

            <!-- Title & Subtitle -->
            <div class="app-navigation-item-title-wrapper">
              <div class="app-navigation-item-title">
                <span> Reviews </span>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div
        class="app-navigation-basic-item"
        *ngIf="checkRoleForManagement(role$ | async)"
      >
        <div class="app-navigation-item-wrapper">
          <!-- Item with an internal link -->
          <a
            class="app-navigation-item"
            [routerLinkActive]="'app-navigation-item-active'"
            routerLink="/salary-bandings"
          >
            <!-- Icon -->
            <mat-icon
              class="app-navigation-item-icon"
              [svgIcon]="'heroicons_outline:currency-pound'"
            ></mat-icon>

            <!-- Title & Subtitle -->
            <div class="app-navigation-item-title-wrapper">
              <div class="app-navigation-item-title">
                <span> Salary Bandings </span>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div
        class="app-navigation-basic-item"
        *ngIf="checkRoleForManagement(role$ | async)"
      >
        <div class="app-navigation-item-wrapper">
          <!-- Item with an internal link -->
          <a
            class="app-navigation-item"
            [routerLinkActive]="'app-navigation-item-active'"
            routerLink="/reports"
          >
            <!-- Icon -->
            <mat-icon
              class="app-navigation-item-icon"
              [svgIcon]="'heroicons_outline:presentation-chart-line'"
            ></mat-icon>

            <!-- Title & Subtitle -->
            <div class="app-navigation-item-title-wrapper">
              <div class="app-navigation-item-title">
                <span> Reports </span>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div
        class="app-navigation-basic-item"
        *ngIf="(role$ | async) === 'admin'"
      >
        <div class="app-navigation-item-wrapper">
          <!-- Item with an internal link -->
          <a
            class="app-navigation-item"
            [routerLinkActive]="'app-navigation-item-active'"
            routerLink="/organisation"
          >
            <!-- Icon -->
            <mat-icon
              class="app-navigation-item-icon"
              [svgIcon]="'heroicons_outline:office-building'"
            ></mat-icon>

            <!-- Title & Subtitle -->
            <div class="app-navigation-item-title-wrapper">
              <div class="app-navigation-item-title">
                <span> Organisation </span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div class="app-navigation-footer pl-5 pb-5 text-gray-600 font-small">
    <!--
    <button
      *ngIf="navigationState === 'collapse'"
      mat-icon-button
      (click)="toggleNavigationState('open')"
    >
      <mat-icon [svgIcon]="'heroicons_outline:chevron-right'"></mat-icon>
    </button>
    <button
      *ngIf="navigationState === 'open'"
      mat-icon-button
      (click)="toggleNavigationState('collapse')"
    >
      <mat-icon [svgIcon]="'heroicons_outline:chevron-left'"></mat-icon>
    </button>
    -->
  </div>
</div>

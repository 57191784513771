export const environment = {
  production: true,
  domain: 'demo.hellobod.io',
  api: 'https://bod-api-ktx3incqaq-nw.a.run.app',
  firebase: {
    apiKey: 'AIzaSyAxa_rgSLstnV9wg8qkWya-I3jxk0ClfpM',
    authDomain: 'bod-app-demo.firebaseapp.com',
    projectId: 'bod-app-demo',
    storageBucket: 'bod-app-demo.appspot.com',
    messagingSenderId: '417727659837',
    appId: '1:417727659837:web:b769f844bafc25a90ccf54',
    measurementId: 'G-4GH9QVLXT1',
  },
};

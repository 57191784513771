<div
  class="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-8"
>
  <app-loading *ngIf="(isLoading$ | async) || isLoggingIn"></app-loading>
  <div
    *ngIf="!(isLoading$ | async) && !isLoggingIn"
    class="
      flex
      md:w-full md:max-w-6xl
      sm:rounded-2xl sm:shadow
      overflow-hidden
      sm:bg-card
    "
  >
    <div
      class="
        relative
        hidden
        md:flex
        flex-auto
        items-center
        justify-center
        h-full
        p-16
        lg:px-28
        overflow-hidden
        bg-gray-800
        dark:border-l
      "
    >
      <!-- Background - @formatter:off -->

      <!-- @formatter:on -->
      <!-- Content -->
      <div class="z-10 relative w-full max-w-2xl">
        <div class="font-bold leading-none text-gray-100" *ngIf="orgName">
          <div class="text-4xl pb-2">You've been invited to join</div>
          <div class="text-7xl">{{ orgProductName }}@{{ orgName }}</div>
        </div>
        <div class="mt-6 text-lg tracking-tight leading-6 text-gray-400">
          Your Personal and Professional Development Ecosystem.<br /><br />
          You've been invited to join by
          <strong>{{ invite.invitedByName }}</strong
          >.
        </div>
      </div>
    </div>

    <div class="w-full sm:w-auto py-8 px-4 sm:p-12 md:p-16">
      <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
        <!-- Title -->
        <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">
          Sign me up!
        </div>
        <div class="flex items-baseline mt-0.5 font-medium">
          <div>Already have an account?</div>
          <a
            class="ml-1 text-primary-500 hover:underline"
            [routerLink]="['/login']"
            >Sign in
          </a>
        </div>

        <div class="font-medium tracking-tight pt-4" *ngIf="invite">
          Your account will be registered to <strong>{{ invite.email }}</strong>
        </div>

        <!-- Sign Up form -->
        <form class="mt-8" [formGroup]="signUpForm">
          <!-- First name field -->
          <mat-form-field class="w-full">
            <mat-label>First name</mat-label>
            <input
              id="firstName"
              matInput
              autocomplete="off"
              [formControlName]="'firstName'"
            />
            <mat-error *ngIf="signUpForm.get('firstName').hasError('required')">
              First name is required
            </mat-error>
          </mat-form-field>

          <!-- Last name field -->
          <mat-form-field class="w-full">
            <mat-label>Last name</mat-label>
            <input
              id="lastName"
              matInput
              autocomplete="off"
              [formControlName]="'lastName'"
            />
            <mat-error *ngIf="signUpForm.get('lastName').hasError('required')">
              Last name is required
            </mat-error>
          </mat-form-field>

          <!-- Password field -->
          <mat-form-field class="w-full">
            <mat-label>Password</mat-label>
            <input
              id="password"
              matInput
              type="password"
              autocomplete="off"
              [formControlName]="'password'"
              #passwordField
            />
            <button
              mat-icon-button
              type="button"
              (click)="
                passwordField.type === 'password'
                  ? (passwordField.type = 'text')
                  : (passwordField.type = 'password')
              "
              matSuffix
            >
              <mat-icon
                class="icon-size-5"
                *ngIf="passwordField.type === 'password'"
                [svgIcon]="'heroicons_solid:eye'"
              ></mat-icon>
              <mat-icon
                class="icon-size-5"
                *ngIf="passwordField.type === 'text'"
                [svgIcon]="'heroicons_solid:eye-off'"
              ></mat-icon>
            </button>
            <mat-error> Password is required </mat-error>
          </mat-form-field>

          <!-- ToS and PP -->
          <div class="inline-flex items-end w-full mt-1.5">
            <mat-checkbox [color]="'primary'" [formControlName]="'agreements'">
              <span>I agree to the</span>
              <a
                class="ml-1 text-primary-500 hover:underline"
                (click)="onTermsAndConditions()"
                >Terms of Service
              </a>
              <span>and</span>
              <a
                class="ml-1 text-primary-500 hover:underline"
                [routerLink]="['./']"
                >Privacy Policy
              </a>
            </mat-checkbox>
          </div>

          <!-- Submit button -->
          <button
            class="app-mat-button-large w-full mt-6"
            mat-flat-button
            [color]="'primary'"
            [disabled]="signUpForm.disabled"
            (click)="signUp()"
          >
            <span *ngIf="!signUpForm.disabled"> Create your free account </span>
            <mat-progress-spinner
              *ngIf="signUpForm.disabled"
              [diameter]="24"
              [mode]="'indeterminate'"
            ></mat-progress-spinner>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { IBaseModel } from './base.model';
import { Adapter } from './adaptor';
import { NoteCount } from '.';

export interface ModelBehaviourUpload extends IBaseModel {
  active: boolean;
  data: Capability[];
}

export interface Behaviour {
  label: string;
  description: string;
}

export interface Capability {
  id: string;
  capabilityName: string;
  capabilityDefinition: string;
  focus: string;
  focusDescription: string;
  behaviours: Behaviour[];
  order?: number;
}

export interface CapabilityStatus {
  status: string;
  timestamp: Date;
}

export enum CapabilityStatusStep {
  Started = 'Started',
  Submitted = 'Submitted',
  Reviewed = 'Reviewed',
  Discuss = 'Discuss',
  Agreed = 'Agreed',
  Completed = 'Completed',
}

export interface CapabilitySet extends IBaseModel {
  id: string;
  uid: string;
  submitted: boolean;
  submittedOn: string;
  status: CapabilityStatus[];
  createdOn: Date;
  reviewedBehaviourLabels: string[];
  reviewedByUid: string;
  reviewedByName: string;
  notes: NoteCount[];
  track: string;
  capabilities: Capability[];
  selectedBehaviourLabels: string[];
}

export interface CapabilityResult {
  focus: string;
  capabilityName: string;
  selectedBehaviourLabel: string;
}

export interface CapabilityOverlay {
  id;
  index;
}

@Injectable()
export class CapabilitySetAdapter implements Adapter<CapabilitySet> {
  adapt(item: any): CapabilitySet {
    let capabilitySet = <CapabilitySet>(
      (item.track, item.capabilities, item.selectedBehaviourLabels)
    );
    capabilitySet.id = item.uid;
    capabilitySet.createdOn = item.createdOn;
    capabilitySet.submitted = item.submitted;
    capabilitySet.submittedOn = item.submittedOn;
    capabilitySet.status = item.status;

    return capabilitySet;
  }
}

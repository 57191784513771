import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { Capability, Note, UserData } from '@core/models';
import { NotesService } from '@core/services';
import { Observable, pipe, Subscription } from 'rxjs';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('notes') private noteEl: ElementRef;
  @ViewChild('textarea') private textareaEl: ElementRef;
  @Output() notesChanged = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Input() userId: string;
  @Input() capabilityDocId: string;
  @Input() author: UserData;
  @Input() capability: Capability;

  notes$: Observable<Note[]>;
  noteSub: Subscription;
  textEntered: boolean = false;
  notesCount: number = 0;

  constructor(private notesService: NotesService) { }

  ngOnInit(): void {
    this.getNotes();
    this.scrollToBottom();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('capability' in changes) {
      this.getNotes();
    }
  }

  ngOnDestroy() {
    this.noteSub.unsubscribe();
  }

  getNotes() {
    this.notes$ = this.notesService.getNotesByCapabilitySet(
      this.capabilityDocId, 
      this.capability.id || this.capability.capabilityName,
      this.userId);
    
    this.noteSub = this.notes$.subscribe(notes => {
      setTimeout(() => this.scrollToBottom(), 0);
      this.notesCount = notes.length;
    });
  }

  onCreateNote(note) {
    let addSub = this.notesService.addNote(<Note>{
      capabilityDocId: this.capabilityDocId,
      capabilityId: this.capability.id || this.capability.capabilityName,
      createdByUid: this.author.id,
      createdByName: `${this.author.firstName} ${this.author.lastName}`,
      createdOn: new Date(),
      note
    }, this.userId).subscribe(note => {
      this.textareaEl.nativeElement.value = '';
      this.scrollToBottom();
      addSub.unsubscribe();

      this.notesChanged.emit({ capabilityId: this.capability.id || this.capability.capabilityName, count: this.notesCount });
    });
  }

  onClose() {
    this.close.emit();
  }

  onKeyup() {
    this.textEntered = this.textareaEl.nativeElement.value !== '';
  }

  scrollToBottom(): void {
    try {
      this.noteEl.nativeElement.scrollTop = this.noteEl.nativeElement.scrollHeight;
    } catch (err) { }
  }
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash-es';

import {
  CapabilityService,
  OrgService,
  ProductService,
  TeamService,
  UserService,
} from '@core/services';
import {
  Observable,
  Subject,
  combineLatest,
  from,
  of,
  EMPTY,
  throwError,
} from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '@core/store/app.reducer';
import * as UI from '@core/store/ui.actions';
import {
  catchError,
  concatMap,
  groupBy,
  map,
  mergeMap,
  reduce,
  switchMap,
  take,
  takeUntil,
  tap,
  toArray,
  withLatestFrom,
} from 'rxjs/operators';
import { Member } from '@core/models/member.model';
import { CapabilitySet, UserData } from '@core/models';

export interface TeamMemberGroup {
  name: string;
  members: Member[];
}

@Component({
  selector: 'app-team-admin',
  templateUrl: './team-admin.component.html',
  styleUrls: ['./team-admin.component.scss'],
})
export class TeamAdminComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isLoading$: Observable<boolean>;
  role$: Observable<string>;
  manager: Member;
  memberGroups: TeamMemberGroup[];
  productTerm: string;

  constructor(
    private userService: UserService,
    private orgService: OrgService,
    private productService: ProductService,
    private teamService: TeamService,
    private router: Router,
    private store: Store<{ ui: fromRoot.State }>
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store.select(fromRoot.getIsLoading);
    this.role$ = this.store.select(fromRoot.getRole);
    this.store.dispatch(new UI.StartLoading());

    this.store
      .select(fromRoot.getProductInfo)
      .pipe(
        take(1),
        tap((product) => (this.productTerm = product.term))
      )
      .subscribe();

    const user$ = this.userService.currentUser$;
    user$
      .pipe(
        mergeMap((user) =>
          this.teamService.getMembersByUserRole(user.id, ['reviewer']).pipe(
            mergeMap((memberships) => {
              if (!memberships.length) {
                return throwError('You are not currently reviewing any teams.');
              }

              let teams = memberships.map((member) => member.team.id);
              if (teams.length) {
                return this.teamService.getMembersOfTeams(teams);
              } else {
                return EMPTY;
              }
            })
          )
        ),
        withLatestFrom(user$),
        switchMap(([members, user]) => {
          if (!members.length) return of({ groups: null, user });

          this.manager = members.find((m) => m.uid === user.id);

          return from(members).pipe(
            groupBy((m) => m.name),
            mergeMap((group) =>
              group.pipe(
                reduce(
                  (acc, cur) => {
                    acc.members.push(cur);
                    return acc;
                  },
                  <TeamMemberGroup>{ name: group.key, members: [] }
                )
              )
            ),
            /*
            map((group) => {
              group.members.sort(
                (m1, m2) =>
                  (this.parseDate(m2.status.lastActivity) as any).toDate() -
                  (this.parseDate(m1.status.lastActivity) as any).toDate()
              );
              return group;
            }),
            */
            toArray(),
            map((groups) => ({ groups, user }))
          );
        }),
        tap(({ groups, user }) => {
          if (groups) {
            this.memberGroups = groups.sort((g1, g2) => {
              return (
                (this.parseDate(g2.members[0]?.status?.lastActivity) as any) -
                (this.parseDate(g1.members[0]?.status?.lastActivity) as any)
              );
            });

            // Remove current logged in user
            this.memberGroups = this.memberGroups.filter(
              (mg) => mg.members[0].uid !== user.id
            );
          }

          this.store.dispatch(new UI.StopLoading());
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe({
        error: (err) => {
          console.log(err);
          this.store.dispatch(new UI.StopLoading());
        },
      });
  }

  parseDate(date) {
    if (date) {
      return (date as any).toDate();
    } else {
      return new Date('2000-01-01 00:00:01Z');
    }
  }

  getStatusColor(status: string) {
    if (!status) return '';
    return `team-admin__chip--${status.toLowerCase()}`;
  }

  getStatusLabel(status: string, member: Member) {
    if (status === 'Submitted' && member.review) {
      return 'In review';
    }

    return status;
  }

  getProfilePic(imageSrc) {
    if (!imageSrc) return '';
    return `${imageSrc}`;
  }

  joinTeams(memberships: Member[]) {
    return memberships.map((member) => member.team.name).join(', ');
  }

  checkRoleForManagement(role) {
    if (role === 'manager' || role === 'admin') {
      return true;
    }

    return false;
  }

  checkForProduct(productSlug) {
    return this.productService.hasAccess(productSlug);
  }

  onView(member: Member) {
    this.router.navigateByUrl(`checkin/${member.uid}/view`);
  }

  onReview(member: Member) {
    const reviewUrl = `checkin/${member.uid}/review`;
    if (member.review) {
      this.router.navigateByUrl(reviewUrl);
    } else {
      this.teamService
        .addMemberReviewLock(member.uid, this.manager.uid, this.manager.name)
        .pipe(take(1))
        .subscribe(() => {
          this.router.navigateByUrl(reviewUrl);
        });
    }
  }

  onViewDashboard(member: Member) {
    this.router.navigateByUrl(`/dashboard/${member.uid}`);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

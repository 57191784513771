<div class="admin-div">
  <h2 fxLayoutAlign="center center">Set Team Time Period</h2>
  <div class="time-form-div">
    <form 
    fxLayout="column" 
    fxLayoutAlign="center center" 
    fxLayoutGap="10px"
    #f="ngForm" 
    (ngSubmit)="updateTimePeriod(f)"
    >
      <mat-form-field>
        <mat-select 
            [(value)]="currentTimePeriod"
            placeholder = "Time Period"
            [(ngModel)]="currentTimePeriod"
            name="timePeriodSelector" 
            (selectionChange)="updateTimePeriod(f)"
            required
        >
          <mat-option *ngFor="let timePeriod of configData.timePeriods" [value]="timePeriod">
              {{ timePeriod }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="timePeriodChanged"><h3>The team time period has been updated.</h3></div>
    </form>
  </div>  
</div>

  <!--h2 fxLayoutAlign="center center">Select New Team</h2>
  <div cdkDropListGroup fxLayoutAlign="center top"> 
      <div class="example-container">
        <h2>People</h2>
    
        <div
          cdkDropList
          [cdkDropListData]="userNames"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of userNames" cdkDrag>{{item}}</div>
        </div>
      </div>
    
      <div class="example-container">
        <h2>Team</h2>
    
        <div
          cdkDropList
          [cdkDropListData]="selectedNames"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of selectedNames" cdkDrag>{{item}}</div>
        </div>
      </div>
    </div>
    <section class="team-selection-form">
      <form 
          fxLayout="row" 
          fxLayoutAlign="center center" 
          fxLayoutGap="10px"
          #teamform="ngForm" 
          (ngSubmit)="writeTeam(teamform)"
      >
          <mat-form-field>
              <input 
                  type="text" 
                  matInput 
                  placeholder="Team Name" 
                  ngModel 
                  name="name"
                  required
                  #nameInput="ngModel"
              >
              <mat-error *ngIf="nameInput.hasError('required')">Field must not be empty.</mat-error>
              <mat-error *ngIf="!nameInput.hasError('required')">Name is invalid.</mat-error>
          </mat-form-field>
          <button type="submit" mat-raised-button color="primary" [disabled]="teamform.form.invalid">Save</button>
      </form>
  </section-->


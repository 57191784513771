import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CapabilityStatusStep } from '@core/models';
import { Notification } from '@core/models/notification.model';
import { from, Observable, of } from 'rxjs';
import { BaseService } from './base.service';
import { environment } from '@env';

@Injectable()
export class NotificationsService extends BaseService {
  constructor(private db: AngularFirestore) {
    super('notifications', db);
  }

  // ***************
  // Getters/Setters
  // ***************

  //***************
  // Functions:
  //***************

  create(notification: Notification): Observable<Notification> {
    return from(this.addOrUpdate<Notification>(notification));
  }

  statusChangeNotification({
    user,
    reviewer,
    status,
    productName,
    productTerm,
  }): Observable<Notification | boolean> {
    if (!reviewer) {
      return of(false);
    }

    let model = <Notification>{
      subject: 'Review updated',
      type: 'email',
      email: {
        title: 'Review updated',
      },
      actionCta: `Go to ${productName}`,
      createDate: new Date(),
    };

    switch (status) {
      case CapabilityStatusStep.Submitted:
        model.uid = reviewer.id;
        model.email.to = reviewer.email;
        model.email.body = `Hi ${reviewer.firstName}<br /><br />${user.firstName} has submitted a ${productName} ${productTerm} for you to review.<br /><br />Please log in to ${productName} and complete their review.`;
        model.actionUrl = `https://${environment.domain}/team-admin`;
        break;
      case CapabilityStatusStep.Reviewed:
        model.uid = user.id;
        model.email.to = user.email;
        model.email.body = `Hi ${user.firstName}<br /><br />${reviewer.firstName} has completed your review please log into ${productName} and look at your feedback. Once you are happy with the feedback you can click Agree to sign off your ${productName}`;
        model.actionUrl = `https://${environment.domain}/checkin`;
        break;
      case CapabilityStatusStep.Discuss:
        model.uid = reviewer.id;
        model.email.to = reviewer.email;
        model.email.body = `Hi ${reviewer.firstName}<br /><br />${user.firstName} would like to discuss their recent review.`;
        model.actionUrl = `https://${environment.domain}/team-admin`;
        break;
      case CapabilityStatusStep.Agreed:
        model.uid = reviewer.id;
        model.email.to = reviewer.email;
        model.email.body = `Hi ${reviewer.firstName}<br /><br />${user.firstName} has agreed your recent review.<br /><br />Please log in to ${productName} and complete their review.`;
        model.actionUrl = `https://${environment.domain}/team-admin`;
        break;
      case CapabilityStatusStep.Completed:
        model.uid = user.id;
        model.email.to = user.email;
        model.email.body = `Hi ${user.firstName}<br /><br />${reviewer.firstName} has completed your ${productTerm}, you can now start a new ${productTerm}`;
        model.actionUrl = `https://${environment.domain}/checkin`;
        break;
    }

    return this.create(model);
  }
}

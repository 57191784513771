import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import * as fromServices from './services';
import { adapters as fromAdapters } from './models';
import { IconsModule } from './icons/icons.module';

@NgModule({
    declarations: [],
    imports: [CommonModule, HttpClientModule, IconsModule],
    providers: [fromServices.services, fromAdapters]
})
export class CoreModule {}

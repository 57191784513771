import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { UserData } from '@core/models';
import { UserService } from '@core/services';
import { Subject } from 'rxjs';
import { takeUntil, windowWhen } from 'rxjs/operators';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
})
export class UserMenuComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @Input() showAvatar: boolean = true;
  user: UserData;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: UserService,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    // Subscribe to user changes
    this._userService.currentUser$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: UserData) => {
        this.user = user;

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  settings() {
    this._router.navigate(['/settings']);
  }

  signOut() {
    this._router.navigate(['/logout']);
  }
}

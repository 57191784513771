<mat-tab-group class="drawer" animationDuration="0ms">
  <mat-tab label="{{ checkinVerb }}">
    <div class="drawer-wrap">
      <mat-selection-list
        [multiple]="multiSelect"
        (selectionChange)="onOverlaysChange()"
      >
        <mat-list-option
          *ngFor="
            let cap of getCapabilityOverlays(excludeCurrent);
            let x = index
          "
          [value]="cap.id"
          [selected]="!multiSelect && x === 0"
        >
          <span class="list-item"
            ><span class="lozenge bg-accent-300">{{
              capabilities.length - (excludeCurrent ? x + 1 : x)
            }}</span>
            {{ getLatestStatusDate(cap) | amFromUtc | amDateFormat: "LL" }}
            <span
              *ngIf="checkInProgress(cap)"
              class="
                absolute
                right-3
                overflow-hidden
                rounded-xl
                py-1
                px-2
                text-white text-xs
                bg-orange-500
              "
              >In progress</span
            ></span
          >
        </mat-list-option>
      </mat-selection-list>
      <p *ngIf="!getCapabilityOverlays(excludeCurrent).length">
        You do not have any previous data to
        {{ checkinVerb?.toLowerCase() }}.
      </p>
    </div>
  </mat-tab>
  <mat-tab label="History" *ngIf="!hideHistory">
    <div class="drawer-wrap">
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let cap of getCapabilityOverlays(); let x = index"
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="lozenge bg-accent-300">{{
                capabilities.length - x
              }}</span>
              {{
                getLatestStatusDate(cap)
                  | amFromUtc
                  | amDateFormat: "MMMM Do YYYY @H:MM"
              }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ol class="drawer__status-list">
            <li *ngFor="let status of cap.status">
              {{ status.status }} -
              <span class="status-timestamp">
                {{
                  status.timestamp.toDate()
                    | amFromUtc
                    | amDateFormat: "MMMM Do YYYY @H:MM"
                }}</span
              >
            </li>
          </ol>
          <span class="manager" style="display: none"
            >Manager: {{ cap.reviewedByName }}</span
          >
          <div class="admin-actions" *ngIf="showDelete">
            <button
              mat-button
              (click)="onDelete(cap, capabilities.length - x)"
              color="warn"
            >
              Delete
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-tab>
</mat-tab-group>

<div class="actionbar">
  <div class="actionbar__left">
    <strong>{{ productTerm }} {{ capabilities.length }}</strong>
  </div>
  <div class="actionbar__center">
    <app-status-stepper
      class="actionbar__stepper"
      [steps]="statusSteps"
      [currentStep]="currentStatusStep"
    >
    </app-status-stepper>
  </div>
  <div class="actionbar__right">
    <button
      *ngIf="currentStatusStep === 'Reviewed' && !isReviewer && !isViewer"
      class="actionbar__btn app-mat-button-rounded"
      mat-flat-button
      [color]="'primary'"
      [disabled]="discussDisabled"
      (click)="onDiscuss()"
    >
      Discuss
    </button>
    <button
      *ngIf="!isViewer"
      class="actionbar__btn app-mat-button-rounded"
      mat-flat-button
      [color]="'primary'"
      matBadgeColor="warn"
      [matBadge]="submitBadgeNumber"
      [matBadgeHidden]="submitBadgeNumber === 0"
      [disabled]="submitDisabled"
      (click)="onSubmit()"
    >
      {{ submitTitle }}
    </button>
  </div>
</div>

<div class="flex flex-col flex-auto">
  <!-- Header -->
  <div
    class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-8 pb-0 dark:bg-transparent"
  >
    <div class="flex flex-1 min-w-0 justify-center">
      <div
        class="flex flex-col sm:flex-row flex-auto sm:justify-between w-full max-w-screen-lg"
      >
        <!-- Title -->
        <div class="mt-2">
          <h2
            class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate"
          >
            Reports
          </h2>
        </div>
        <!-- Actions -->
        <div class="mt-4 sm:mt-0">
          <mat-form-field appearance="fill">
            <input
              matInput
              #ref
              [matDatepicker]="picker"
              name="dateSelector"
              [(value)]="selectedDate"
              [(ngModel)]="selectedDate"
              (dateChange)="onSubmit(ref.value)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <!-- Main -->
  <div class="flex justify-center flex-auto p-6 md:p-8">
    <!-- Back -->
    <a
      class="inline-flex items-center mb-4 leading-6 text-primary hover:underline"
      *ngIf="userSelected"
      (click)="backClicked()"
    >
      <span class="inline-flex items-center">
        <mat-icon
          class="icon-size-5 text-current"
          [svgIcon]="'heroicons_solid:arrow-sm-left'"
        ></mat-icon>
        <span class="ml-1.5 font-medium leading-5">Back</span>
      </span>
    </a>
    <div class="w-full max-w-screen-lg">
      <app-loading *ngIf="isLoading$ | async"></app-loading>
      <p *ngIf="!teamReportDataArray?.length">
        There is no report data to show.
      </p>
      <div
        *ngIf="!(isLoading$ | async) && teamResultsComplete"
        class="grid grid-cols-1 gap-6 w-full min-w-0"
      >
        <div
          *ngFor="let teamReportChartData of teamReportDataArray; index as i"
        >
          <h2 class="text-xl mb-3">{{ teamTitles[i] }}</h2>
          <div
            class="sm:col-span-6 flex flex-col flex-auto bg-card shadow rounded-md overflow-hidden mb-6"
          >
            <div
              class="flex flex-row flex-auto"
              *ngIf="teamReportChartData.length"
            >
              <mat-tab-group class="flex-1" animationDuration="0ms" #tabGroup>
                <mat-tab>
                  <ng-template mat-tab-label>Team Maturity</ng-template>
                  <div
                    class="sm:col-span-6 flex flex-col flex-auto p-1 bg-card shadow rounded-md overflow-hidden"
                  >
                    <span class="flex items-center text-sm pl-6">
                      <mat-icon
                        class="text-hint h-3 h-3 pr-1"
                        [svgIcon]="'heroicons_outline:information-circle'"
                      ></mat-icon>
                      <strong>Tip:</strong>&nbsp;Drag a box with your mouse to
                      zoom into a specific area</span
                    >
                    <hc-bubble-chart
                      [bubbleChartData]="teamReportChartData"
                      (selectBubbleEvent)="onDrilldown($event)"
                      [showSeriesLabels]="showSeriesLabels[i]"
                      [excludeUsers]="getExcludedUsers(teamIds[i])"
                    >
                    </hc-bubble-chart>
                  </div>
                </mat-tab>

                <mat-tab>
                  <ng-template mat-tab-label>Maturity Mix</ng-template>

                  <div
                    class="sm:col-span-3 lg:col-span-4 flex flex-col flex-auto p-6 bg-card shadow rounded-lg overflow-hidden"
                  >
                    <div class="flex flex-col flex-auto">
                      <hc-donut-chart [bubbleChartData]="teamReportChartData">
                      </hc-donut-chart>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>

              <div class="flex-1 bg-gray-50 border-l p-6 pt-3">
                <h3 class="text-md mb-4 border-b" style="height: 29px">
                  Team Members
                </h3>

                <div
                  class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mb-8"
                >
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-100">
                      <tr>
                        <th
                          scope="col"
                          class="pr-6 py-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider pl-10"
                        >
                          Name
                        </th>
                        <th scope="col" class="relative px-6 py-3"></th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr
                        class="team-list-item"
                        *ngFor="
                          let data of getDistinctUsers(teamReportChartData)
                        "
                        (click)="onToggleUser(data.member.userId, teamIds[i])"
                      >
                        <td class="px-2 py-2 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="flex-shrink-0 h-6 w-6">
                              <img
                                class="h-6 w-6 rounded-full"
                                *ngIf="data.member.photoUrl"
                                src="{{ getProfilePic(data.member.photoUrl) }}"
                                alt=""
                              />
                              <mat-icon
                                class="h-6 w-6 rounded-full opacity-30"
                                *ngIf="!data.member.photoUrl"
                                [svgIcon]="'heroicons_outline:user-circle'"
                              ></mat-icon>
                            </div>
                            <div class="ml-2">
                              <div class="text-md font-medium text-gray-900">
                                {{ data.member.name }}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td
                          class="px-2 py-2 whitespace-nowrap flex justify-end"
                        >
                          <salary-score
                            *ngIf="checkForProduct('salary-banding')"
                            size="small"
                            [value]="data.member.salaryScore"
                          ></salary-score>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              class="flex flex-row flex-auto p-6"
              *ngIf="!teamReportChartData.length"
            >
              We dont have enough data to display for this team yet.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BubbleChartDataArray } from '@core/models';
import * as Highcharts from 'highcharts';

declare var require: any;
const More = require('highcharts/highcharts-more');
More(Highcharts);

/*
const Exporting = require('highcharts/modules/exporting');
Exporting(Highcharts);

const ExportData = require('highcharts/modules/export-data');
ExportData(Highcharts);
*/

const Accessibility = require('highcharts/modules/accessibility');
Accessibility(Highcharts);

const Annotations = require('highcharts/modules/annotations');
Annotations(Highcharts);

class seriesOptions {
  data: any[];
  color: string;

  constructor(color: string) {
    this.data = [];
    this.color = color;
  }
}

@Component({
  selector: 'hc-bubble-chart',
  templateUrl: './hc-bubble-chart.component.html',
  styleUrls: ['./hc-bubble-chart.component.css'],
})
export class HcBubbleChartComponent implements OnInit {
  @Input() bubbleChartData: BubbleChartDataArray[] = [];
  @Input() showSeriesLabels: boolean = false;
  @Input() displayDates: boolean = false;
  @Input() excludeUsers: string[] = [];
  @Output() selectBubbleEvent = new EventEmitter<string>();

  bubbleColors: any[] = [
    '#D35400',
    '#F39C12',
    '#F1C40F',
    '#2ECC71',
    '#27AE60',
    '#16A085',
    '#1ABC9C',
    '#3498DB',
    '#8E44AD',
    '#9B59B6',
    '#E74C3C',
    '#C0392B',
  ];

  highcharts = Highcharts;
  chartOptions = {
    chart: {
      //styledMode: true,
      type: 'bubble',
      plotBorderWidth: 1,
      zoomType: 'xy',
      style: {
        fontFamily: 'Inter var',
      },
    },

    legend: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    title: {
      text: '',
    },

    accessibility: {
      point: {
        valueDescriptionFormat:
          '{index}. {point.name}, values: {point.x}, skills: {point.y}, knowledge: {point.z}.',
      },
    },

    xAxis: {
      title: {
        text: 'Values',
        style: {
          color: 'black',
          fontSize: 18,
          fontWeight: 'bold',
        },
      },
      min: -70,
      max: 70,
      tickInterval: 70,
      tickLength: 0,
      labels: {
        enabled: false,
      },
      plotLines: [
        {
          color: 'black',
          value: 0,
          width: 1,
        },
      ],
    },

    yAxis: {
      title: {
        text: 'Skills',
        style: {
          color: 'black',
          fontSize: 18,
          fontWeight: 'bold',
        },
      },
      min: -70,
      max: 70,
      tickInterval: 70,
      tickLength: 0,
      labels: {
        enabled: false,
      },
      plotLines: [
        {
          color: 'black',
          value: 0,
          width: 2,
        },
      ],
    },

    tooltip: {
      useHTML: true,
      borderColor: 'black',
      headerFormat: '<table>',
      pointFormat: '<tr><h3>{point.name}</h3></tr>',
      /*
      pointFormat: '<tr><th colspan="2"><h3>{point.name}</h3></th></tr>' +
          '<tr><th>Time period:</th><td>{point.timePeriod}</td></tr>' +
          '<tr><th>Values:</th><td>{point.x}</td></tr>' +
          '<tr><th>Skills:</th><td>{point.y}</td></tr>' +
          '<tr><th>Knowledge:</th><td>{point.z}</td></tr>',
      */
      footerFormat: '</table>',
      followPointer: true,
      enabled: !this.showSeriesLabels,
      credits: {
        enabled: false,
      },
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: this.showSeriesLabels,
          allowOverlap: true,
          format: '{point.name}',
          style: {
            color: 'black',
          },
          align: 'right',
          x: -20,
        },
      },
      bubble: {
        marker: {
          lineWidth: 0,
        },
      },
    },

    exporting: {
      enabled: false,
    },

    series: [
      {
        data: [
          { x: 100, y: 100, z: 0, uid: '', name: 'Min Bubble', dateStr: 'Q1' },
          {
            x: 100,
            y: 100,
            z: 200,
            uid: '',
            name: 'Max Bubble',
            dateStr: 'Q1',
          },
        ],
        color: this.bubbleColors[0],
      },
    ],

    annotations: [
      {
        visible: true,
        labels: [
          {
            point: { x: -60, y: 60, xAxis: 0, yAxis: 0 },
            /*align: 'left',*/
            text: 'Challenging',
          },
          {
            point: { x: 60, y: 60, xAxis: 0, yAxis: 0 },
            /*align: 'right',*/
            text: 'Mastering',
          },
          {
            point: { x: 60, y: -70, xAxis: 0, yAxis: 0 },
            /*align: 'right',*/
            text: 'Championing',
          },
          {
            point: { x: -60, y: -70, xAxis: 0, yAxis: 0 },
            /*align: 'left',*/
            text: 'Growing',
          },
        ],
        labelOptions: {
          backgroundColor: 'white',
          borderColor: 'white',
          style: {
            color: 'black',
            fontSize: 16,
          },
          distance: 0,
          x: 0,
          y: 0,
        },
        draggable: '',
      },
    ],
  };

  constructor(public datepipe: DatePipe) {}

  ngOnInit(): void {
    var index;

    //console.log("bubbleChartData = ", this.bubbleChartData);

    this.chartOptions.tooltip.enabled = !this.showSeriesLabels;
    this.chartOptions.plotOptions.series.dataLabels.enabled =
      this.showSeriesLabels;
    this.chartOptions.plotOptions.series.dataLabels.format = this.displayDates
      ? '{point.dateStr}'
      : '{point.name}';

    this.bubbleChartData.forEach((userBubble) => {
      if (this.excludeUsers.includes(userBubble.userId)) {
        return;
      }

      index = 0;
      let data = userBubble.data;
      //console.log("hc-bubble: index =", userBubble['data'], data);
      data.forEach((bubble) => {
        //console.log("hc-bubble: index =", bubble);

        if (this.chartOptions.series[index] == null) {
          this.chartOptions.series[index] = new seriesOptions(
            this.bubbleColors[index]
          );
        }

        this.chartOptions.series[index].data.push({
          x: bubble.x,
          y: bubble.y,
          z: bubble.r,
          uid: userBubble.userId,
          name: userBubble.userName,
          dateStr: this.datepipe.transform(userBubble.dates[index], 'MMM d, y'),
        });

        index++;
      });

      //console.log("hc-bubble: chartOptions.series =", this.chartOptions.series);
    });
  }

  onPointSelect(event) {
    //console.log("Bubble click selection = ", event.point.name);
    this.selectBubbleEvent.emit(event.point.uid);
  }
}

import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase';

import { BaseService } from './base.service';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { Member } from '@core/models/member.model';
import { first, flatMap, map, mergeMap, tap, toArray } from 'rxjs/operators';

@Injectable()
export class MemberService extends BaseService {
  private _defaultMembership = new BehaviorSubject<Member>(null);

  constructor(private db: AngularFirestore, public datepipe: DatePipe) {
    super('', db);
  }

  // ***************
  // Getters/Setters
  // ***************

  set defaultMembership(value: Member) {
    this._defaultMembership.next(value);
  }

  get defaultMembership(): Member {
    return this._defaultMembership.getValue();
  }

  get defaultMembership$(): Observable<Member> {
    return this._defaultMembership.asObservable();
  }

  //***************
  // Functions:
  //***************

  getMembershipsByUserId(userId: string): Observable<Member[]> {
    return this.listGroup<Member>(`members`, (ref) =>
      ref.where('uid', '==', userId)
    );
  }

  getDefaultMembership(
    userId: string,
    currentOrgId?: string
  ): Observable<Member | undefined> {
    if (currentOrgId) {
      return this.get<Member>(userId, `organisations/${currentOrgId}/members`);
    } else {
      // If using BOD for personal use, for now we return the first team membership found
      return this.listGroup<Member>(`members`, (ref) =>
        ref
          .where(firebase.firestore.FieldPath.documentId(), '==', userId)
          .where('type', '==', 'team')
      ).pipe(
        mergeMap((x) => x),
        first()
      );
    }
  }
}

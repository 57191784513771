import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  authenticated$: Observable<boolean>;
  openSidenav = false;
  title = 'BOD app';

  constructor(private authService: AuthService) {
    //this.authenticated$ = authService.authenticated$;
  }
}

<div>
  <h2 mat-dialog-title class="mb-4">{{data.title}}</h2>
  <p>{{data.message}}</p>
  <mat-dialog-actions class="mt-8 border-t border-gray-100 flex justify-end">
    <button
      *ngIf="!data.hideClose"
      color="primary"
      mat-button
      (click)="onClose()"
    >
      Close
    </button>
    <button *ngIf="data.actionCta" mat-button (click)="onClose()">Close</button>
    <button
      *ngIf="data.actionCta"
      mat-flat-button
      color="primary"
      (click)="onAction()"
    >
      {{data.actionCta}}
    </button>
  </mat-dialog-actions>
</div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserData } from '@core/models';
import { OrgService, UserService } from '@core/services';
import { Subscription, Observable, combineLatest } from 'rxjs';
import * as fromRoot from '@core/store/app.reducer';
import * as UI from '@core/store/ui.actions';
import { map, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  isLoading$: Observable<boolean>;
  productName: string;
  productTerm: string;

  constructor(
    private _router: Router,
    private _orgService: OrgService,
    private _store: Store<{ ui: fromRoot.State }>
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this._store.select(fromRoot.getIsLoading);
    this._store.dispatch(new UI.StartLoading());

    this._orgService.organisation$
      .pipe(
        take(1),
        tap((org) => {
          this.productName = org.productName;
          this.productTerm = org.productTerm;
          this._store.dispatch(new UI.StopLoading());
        })
      )
      .subscribe();
  }

  onStart() {
    this._router.navigate(['checkin']);
  }
}

import { Component, OnInit } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { NgForm } from '@angular/forms';
import { TeamData, ConfigData } from '@core/models';
import { TeamService } from '@core/services';
import { Subscription } from 'rxjs';
import configSettings from '../../resources/config.json';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
  // Get organisation name and time periods from app config file
  configData: ConfigData = configSettings;

  teamSubscription: Subscription;
  teamData: TeamData;
  public userNames = [];
  public selectedNames = [];

  allTimePeriods: string[] = this.configData.timePeriods;
  timePeriods = this.allTimePeriods;

  currentTimePeriod: string;
  timePeriodChanged: boolean = false;

  constructor(private teamService: TeamService) {}

  ngOnInit(): void {
    //console.log("current organisation", this.configData.organisation);
    // Setup subscription with actions once data has been retrieved from FireStore
    /*
    this.teamSubscription = this.teamService.teamDataLoaded.subscribe(
      teamData => {
        this.teamData = teamData;
        this.userNames = [];
        //console.log("Loaded organisation: ", this.teamData)
        this.teamData.users.forEach(element => {
          this.userNames.push(element.name); 
        })
      }
    );

    // Request data from FireStore
    
    this.teamService.getAllTeamsData(this.configData.organisation);
    */
  }

  writeTeam(form: NgForm) {
    //console.log("Selected Team: ", form.value.name, this.selectedNames);
    //this.teamService.setTeamData(this.configData.organisation, form.value.name, this.selectedNames);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  updateTimePeriod(form: NgForm) {
    // Update Report Time Period for organisation
    //this.teamService.setCurrentTimePeriod(this.configData.organisation,form.value.timePeriodSelector);
    //this.timePeriodChanged = true;
  }
}

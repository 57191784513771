import { AdminActions, SET_ADMIN, SET_NOT_ADMIN } from './admin.actions';

export interface State {
    isAdmin: boolean;
}

const initialState: State = {
    isAdmin: false
}

export function adminReducer(state = initialState, action: AdminActions) {
    switch (action.type) {
        case SET_ADMIN:
            return {
                isAdmin: true
            };
        case SET_NOT_ADMIN:
            return {
                isAdmin: false
            };
        default: {
            return state;
        }
    }
}

export const getIsAdmin = (state: State) => state.isAdmin;
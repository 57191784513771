import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Note, NoteAdapter } from '@core/models/note.model';
import { from, Observable, Subject } from 'rxjs';
import { BaseService } from './base.service';

@Injectable()
export class NotesService extends BaseService {
  constructor(
    private db: AngularFirestore,
    private adapter: NoteAdapter,
    public datepipe: DatePipe
  ) {
    super('notes', db);
  }

  addNote(note: Note, uid: string): Observable<Note> {
    return from(this.addOrUpdate<Note>(note, `/users/${uid}/notes`));
  }

  getNotesByCapabilitySet(
    capabilityDocId: string,
    capabilityId: string,
    uid: string
  ): Observable<Note[]> {
    return this.list<Note>(`/users/${uid}/notes`, (ref) =>
      ref
        .where('capabilityDocId', '==', capabilityDocId)
        .where('capabilityId', '==', capabilityId)
        .orderBy('createdOn', 'desc')
    );
  }
}

<app-loading *ngIf="isLoading$ | async"></app-loading>
<div class="flex flex-col flex-auto" *ngIf="!(isLoading$ | async)">
  <mat-drawer-container autosize class="h-full">
    <mat-drawer #drawer mode="over" position="end" class="w-100"> </mat-drawer>

    <mat-drawer-content class="flex flex-col">
      <!-- Header -->
      <div
        class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-8 pb-0 dark:bg-transparent"
      >
        <div class="flex flex-1 min-w-0 justify-center">
          <div
            class="flex flex-col sm:flex-row flex-auto sm:justify-between w-full max-w-screen-lg"
          >
            <!-- Title -->
            <div class="mt-2">
              <h2
                class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate"
              >
                Settings
              </h2>
            </div>
            <!-- Actions -->
            <div class="mt-4 sm:mt-0"></div>
          </div>
        </div>
      </div>

      <!-- Main -->
      <div class="flex flex-auto px-6 py-3 md:px-8 justify-center">
        <div class="w-full max-w-screen-lg">
          <div class="grid grid-cols-1 gap-6 w-full min-w-0">
            <mat-tab-group animationDuration="0ms" #tabGroup>
              <mat-tab>
                <ng-template mat-tab-label> Account </ng-template>

                <settings-account
                  (error)="openSnackBar($event)"
                  (saved)="openSnackBar('Saved!')"
                ></settings-account>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label> Email </ng-template>

                <settings-email (saved)="openSnackBar($event)"></settings-email>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label> Security </ng-template>

                <settings-security
                  (saved)="openSnackBar('Saved!')"
                ></settings-security>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@core/auth/guards/auth.guard';
import { NoAuthGuard } from '@core/auth/guards/noauth.guard';
import { InviteComponent } from './routes/auth/invite/invite.component';
import { LoginComponent } from './routes/auth/login/login.component';
import { PasswordResetComponent } from './routes/auth/password-reset/password-reset.component';

//Routes
import {
  AdminComponent,
  TeamAdminComponent,
  ReportsComponent,
  CheckinComponent,
  WelcomeComponent,
} from '@routes';
import { LayoutComponent } from './layout/layout.component';
import { ForgottenPasswordComponent } from './routes/auth/forgotten-password/forgotten-password.component';
import { LogoutComponent } from './routes/auth/logout/logout.component';
import { LoginLinkComponent } from './routes/auth/login/login-link.component';
import { ActionHandlerComponent } from './routes/auth/action-handler/action-handler';

const routes: Routes = [
  // Redirect empty path to '/welcome'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

  // Guests routes
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      { path: 'invite/:inviteId', component: InviteComponent },
      { path: 'auth/action', component: ActionHandlerComponent },
      { path: 'login', component: LoginComponent },
      { path: 'login-link', component: LoginLinkComponent },
      { path: 'forgotten-password', component: ForgottenPasswordComponent },
      { path: 'password-reset', component: PasswordResetComponent },
    ],
  },

  // Auth routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'default',
    },
    children: [
      { path: 'logout', data: { layout: 'empty' }, component: LogoutComponent },
      { path: 'welcome', component: WelcomeComponent },
      { path: 'checkin', component: CheckinComponent },
      { path: 'checkin/:userId/:action', component: CheckinComponent },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('src/app/routes/dashboards/dashboards.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('src/app/routes/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: 'teams',
        loadChildren: () =>
          import('src/app/routes/teams/teams.module').then(
            (m) => m.TeamsModule
          ),
      },
      {
        path: 'organisation',
        loadChildren: () =>
          import('src/app/routes/organisation/organisation.module').then(
            (m) => m.OrganisationModule
          ),
      },
      {
        path: 'salary-bandings',
        loadChildren: () =>
          import('src/app/routes/salary-bandings/salary-bandings.module').then(
            (m) => m.SalaryBandingsModule
          ),
      },
      { path: 'reports', component: ReportsComponent },
      { path: 'admin', component: AdminComponent },
      { path: 'team-admin', component: TeamAdminComponent },
      {
        path: 'legal',
        loadChildren: () =>
          import('src/app/routes/legal/legal.module').then(
            (m) => m.LegalModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, NoAuthGuard],
})
export class AppRoutingModule {}

import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash-es';

import {
  TeamService,
  CapabilityService,
  OrgService,
  UserService,
  NotificationsService,
} from '@core/services';
import {
  UserData,
  RadarResults,
  Capability,
  CapabilitySet,
  ConfigData,
  CapabilityStatus,
  CapabilityStatusStep,
  TeamMemberStatus,
  CapabilityOverlay,
  NoteCount,
  MemberStatus,
} from '@core/models';
import {
  Subscription,
  Observable,
  combineLatest,
  Subject,
  of,
  throwError,
} from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '@core/store/app.reducer';
import { MatDialog } from '@angular/material/dialog';
import { SurveyCompleteDialog } from './survey-complete-dialog/survey-complete-dialog';
import {
  MatCheckboxDefaultOptions,
  MAT_CHECKBOX_DEFAULT_OPTIONS,
} from '@angular/material/checkbox';
import * as UI from '@core/store/ui.actions';
import * as roles from '@core/models';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSelectionList } from '@angular/material/list';
import { ConfirmDeleteDialog } from './confirm-delete-dialog/confirm-delete-dialog';
import {
  map,
  switchMap,
  takeUntil,
  finalize,
  take,
  tap,
  catchError,
} from 'rxjs/operators';
import { Notification } from '@core/models/notification.model';
import { AlertDialog } from '@shared/components/alert-dialog/alert-dialog';

enum DrawerMode {
  None = 'None',
  Capabilities = 'Capabilities',
  Notes = 'Notes',
}

@Component({
  selector: 'checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions,
    },
  ],
})
export class CheckinComponent implements OnInit, OnDestroy {
  @ViewChild(MatDrawer) drawer: MatDrawer;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isAuth$: Observable<boolean>;
  role$: Observable<string>;
  user$: Observable<UserData>;
  viewerReviewer$: Observable<UserData>;
  capabilitySets$: Observable<CapabilitySet[]>;
  currentUser: string;
  currentCapability: Capability;
  currentNoteTakingCapability: Capability;
  capabilityIndex = 0;
  selectedBehaviour = null;
  selectionsComplete = false;
  initialResults: RadarResults;
  subscription: Subscription;
  userData: UserData = null;
  capabilityReviewerUid?: string;
  viewerReviewerData: UserData = null;
  reviewerUserData: UserData = null;
  capabilityData: CapabilitySet;
  capabilities: CapabilitySet[] = [];
  overlayCapabilities: CapabilityOverlay[] = [];
  currentIteration: number;
  productName: string;
  productTerm: string;
  sideBarOverlayActive: boolean = false;

  isLoading$: Observable<boolean>;

  articleClicked: boolean = false;
  capabilitiesSubmitted: boolean = false;
  capabilitiesExist: boolean = false;
  capabilityCount: number = 1;

  submittedOnDate: string = '';
  steps: CapabilityStatusStep[];
  status: CapabilityStatus[];
  currentStatusStep: CapabilityStatusStep;

  drawerMode: DrawerMode = DrawerMode.Capabilities;
  submitDisabled = false;
  submitTitle = 'Submit';
  completed = false;
  isReviewer = false; // Main reviewer
  isViewer = false; // Secondary reviewer - due to being in their team, but did not start the review process
  reviewerUid: string;
  currentStatusComplete: boolean = false;
  canDelete: boolean = false;

  submitBadgeNumber: number = 0;

  constructor(
    public dialog: MatDialog,
    private notifictionService: NotificationsService,
    private capabilityService: CapabilityService,
    private userService: UserService,
    private teamService: TeamService,
    private orgService: OrgService,
    private route: ActivatedRoute,
    private store: Store<{ ui: fromRoot.State }>,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isAuth$ = this.store.select(fromRoot.getIsAuth);
    this.role$ = this.store.select(fromRoot.getRole);

    this.store.dispatch(new UI.StartLoading());
    this.isLoading$ = this.store.select(fromRoot.getIsLoading);

    this.store
      .select(fromRoot.getProductInfo)
      .pipe(
        take(1),
        tap((product) => {
          this.productName = product.name;
          this.productTerm = product.term;
        })
      )
      .subscribe();

    // All data for this component is from the userId param, or the current logged in user
    this.userService.currentUser$
      .pipe(
        switchMap((loggedInUser) =>
          this.orgService.organisation$.pipe(
            map((org) => ({ loggedInUser, org }))
          )
        ),
        switchMap(({ loggedInUser, org }) => {
          let reviewUserId = this.route.snapshot.paramMap.get('userId');
          let reviewAction = this.route.snapshot.paramMap.get('action');

          if (reviewUserId !== null) {
            this.isReviewer = reviewAction === 'review';
            this.isViewer = reviewAction === 'view';
            this.reviewerUid = loggedInUser.id;
          }

          this.currentUser =
            this.isReviewer || this.isViewer ? reviewUserId : loggedInUser.id;
          this.capabilityIndex = 0;
          this.capabilitiesExist = false;

          // Request data from FireStore
          this.user$ = this.userService.getUserData(this.currentUser);
          this.viewerReviewer$ =
            this.isReviewer || this.isViewer
              ? this.userService.getUserData(this.reviewerUid, false)
              : new Observable<UserData>();
          this.capabilitySets$ = this.capabilityService.getUserCapabilities(
            this.currentUser
          );

          let allSubsToLoad =
            this.isReviewer || this.isViewer
              ? [
                  this.isAuth$,
                  this.role$,
                  this.capabilitySets$,
                  this.user$,
                  this.viewerReviewer$,
                ]
              : [this.isAuth$, this.role$, this.capabilitySets$, this.user$];

          return combineLatest(
            allSubsToLoad,
            (
              auth: boolean,
              role: string,
              capabilities: CapabilitySet[],
              user: UserData,
              manager: UserData
            ) => ({ auth, role, capabilities, user, manager })
          ).pipe(
            catchError((err) => throwError(err.message)),
            map(async (data) => {
              this.store.dispatch(new UI.StopLoading());

              if (data.auth && data.role === 'admin') {
                this.canDelete = true;
              }

              // Store user data
              this.userData = data.user;

              // First check if user is new and has no stored capabilities
              if (!data.capabilities.length) {
                await this.createCapabilitySet();
              } else {
                let countIncomplete = 0;
                this.capabilities = data.capabilities;
                this.capabilityData = this.capabilities[0];
                this.capabilityCount = data.capabilities.length;

                // If currently in review, get the reviewer id
                if (this.capabilityData.reviewedByUid) {
                  this.capabilityReviewerUid =
                    this.capabilityData.reviewedByUid;
                }

                //check if all capabilities have been selected
                this.selectionsComplete = false;

                this.currentStatusStep =
                  this.capabilityService.getCurrentStatus(
                    this.capabilityData.status
                  );
                this.currentStatusComplete =
                  (this.currentStatusStep === CapabilityStatusStep.Reviewed &&
                    !this.isReviewer) ||
                  this.currentStatusStep === CapabilityStatusStep.Agreed ||
                  (this.currentStatusStep === CapabilityStatusStep.Discuss &&
                    !this.isReviewer) ||
                  this.currentStatusStep === CapabilityStatusStep.Completed;

                // Show review count badge
                if (
                  !this.isReviewer &&
                  (this.currentStatusStep === CapabilityStatusStep.Reviewed ||
                    this.currentStatusStep === CapabilityStatusStep.Discuss) &&
                  this.capabilityData.reviewedBehaviourLabels?.length
                ) {
                  let reviewedCount =
                    this.capabilityData.reviewedBehaviourLabels.filter(
                      (val) => val !== null
                    ).length;
                  this.submitBadgeNumber = reviewedCount;
                } else {
                  this.submitBadgeNumber = 0;
                }

                if (this.capabilityData != null) {
                  if (
                    !this.capabilityData.submitted &&
                    this.capabilityData.capabilities != null &&
                    this.capabilityData.selectedBehaviourLabels != null
                  ) {
                    this.capabilityData.selectedBehaviourLabels.forEach(
                      (behaviour) => {
                        if (behaviour == null) {
                          countIncomplete++;
                        }
                      }
                    );

                    if (countIncomplete == 0) {
                      this.selectionsComplete = true;
                    }
                  }
                }

                if (data.manager) {
                  this.viewerReviewerData = data.manager;
                }
              }
            })
          );
        }),
        takeUntil(this._unsubscribeAll),
        finalize(() => this.store.dispatch(new UI.StopLoading()))
      )
      .subscribe({
        error: (error) => {
          const dialogRef = this.dialog.open(AlertDialog, {
            width: '350px',
            data: {
              title: `Somethings not right!`,
              message: error,
              hideClose: true,
              actionCta: 'Back to my dashboard',
            },
          });

          dialogRef
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result) => {
              if (result === 'action') {
                this.router.navigateByUrl('/dashboard');
              }
            });

          this.store.dispatch(new UI.StopLoading());
        },
      });
  }

  filterByFocus(
    capabilities: Capability[],
    focus: 'Skills' | 'Knowledge' | 'Values'
  ) {
    return capabilities.filter((c) => c.focus === focus);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  showDelete() {
    return this.isReviewer;
  }

  replaceOrgName(behaviourDescription) {
    return behaviourDescription.replace('${orgName}', 'Wirehive');
  }

  createCapabilitySet() {
    this.capabilityService
      .getInitialCapabilities(
        this.userData.track,
        this.orgService.organisation.id
      )
      .pipe(
        take(1),
        tap((capabilities) => {
          let selectedBehaviours = this.capabilityService.getInitialBehaviours(
            capabilities,
            this.userData.track
          );

          this.capabilityService
            .storeInitialCapabilities(
              this.currentUser,
              this.userData.track,
              capabilities,
              selectedBehaviours
            )
            .then((capabilitySet) => {
              this.capabilityData = capabilitySet;
              this.currentStatusStep = CapabilityStatusStep.Started;
              this.capabilitiesExist = true;
              this.capabilitiesSubmitted = false;

              // Calc salary score
              let salaryScore = this.capabilityService.calculateSalaryScore(
                this.capabilityData
              );

              this.updateStatus(this.currentStatusStep, salaryScore);

              // Notify change detector
              this.changeDetectorRef.markForCheck();
            });
        })
      )
      .subscribe();
  }

  onDeleteCapabilitySet(data: any) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialog, {
      width: '250px',
      data: { index: data.index },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {
        if (result === 'delete') {
          this.capabilityService
            .delete(
              data.capabilitySet.id,
              `/users/${this.reviewerUid}/capabilities`
            )
            .then(() => this.updateStatus());
        }
      });
  }

  onBehaviourClick(capabilityName: string, behaviourLabel: string) {
    if (!this.isReviewer) {
      // Do nothing if capabilities or results have already been submitted
      if (this.currentStatusStep !== CapabilityStatusStep.Started) return;
    } else {
      // Do nothing if is reviewer but not in review status
      if (
        this.currentStatusStep !== CapabilityStatusStep.Submitted &&
        this.currentStatusStep !== CapabilityStatusStep.Discuss &&
        this.currentStatusStep !== CapabilityStatusStep.Reviewed
      )
        return;
    }

    // Do nothing if we are just viewing
    if (this.isViewer) return;

    let countIncomplete = 0;
    let capabilityIndex = 0;
    let labels = !this.isReviewer
      ? this.capabilityData.selectedBehaviourLabels
      : this.capabilityData.reviewedBehaviourLabels ?? Array(17).fill(null);

    this.capabilityData.capabilities.forEach((capability) => {
      if (capability.capabilityName === capabilityName) {
        labels[capabilityIndex] = behaviourLabel;

        console.log(this.isReviewer, capabilityIndex);

        if (
          this.isReviewer &&
          this.capabilityData.selectedBehaviourLabels[capabilityIndex] ===
            behaviourLabel
        ) {
          labels[capabilityIndex] = null;
        }
      }
      if (labels[capabilityIndex] === null) {
        countIncomplete++;
      }

      capabilityIndex++;
    });

    this.capabilityService
      .storeCapabilitySelection(
        this.currentUser,
        labels,
        this.capabilityData.id,
        this.isReviewer
      )
      .then(() => {
        // Notify change detector
        //this.changeDetectorRef.markForCheck();
      });

    if (!this.isReviewer && countIncomplete === 0) {
      // Confirmation dialog (only show once or gets annoying :) )
      !this.selectionsComplete && this.dialog.open(SurveyCompleteDialog);
      this.selectionsComplete = true;
    }
  }

  onNote(capability) {
    this.sideBarOverlayActive = false;
    this.currentNoteTakingCapability = capability;
    this.drawerMode = DrawerMode.Notes;
    this.drawer.toggle();
  }

  onStatusStepChange({ status }) {
    this.store.dispatch(new UI.StartLoading());

    // If there have been any revisions, merge these into the main selected behaviours
    if (
      status === CapabilityStatusStep.Agreed &&
      this.capabilityData.reviewedBehaviourLabels?.length
    ) {
      for (
        let i = 0;
        i < this.capabilityData.reviewedBehaviourLabels.length;
        i++
      ) {
        let reviewedLabel = this.capabilityData.reviewedBehaviourLabels[i];
        if (reviewedLabel)
          this.capabilityData.selectedBehaviourLabels[i] = reviewedLabel;
      }
    }

    // TODO: Consider atomic transaction here rather than 3 individual updates
    this.capabilityService
      .storeCapabilitySelection(
        this.currentUser,
        this.capabilityData.selectedBehaviourLabels,
        this.capabilityData.id
      )
      .then(() => {
        // Update to next status
        this.capabilityService
          .updateStatus(
            this.capabilityData.id,
            this.currentUser,
            status,
            this.capabilityData.status,
            status === CapabilityStatusStep.Reviewed
              ? <CapabilitySet>{ reviewedByUid: this.reviewerUid }
              : null
          )
          .then(() => {
            // Calc salary score
            let salaryScore = this.capabilityService.calculateSalaryScore(
              this.capabilityData
            );

            // Update team status
            this.updateStatus(status, salaryScore);

            // Notify change detector
            this.changeDetectorRef.markForCheck();

            // Stop loading
            this.store.dispatch(new UI.StopLoading());
          });
      });
  }

  onStartNew() {
    this.createCapabilitySet();
  }

  onOverlayPrevious() {
    this.sideBarOverlayActive = !this.sideBarOverlayActive;
    this.drawerMode = DrawerMode.Capabilities;
    this.drawer.toggle();
  }

  onOverlaysChange(selectedOptions) {
    this.overlayCapabilities = selectedOptions.map((option) => {
      let indexOfCap = _.findIndex(this.capabilities, function (o) {
        return o.id === option.value;
      });
      return <CapabilityOverlay>{ id: option.value, index: indexOfCap };
    });
  }

  onNotesChanged({ capabilityId, count }) {
    let noteCounts: NoteCount[] = this.capabilityData.notes || [];
    let notesForCapability = _.find(
      noteCounts,
      (note) => note?.capabilityId === capabilityId
    );
    if (notesForCapability) {
      notesForCapability.count = count;
    } else {
      noteCounts.push(<NoteCount>{ capabilityId, count });
    }

    this.capabilityService.updateNoteCount(
      this.capabilityData.id,
      this.currentUser,
      noteCounts
    );
  }

  onGoToDashboard() {
    this.router.navigateByUrl(
      this.isReviewer ? `/dashboard/${this.currentUser}` : `/dashboard`
    );
  }

  applyBehaviourSelected(capabilityId, behaviourLabel): boolean {
    let index = this.capabilityData.capabilities.findIndex(
      (c) => c.id === capabilityId
    );

    return (
      this.capabilityData.selectedBehaviourLabels[index] === behaviourLabel
    );
  }

  applyBehaviourReview(capabilityId, behaviourLabel): boolean {
    if (
      (this.isReviewer &&
        this.currentStatusStep !== CapabilityStatusStep.Agreed) ||
      (this.isReviewer &&
        this.currentStatusStep !== CapabilityStatusStep.Completed) ||
      (!this.isReviewer &&
        (this.currentStatusStep === CapabilityStatusStep.Reviewed ||
          this.currentStatusStep === CapabilityStatusStep.Discuss))
    ) {
      let index = this.capabilityData.capabilities.findIndex(
        (c) => c.id === capabilityId
      );

      return (
        this.capabilityData?.reviewedBehaviourLabels?.[index] === behaviourLabel
      );
    }

    return false;
  }

  applyNoteCount(notes, capabilityId) {
    let noteCount: NoteCount = _.find(
      notes,
      (note) => note?.capabilityId === capabilityId
    );
    return noteCount?.count ?? 0;
  }

  applyBehaviourOverlay(overlay: CapabilityOverlay, index, label) {
    let capabilitySet = this.capabilities.find((c) => c.id === overlay.id);
    return capabilitySet.selectedBehaviourLabels[index] === label;
  }

  updateStatus(status?: CapabilityStatusStep, salaryScore?: number) {
    //this.store.dispatch(new UI.StartLoading());

    let newStatus = <MemberStatus>{
      capabilityCount: this.capabilityCount,
      capabilityStatus: status || this.currentStatusStep,
      lastActivity: new Date(),
    };

    // Update team member status
    this.userService
      .updateStatus(this.currentUser, newStatus, salaryScore)
      .pipe(
        switchMap(() => {
          // Remove review lock
          if (status === CapabilityStatusStep.Completed) {
            return this.teamService.removeMemberReviewLock(this.currentUser);
          } else {
            return of(true);
          }
        }),
        take(1)
      )
      .subscribe({
        complete: () => {
          //this.store.dispatch(new UI.StopLoading());
        },
      });

    /*
    if (newStatus.capabilityStatus !== CapabilityStatusStep.Started) {
      this.teamService
        .getTeamMembershipsByUserId(this.currentUser, ['org'])
        .pipe(
          switchMap((memberships) =>
            this.teamService
              .getMembersOfTeams(
                memberships.map((m) => m.team.id),
                ['reviewer']
              )
              .pipe(
                switchMap((reviewers) => {
                  return this.userService
                    .getUserData(this.capabilityReviewerUid || reviewers[0].uid)
                    .pipe(
                      map((reviewerUserData) => ({
                        reviewers,
                        reviewerUserData,
                      })),
                      switchMap(({ reviewers, reviewerUserData }) => {
                        return this.notifictionService.statusChangeNotification(
                          {
                            user: this.userData,
                            reviewer: reviewerUserData,
                            status: newStatus.capabilityStatus,
                            productName: this.productName,
                            productTerm: this.productTerm,
                          }
                        );
                      })
                    );
                })
              )
          ),
          take(1)
        )
        .subscribe();
    }
    */
  }
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import {
  UserService,
  ResultsService,
  OrgService,
  TeamService,
  ProductService,
} from '@core/services';
import {
  UserResults,
  BubbleRec,
  BubbleChartDataArray,
  Team,
} from '@core/models';
import {
  Subscription,
  Observable,
  Subject,
  merge,
  of,
  forkJoin,
  combineLatest,
} from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '@core/store/app.reducer';
import * as UI from '@core/store/ui.actions';
import {
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Member } from '@core/models/member.model';
import { Organisation } from '@core/models/org.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  resultsSubscription: Subscription;
  userSubscription: Subscription;
  role$: Observable<string>;
  organisation: Organisation;
  members: Member[];
  membersHidden: { userId: string; teamId: string }[] = [];
  teams: Team[];
  teamTitles: string[] = [];
  teamIds: string[] = [];
  showSeriesLabels: boolean[] = [];
  isLoading$: Observable<boolean>;
  public bubbleChartDataArray = [];
  public userSelected: boolean = false;
  public dateSelected: boolean = false;
  public teamResultsComplete: boolean = false;
  public showMemberBubbles = false;

  public teamBubbleChartData: BubbleChartDataArray[] = [
    {
      data: [{ x: 0, y: 0, r: 0 }],
      dates: [],
      userId: '',
      userName: '',
    },
  ];

  public teamReportDataArray: BubbleChartDataArray[][] = [];

  selectedDate: Date;

  constructor(
    private orgService: OrgService,
    private teamService: TeamService,
    private resultsService: ResultsService,
    private userService: UserService,
    private productService: ProductService,
    public datepipe: DatePipe,
    private store: Store<{ ui: fromRoot.State }>,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store.select(fromRoot.getIsLoading);
    this.store.dispatch(new UI.StartLoading());
    this.role$ = this.store.select(fromRoot.getRole);

    combineLatest([
      this.userService.currentUser$,
      this.orgService.organisation$,
      this.store.select(fromRoot.getRole),
    ])
      .pipe(
        switchMap(([user, org, role]) => {
          function getTeams(teamService: TeamService): Observable<Team[]> {
            if (role === 'admin') {
              return teamService.getTeamsByOrg(org.id);
            } else {
              return teamService
                .getTeamMembershipsByUserId(user.id, ['org'])
                .pipe(
                  map((members) =>
                    members.map(
                      (m) => <Team>{ id: m.team.id, name: m.team.name }
                    )
                  )
                );
            }
          }
          return getTeams(this.teamService).pipe(
            map((teams) => ({ teams, org }))
          );
        }),
        switchMap(({ org, teams }) => {
          this.organisation = org;
          this.teams = teams;
          return this.teamService
            .getMembersOfTeams(teams.map((t) => t.id))
            .pipe(
              map((members) =>
                members
                  // Filter only folks that have completed at least one review
                  .filter(
                    (m) =>
                      (m.status.capabilityCount > 1 ||
                        (m.status.capabilityCount === 1 &&
                          m.status.capabilityStatus === 'Completed')) &&
                      !this.membersHidden[m.id]
                  )

                  // Sort by salary score desc
                  .sort((a, b) => b.salaryScore - a.salaryScore)
              )
            );
        }),
        takeUntil(this._unsubscribeAll),
        tap((members) => {
          this.members = members;
          this.selectedDate = new Date();

          this.getCompleteTeamResults();
          this.store.dispatch(new UI.StopLoading());
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Get bubble result data for the selected time period for all user IDs
  getCompleteTeamResults() {
    // Get team results
    let observables = this.members.map((member) => {
      return this.resultsService
        .getUserResultsFromCapabilities(member.uid, {
          currentUserName: member.name,
          endDate: this.selectedDate,
        })
        .pipe(
          map(({ userResults, capabilities }) => ({
            userResults,
            capabilities,
            member,
          }))
        );
    });

    combineLatest(observables)
      .pipe(
        map((result) => <any>result),
        takeUntil(this._unsubscribeAll),
        tap((results) => {
          let index = 0;
          results.forEach((userData) => {
            if (userData.userResults.dates !== null) {
              this.buildBubbleChartDataArray(
                userData.userResults,
                userData.member,
                index
              );
              index++;
            }
          });
        })
      )
      .subscribe();
  }

  // Builds bubble data for all team report charts, user by user
  buildBubbleChartDataArray(
    results: UserResults,
    member: Member,
    userIndex: number
  ) {
    var teamId: string;
    var teamIndex: number;
    var chartIndex: number;

    var bubbleChartData: BubbleChartDataArray[] = [
      {
        data: [{ x: 0, y: 0, r: 0 }],
        dates: [],
        userId: '',
        userName: '',
      },
    ];

    teamId = member.team.id;
    teamIndex = 0;

    this.teams.forEach((team) => {
      if (teamId === team.id) {
        chartIndex = teamIndex;
      }

      if (userIndex === 0) {
        this.teamReportDataArray[teamIndex] = [];
        this.teamTitles[teamIndex] = team.name;
        this.teamIds[teamIndex] = team.id;
        this.showSeriesLabels[teamIndex] = true;
      }

      teamIndex++;
    });

    // Initialise Organisation Result Array
    if (userIndex == 0) {
      this.teamReportDataArray[teamIndex] = [];
      this.teamTitles[teamIndex] = this.organisation.name;
      this.teamIds[teamIndex] = this.organisation.id;
      this.showSeriesLabels[teamIndex] = false;
    }

    //console.log("teamIndex = ", teamIndex );
    //console.log("teamReportDataArray initialised = ", this.teamReportDataArray );
    //console.log("chartIndex = ", chartIndex );

    //Update the team chartdata if found valid user data
    if (results.bubbleDataSet != null && results.bubbleDataSet.length != 0) {
      bubbleChartData[0].data[0] = results.bubbleDataSet[0];
      bubbleChartData[0].userId = results.userId;
      bubbleChartData[0].userName = results.userName;
      bubbleChartData[0].dates = results.dates;
      bubbleChartData[0].member = member;

      // Add data to team report chart
      if (this.teamReportDataArray[chartIndex].length == 0) {
        this.teamReportDataArray[chartIndex][0] = bubbleChartData[0];
      } else {
        this.teamReportDataArray[chartIndex].push(bubbleChartData[0]);
      }

      // Add data to organisation report chart
      if (this.teamReportDataArray[teamIndex].length == 0) {
        this.teamReportDataArray[teamIndex][0] = bubbleChartData[0];
      } else {
        this.teamReportDataArray[teamIndex].push(bubbleChartData[0]);
      }
    }

    //console.log("teamReportDataArray = ", this.teamReportDataArray);

    this.teamResultsComplete = true;
  }

  getProfilePic(imageSrc) {
    if (!imageSrc) return '';
    return `${imageSrc}`;
  }

  getExcludedUsers(teamId) {
    let users = this.membersHidden.filter((mh) => mh.teamId === teamId);
    return users;
  }

  getDistinctUsers(teamReportChartData) {
    const result = [];
    const map = new Map();
    for (const item of teamReportChartData) {
      if (!map.has(item.userId)) {
        map.set(item.userId, true); // set any value to Map
        result.push(item);
      }
    }

    return result;
  }

  checkRoleForManagement(role) {
    if (role === 'manager' || role === 'admin') {
      return true;
    }

    return false;
  }

  checkForProduct(productSlug) {
    return this.productService.hasAccess(productSlug);
  }

  onToggleUser(userId, teamId) {
    if (
      !this.membersHidden.find(
        (mh) => mh.userId === userId && mh.teamId === teamId
      )
    ) {
      this.membersHidden.push({ userId, teamId });
    } else {
      this.membersHidden = this.membersHidden.filter(
        (mh) => mh.userId !== userId && mh.teamId !== teamId
      );
    }
  }

  onMemberMouseover(event) {}

  onSubmit(newDate: Date) {
    // Set Report Time Period
    this.selectedDate = newDate;

    //console.log("Reports onSubmit: dateValue = ", newDate);
    //console.log("Reports onSubmit: selectedDate = ", this.selectedDate);

    // Reset data arrays

    this.teamBubbleChartData = [
      {
        data: [{ x: 0, y: 0, r: 0 }],
        dates: [],
        userId: '',
        userName: '',
      },
    ];

    this.bubbleChartDataArray = [];

    // Update data
    this.getCompleteTeamResults();

    this.dateSelected = true;
  }

  /*
    onDrilldown(): Callback triggered by bubble chart point selection event
  */

  onDrilldown(userId: string) {
    this.router.navigateByUrl(`/dashboard/${userId}`);

    /*
    this.store.dispatch(new UI.StartLoading());
    this.userService.getUserData(selectedUserId).pipe(
      take(1),
      tap(userData => {
        this.selectedUserId = selectedUserId;
        this.selectedUserName = `${userData.firstName} ${userData.lastName}`;
        this.userSelected = true;

        console.log(userData)

        this.store.dispatch(new UI.StopLoading());
      })
    ).subscribe();
    */

    /*
    this.userSubscription = this.userService.userDataLoaded.subscribe(
      userData => {
        this.selectedUserName = `${userData.firstName} ${userData.lastName}`;
        this.userSelected = true;
        this.userSubscription.unsubscribe();
      });

    this.userService.getUserData(selectedUserId);
    */
  }

  /* 
    backClicked(): Callback to return from selected user dashboard (app-team-dashboard) to team bubble chart
  */

  backClicked() {
    this.userSelected = false;
  }
}

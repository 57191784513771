import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '@core/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialog } from '@shared/components/alert-dialog/alert-dialog';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css'],
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  ngUnsubscribe: Subject<any> = new Subject<any>();
  mode: string; //Request type
  actionCode: string; //confirmation code
  passwordResetForm: FormGroup;
  actionCodeChecked: boolean;

  constructor(
    private _dialog: MatDialog,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.passwordResetForm = this.formBuilder.group({
      newPassword: ['', Validators.required, Validators.email],
      confirmPassword: ['', Validators.required, Validators.email],
    });

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        // if we didn't receive any parameters,
        // we can't do anything
        if (!params) this.router.navigate(['/login']);

        this.mode = params['mode'];
        this.actionCode = params['oobCode'];

        if (params['mode'] === 'resetPassword') {
          // Verify the password reset code is valid.
          this.authService
            .getAuth()
            .verifyPasswordResetCode(this.actionCode)
            .then((email) => {
              this.actionCodeChecked = true;
            })
            .catch((e) => {
              // Invalid or expired action code. Ask user to try to
              // reset the password again.
              this.displayAlert({ message: e });
              this.router.navigate(['/login']);
            });
        } else {
          console.log('query parameters are missing');
          this.router.navigate(['/login']);
        }
      });
  }

  ngOnDestroy() {
    // End all subscriptions listening to ngUnsubscribe
    // to avoid memory leaks.
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Attempt to confirm the password reset with firebase and
   * navigate user back to home.
   */
  onSubmit() {
    if (
      this.passwordResetForm.get('newPassword').value !==
      this.passwordResetForm.get('confirmPassword').value
    ) {
      this.displayAlert({
        message:
          'Your new password and confirm password do not match. Please check and try again.',
      });
      return;
    }
    // Save the new password.
    this.authService
      .getAuth()
      .confirmPasswordReset(
        this.actionCode,
        this.passwordResetForm.get('newPassword').value
      )
      .then((resp) => {
        // Password reset has been confirmed and new password updated.
        this.displayAlert({
          title: 'Thanks',
          message: 'Your new password has been saved.',
        });
        this.router.navigate(['/login']);
      })
      .catch((e) => {
        // Error occurred during confirmation. The code might have
        // expired or the password is too weak.
        console.log('Error', e);
        this.displayAlert(e);
      });
  }

  displayAlert({ title = 'Ooops', message }) {
    this._dialog.open(AlertDialog, {
      width: '350px',
      data: {
        title,
        message,
      },
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Observable, of, throwError } from 'rxjs';
import { AuthService } from '@core/auth/auth.service';
import { UIService } from '@core/services';
import { Store } from '@ngrx/store';
import * as fromRoot from '@core/store/app.reducer';

import { AlertDialog } from '@shared/components/alert-dialog/alert-dialog';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '@env';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  signInLink: boolean = false;
  signInLinkEmailSent: boolean = false;
  isLoading$: Observable<boolean>;
  private loadingSubs: Subscription;
  ssoEnabled: boolean;

  constructor(
    public afAuth: AngularFireAuth,
    private _dialog: MatDialog,
    private _router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private store: Store<{ ui: fromRoot.State }>
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store.select(fromRoot.getIsLoading);
    this.ssoEnabled = this.authService.ssoEnabled();

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  async onSubmit() {
    this.loginForm.disable();

    if (this.signInLink) {
      const actionCodeSettings = {
        //url: `http://localhost:4200/login-link`,
        url: `https://${environment.firebase.authDomain}/login-link`,
        handleCodeInApp: true,
      };

      let email = this.loginForm.get('email').value;

      try {
        await this.afAuth.sendSignInLinkToEmail(email, actionCodeSettings);
        window.localStorage.setItem('emailForSignIn', email);
        this.signInLinkEmailSent = true;
      } catch (err) {
        const dialogRef = this._dialog.open(AlertDialog, {
          width: '350px',
          data: {
            title: `Ooops`,
            message: `The email address or password you provided is incorrect. Please try again.`,
          },
        });
        this.loginForm.enable();
      }
    } else {
      this.authService
        .login({
          email: this.loginForm.value.email,
          password: this.loginForm.value.password,
        })
        .pipe(catchError((error) => throwError(error)))
        .subscribe({
          complete: () => {},
          next: () => {
            this._router.navigateByUrl('/dashboard');
            /*
        } else {
          const dialogRef = this.dialog.open(EmailVerificationDialog);
          this.router.navigate(['./login']);
        }
        */
          },
          error: (error) => {
            console.log('Error', error);
            const dialogRef = this._dialog.open(AlertDialog, {
              width: '350px',
              data: {
                title: `Ooops`,
                message:
                  'The email address or password you provided is incorrect. Please try again.',
              },
            });

            this.loginForm.enable();
          },
        });
    }
  }

  onSignInLink() {
    this.signInLink = !this.signInLink;
  }
}

<div class="w-full">
  <app-loading *ngIf="isLoading$ | async"></app-loading>

  <!-- Form -->
  <form [formGroup]="accountForm" *ngIf="!(isLoading$ | async)">
    <div
      class="flex flex-col flex-auto mt-2 bg-card shadow rounded overflow-hidden"
    >
      <div class="p-8">
        <div class="grid sm:grid-cols-4 gap-6 w-full">
          <!-- First name -->
          <div class="sm:col-span-2">
            <mat-form-field class="app-mat-no-subscript w-full">
              <mat-label>First name</mat-label>
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:user'"
                matPrefix
              ></mat-icon>
              <input [formControlName]="'firstName'" matInput />
            </mat-form-field>
          </div>
          <!-- Last name -->
          <div class="sm:col-span-2">
            <mat-form-field class="app-mat-no-subscript w-full">
              <mat-label>Last name</mat-label>
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:user'"
                matPrefix
              ></mat-icon>
              <input [formControlName]="'lastName'" matInput />
            </mat-form-field>
          </div>
        </div>
        <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
          <!-- Track -->
          <div class="sm:col-span-2">
            <mat-form-field class="app-mat-no-subscript w-full">
              <mat-label>Track</mat-label>
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:color-swatch'"
                matPrefix
              ></mat-icon>
              <input [formControlName]="'track'" matInput readonly />
            </mat-form-field>
          </div>
          <!-- Position -->
          <div class="sm:col-span-2">
            <mat-form-field class="app-mat-no-subscript w-full">
              <mat-label>Position</mat-label>
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:briefcase'"
                matPrefix
              ></mat-icon>
              <input [formControlName]="'position'" matInput readonly />
            </mat-form-field>
          </div>
        </div>

        <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
          <div class="sm:col-span-2">
            <label class="block text-md font-medium text-gray-700">
              Photo
            </label>
            <div class="mt-1 flex items-center">
              <span
                class="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100 border"
              >
                <svg
                  *ngIf="!profileImageUrl"
                  class="h-full w-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
                <img
                  class="w-full h-full object-cover"
                  src="{{ profileImageUrl }}"
                  *ngIf="profileImageUrl"
                />
              </span>
              <button
                (click)="filePicker.click()"
                type="button"
                class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Change
              </button>
              <input
                #filePicker
                class="h-0 opacity-0 absolute"
                type="file"
                id="file-selection-input"
                style="color: transparent"
                accept=".png,.jpg"
                (change)="handleFileInput($event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-center justify-end mt-10">
      <button
        class="px-6 ml-3"
        mat-flat-button
        [color]="'primary'"
        (click)="save()"
        [disabled]="!accountForm.valid"
      >
        <span *ngIf="!accountForm.disabled"> Save </span>
        <mat-progress-spinner
          *ngIf="accountForm.disabled"
          [diameter]="24"
          [mode]="'indeterminate'"
        ></mat-progress-spinner>
      </button>
    </div>
  </form>
</div>

import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'alert-dialog',
  templateUrl: 'alert-dialog.html',
})
export class AlertDialog {
  data: any;

  constructor(
    public dialogRef: MatDialogRef<AlertDialog>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.data = data;
  }

  onClose(): void {
    this.dialogRef.close('close');
  }

  onAction(): void {
    this.dialogRef.close('action');
  }
}

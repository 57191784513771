import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { CapabilitySet } from '@core/models';
import { CapabilityService } from '@core/services';

@Component({
  selector: 'app-capability-selector',
  templateUrl: './capability-selector.component.html',
  styleUrls: ['./capability-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CapabilitySelectorComponent implements OnInit {
  @Output() delete = new EventEmitter<{
    capabilitySet: CapabilitySet;
    index: Number;
  }>();
  @Output() overlayChange = new EventEmitter<MatListOption[]>();
  @Input() checkinVerb: string = 'Overlay';
  @Input() hideHistory: boolean = false;
  @Input() capabilities: CapabilitySet[] = [];
  @Input() showDelete: boolean;
  @Input() excludeCurrent: boolean = false;
  @Input() multiSelect: boolean = false;
  @ViewChild(MatSelectionList) overlaySelectionList: MatSelectionList;

  constructor(private _capabilityService: CapabilityService) {}

  ngOnInit(): void {}

  getCapabilityOverlays(excludeCurrent: boolean = false) {
    return excludeCurrent
      ? this.capabilities.slice(1, this.capabilities.length)
      : this.capabilities;
  }

  getLatestStatusDate(capability: CapabilitySet) {
    return this._capabilityService.getCurrentTimestamp(capability.status);
  }

  checkInProgress(capability: CapabilitySet): boolean {
    return (
      this._capabilityService.getCurrentStatus(capability.status) !==
      'Completed'
    );
  }

  onOverlaysChange() {
    this.overlayChange.emit(this.overlaySelectionList.selectedOptions.selected);
  }

  onDelete(capabilitySet: CapabilitySet, index: number) {
    this.delete.emit({ capabilitySet, index });
  }
}

<app-navigation
  class="light bg-white print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [opened]="!isScreenSmall"
>
</app-navigation>

<!-- Wrapper -->
<div class="layout-body flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <!-- Navigation toggle button -->
    <button
      *ngIf="false"
      mat-icon-button
      (click)="toggleNavigation('mainNavigation')"
    >
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-2">
      <user-menu></user-menu>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
            Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>

    <span class="w-full text-center p-4">
      <span class="text-xs text-gray-400"
        >BOD Technology Ltd |
        <a href="/legal">License Agreement & Privacy Statement</a></span
      >
    </span>
  </div>
</div>

<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0">
  <div
    class="
      w-full
      sm:w-auto
      py-8
      px-4
      sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card
    "
  >
    <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
      <!-- Title -->
      <div class="text-4xl font-extrabold tracking-tight leading-tight">
        Password reset
      </div>

      <!-- Sign in form -->
      <form
        class="mt-8"
        [formGroup]="passwordResetForm"
        #passwordResetNgForm="ngForm"
      >
        <mat-form-field class="w-full">
          <mat-label>New password</mat-label>
          <input
            id="newPassword"
            matInput
            type="password"
            [formControlName]="'newPassword'"
            #newPassword
          />
          <button
            mat-icon-button
            type="button"
            (click)="
              newPassword.type === 'password'
                ? (newPassword.type = 'text')
                : (newPassword.type = 'password')
            "
            matSuffix
          >
            <mat-icon
              class="icon-size-5"
              *ngIf="newPassword.type === 'password'"
              [svgIcon]="'heroicons_solid:eye'"
            ></mat-icon>
            <mat-icon
              class="icon-size-5"
              *ngIf="newPassword.type === 'text'"
              [svgIcon]="'heroicons_solid:eye-off'"
            ></mat-icon>
          </button>
          <mat-error>New password is required</mat-error>
        </mat-form-field>

        <!-- Old password field -->
        <mat-form-field class="w-full">
          <mat-label>Confirm password</mat-label>
          <input
            id="confirmPassword"
            matInput
            type="password"
            [formControlName]="'confirmPassword'"
            #confirmPassword
          />
          <button
            mat-icon-button
            type="button"
            (click)="
              confirmPassword.type === 'password'
                ? (confirmPassword.type = 'text')
                : (confirmPassword.type = 'password')
            "
            matSuffix
          >
            <mat-icon
              class="icon-size-5"
              *ngIf="confirmPassword.type === 'password'"
              [svgIcon]="'heroicons_solid:eye'"
            ></mat-icon>
            <mat-icon
              class="icon-size-5"
              *ngIf="confirmPassword.type === 'text'"
              [svgIcon]="'heroicons_solid:eye-off'"
            ></mat-icon>
          </button>
          <mat-error>Please confirm your new password</mat-error>
        </mat-form-field>

        <!-- Submit button -->
        <button
          class="app-mat-button-large w-full mt-6"
          mat-flat-button
          [color]="'primary'"
          [disabled]="passwordResetForm.disabled"
          (click)="onSubmit()"
        >
          <span *ngIf="!passwordResetForm.disabled">Submit</span>
          <mat-progress-spinner
            *ngIf="passwordResetForm.disabled"
            [diameter]="24"
            [mode]="'indeterminate'"
          ></mat-progress-spinner>
        </button>
      </form>
    </div>
  </div>
</div>

import { Action } from '@ngrx/store';

export const SET_USER_ROLE = '[Role] Set userRole';
export const SET_REVIEWER_ROLE = '[Role] Set reviewerRole';
export const SET_MANAGER_ROLE = '[Role] Set managerRole';
export const SET_ADMIN_ROLE = '[Role] Set adminRole';

export class SetUserRole implements Action {
  readonly type = SET_USER_ROLE;
}

export class SetReviewerRole implements Action {
  readonly type = SET_REVIEWER_ROLE;
}

export class SetManagerRole implements Action {
  readonly type = SET_MANAGER_ROLE;
}

export class SetAdminRole implements Action {
  readonly type = SET_ADMIN_ROLE;
}

export type RoleActions =
  | SetUserRole
  | SetReviewerRole
  | SetManagerRole
  | SetAdminRole;

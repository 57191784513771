import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'salary-score',
  templateUrl: './salary-score.component.html',
  styleUrls: ['./salary-score.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SalaryScoreComponent {
  @Input() size: string;
  @Input() value: number = 0;
  color: ThemePalette = 'primary';
  displayAsPercentage = true;
  mode: ProgressSpinnerMode = 'determinate';

  band() {
    return 20 * this.value;
  }

  getSize() {
    if (this.size === 'small') {
      return { stroke: 2, diameter: 25 };
    } else if (this.size === 'medium') {
      return { stroke: 4, diameter: 40 };
    } else {
      return { stroke: 5, diameter: 70 };
    }
  }

  formatValue(value: number): string {
    if (this.displayAsPercentage) {
      return `${Math.round((100 / 5) * value)}%`;
    }

    return value.toFixed(2);
  }
}

import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Bugfender } from '@bugfender/sdk';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  constructor(public datepipe: DatePipe) {}

  logTrace(uid: string, msg: string) {
    Bugfender.trace(uid, this.buildLogMessage(uid, msg));
  }

  logInfo(uid: string, msg: string) {
    Bugfender.info(uid, this.buildLogMessage(uid, msg));
  }

  logWarn(uid: string, msg: string) {
    Bugfender.warn(uid, this.buildLogMessage(uid, msg));
  }

  logError(uid: string, msg: string, err: Error) {
    Bugfender.error(uid, this.buildLogMessage(uid, msg, err));
  }

  logFatal(uid: string, msg: string, err: Error) {
    Bugfender.fatal(uid, this.buildLogMessage(uid, msg, err));
  }

  buildLogMessage(uid: string, msg: string, err?: Error): string {
    var date = new Date();
    var dateStr: string = '';
    var logStr: string = '';

    dateStr = this.datepipe.transform(date, 'dd-MM-yyyy (hh-mm-ss)');

    if (err != null) {
      logStr = `${dateStr}: userID=${uid}, msg=${msg}, error=${err.message}`;
    } else {
      logStr = `${dateStr}: userID=${uid}, msg=${msg}`;
    }

    return logStr;
  }
}

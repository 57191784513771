import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { CapabilitySet, CapabilityStatusStep } from '@core/models';
import { CapabilityService } from '@core/services';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent implements OnInit, OnChanges {
  @Output() statusStepChange = new EventEmitter<any>();
  @Output() startNew = new EventEmitter<any>();
  @Output() overlayPrevious = new EventEmitter<any>();
  @Output() gotoDashboard = new EventEmitter<any>();
  @Input() capabilities: CapabilitySet[];
  @Input() currentStatusStep: CapabilityStatusStep;
  @Input() isReviewer: boolean = false;
  @Input() selectionsComplete: boolean = false;
  @Input() submitBadgeNumber: number = 0;
  @Input() productTerm: string;
  @Input() isViewer: boolean = false;

  statusSteps: CapabilityStatusStep[];
  submitDisabled: boolean = false;
  discussDisabled: boolean = false;
  submitTitle: string = 'Submit';
  pendingDiscussion: boolean = false;

  constructor(
    private capabilityService: CapabilityService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.statusSteps = this.capabilityService.getSteps();
  }

  ngOnChanges(): void {
    if (this.currentStatusStep === CapabilityStatusStep.Discuss) {
      this.currentStatusStep = CapabilityStatusStep.Reviewed;
      this.pendingDiscussion = true;
    }

    // Reviewer
    if (this.isReviewer) {
      // Disable submit button for the following states
      this.submitDisabled =
        this.currentStatusStep !== CapabilityStatusStep.Submitted &&
        this.currentStatusStep !== CapabilityStatusStep.Agreed;

      // Change CTA depending on state
      switch (this.currentStatusStep) {
        case CapabilityStatusStep.Agreed:
        case CapabilityStatusStep.Completed:
          this.submitTitle = 'Commit';
          break;
        default:
          this.submitTitle = 'Submit';
          break;
      }

      // User
    } else {
      // Disable submit button for the following states
      this.submitDisabled =
        !this.selectionsComplete ||
        this.currentStatusStep === CapabilityStatusStep.Submitted ||
        this.currentStatusStep === CapabilityStatusStep.Agreed;

      // Disable discuss button if already on discuss
      this.discussDisabled = this.pendingDiscussion;

      // Change CTA depending on state
      switch (this.currentStatusStep) {
        case CapabilityStatusStep.Reviewed:
          this.submitTitle = 'Agree';
          break;
        case CapabilityStatusStep.Completed:
          this.submitTitle = `Start new ${this.productTerm}`;
          break;
        default:
          this.submitTitle = 'Submit';
          break;
      }
    }
  }

  onSubmit() {
    // If completed the only action is to start a new checkin
    if (this.currentStatusStep === CapabilityStatusStep.Completed) {
      this.startNew.emit();

      // Otherwise step through each status
    } else {
      // Tell whoever is listening that the state has changed
      !this.submitDisabled &&
        this.statusStepChange.emit({
          status: this.capabilityService.getNextStep(this.currentStatusStep),
        });
    }

    // Disable to stop double submit occuring
    this.submitDisabled = true;
  }

  onDiscuss() {
    // TODO send notification

    // Set status to discuss
    this.statusStepChange.emit({
      status: CapabilityStatusStep.Discuss,
    });
  }

  onDashboard() {
    this.gotoDashboard.emit();
  }

  onOverlay() {
    this.overlayPrevious.emit();
  }
}

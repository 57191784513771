<div class="salary-score" *ngIf="value > 0">
  <mat-progress-spinner
    class="salary-score__progress"
    [color]="color"
    [mode]="mode"
    [value]="band()"
    [strokeWidth]="getSize().stroke"
    [diameter]="getSize().diameter"
  >
  </mat-progress-spinner>
  <mat-progress-spinner
    class="salary-score__bg-progress"
    mode="determinate"
    value="100"
    [strokeWidth]="getSize().stroke"
    [diameter]="getSize().diameter"
  >
  </mat-progress-spinner>
  <div
    [ngClass]="{
      'salary-score__val': true,
      'salary-score__val--sm': size === 'small',
      'salary-score__val--md': size === 'medium'
    }"
  >
    <span>{{ formatValue(value) }}</span>
  </div>
</div>

import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subject, Subscription } from 'rxjs';

import { UserService } from './user.service';
import { CapabilityService } from './capability.service';
import {
  RadarResults,
  UserResults,
  BubbleRec,
  FocusData,
  BubbleData,
  myUserResults,
} from '@core/models/results.model';
import {
  Capability,
  CapabilitySet,
  CapabilityStatus,
  CapabilityStatusStep,
} from '@core/models/capability.model';
import technicalResults from '@assets/technicalResultsTemplate.json';
import commercialResults from '@assets/commercialResultsTemplate.json';
import operationsResults from '@assets/operationsResultsTemplate.json';
import { filter, map, tap } from 'rxjs/operators';

class myFocusData implements FocusData {
  data: number[];
  label: string;

  constructor(public focusData: number[], public focusName: string) {
    this.data = focusData;
    this.label = focusName;
  }
}

class myRadarResults implements RadarResults {
  id: string;
  date: Date;
  dateStr: string;
  chartLabels: string[];
  chartData: FocusData[];
  bubbleData: BubbleData;

  constructor(public results: RadarResults) {
    this.chartLabels = [];
    results.chartLabels.forEach((label) => {
      this.chartLabels.push(label);
    });

    this.chartData = [];
    results.chartData.forEach((data) => {
      this.chartData.push(data);
    });

    this.bubbleData = results.bubbleData;
  }
}

@Injectable()
export class ResultsService {
  private availableResults: RadarResults[] = [];
  private fbSubs: Subscription[] = [];
  capabilitySubscription: Subscription;

  initialResults: RadarResults;
  techResults: RadarResults = technicalResults;
  commResults: RadarResults = commercialResults;
  opsResults: RadarResults = operationsResults;

  //resultsLoaded = new Subject<RadarResults[]>();
  resultsLoaded = new Subject<UserResults>();
  progressLoaded = new Subject<UserResults>();

  constructor(
    private db: AngularFirestore,
    private userService: UserService,
    private capabilityService: CapabilityService,
    public datepipe: DatePipe
  ) {}

  getInitialResults(track: string) {
    //console.log("Getting result template for track ",track);

    switch (track) {
      case 'Technical': {
        this.initialResults = this.techResults;
        break;
      }
      case 'Commercial': {
        this.initialResults = this.commResults;
        break;
      }
      case 'Operations': {
        this.initialResults = this.opsResults;
        break;
      }
      default: {
        console.log('Unsupported track: ', track);
        return null;
      }
    }

    return this.initialResults;
  }

  getUserResultsFromCapabilities(
    currentUser: string,
    options: {
      currentUserName?: string;
      startDate?: Date;
      endDate?: Date;
      includeInProgress?: boolean;
    } = {}
  ): Observable<{ userResults: myUserResults; capabilities: CapabilitySet[] }> {
    var userResults: myUserResults = new myUserResults();
    var radarResults: myRadarResults;
    var bubbleDataRec: BubbleRec;
    var useCapability: boolean[] = [];
    var useCapabilityDate: Date[] = [];
    var usingCapabilities: CapabilitySet[] = [];
    var use: boolean;
    var lastDateFound: boolean;
    var capabilitiesFound: boolean;
    var c = 0;
    var count: number;
    var reverseIndex: number;
    var index: number;
    var focus: string;
    var score: number = 0;
    var behaviourLabel: string;
    var skillsData: number[] = [];
    var valuesData: number[] = [];
    var knowledgeData: number[] = [];
    var resultsBubble: BubbleData;
    var skillsCount: number = 0;
    var valuesCount: number = 0;
    var knowledgeCount: number = 0;
    var skillsTotal: number = 0;
    var valuesTotal: number = 0;
    var knowledgeTotal: number = 0;
    var radiusFactor: number = 5;
    var sDate: Date;
    var eDate: Date;
    var cDate: Date;
    var capabilityDates: Date[] = [];
    var userCapabilitySubscription: Subscription;
    var currentStatus: CapabilityStatusStep;

    userResults.userId = currentUser;

    if (options.currentUserName != void 0) {
      userResults.userName = options.currentUserName;
    }

    // Initialise to null array
    userResults.dates = [];

    return this.capabilityService
      .getUserCapabilitiesForResults(currentUser)
      .pipe(
        map((capabilityData) => {
          //console.log("getUserResultsFromCapabilities about to process for user = ",currentUser);
          //console.log("getUserResultsFromCapabilities about to process for user = ",currentUserName);
          //console.log("getUserResultsFromCapabilities capabilityData",capabilityData);

          userResults.radarDataSet = [];
          userResults.bubbleDataSet = [];
          useCapability = [];
          useCapabilityDate = [];
          capabilityDates = [];

          // Initialise capability filter
          if (options.startDate == void 0 && options.endDate == void 0) {
            // Use all COMPLETED capability records
            //console.log("getUserResultsFromCapabilities no dates - use all completed capabilities");

            capabilityData.forEach((capability) => {
              currentStatus = this.capabilityService.getCurrentStatus(
                capability.status
              );

              cDate = null;
              use = false;

              if (
                currentStatus === CapabilityStatusStep.Completed ||
                options.includeInProgress
              ) {
                //cDate = new Date(capability.status[4].timestamp);
                cDate = this.capabilityService.getCurrentTimestamp(
                  capability.status
                );
                use = true;
              }

              if (useCapability.length == 0) {
                useCapability[0] = use;
              } else {
                useCapability.push(use);
              }

              if (useCapabilityDate.length == 0) {
                useCapabilityDate[0] = cDate;
              } else {
                useCapabilityDate.push(cDate);
              }
            });
          } else if (options.startDate == void 0) {
            // Find last capability record before endDate - RECORDS IN DESCENDING ORDER
            //console.log("getUserResultsFromCapabilities endDate - use last capability");

            eDate = new Date(options.endDate);

            lastDateFound = false;
            for (let i = 0; i < capabilityData.length; i++) {
              // Only use completed capapbilities
              currentStatus = this.capabilityService.getCurrentStatus(
                capabilityData[i].status
              );

              //console.log("getUserResultsFromCapabilities currentStatus = ",currentStatus);

              cDate = null;

              if (
                currentStatus === CapabilityStatusStep.Completed ||
                options.includeInProgress
              ) {
                //cDate = new Date(capabilityData[i].status[4].timestamp);
                cDate = this.capabilityService.getCurrentTimestamp(
                  capabilityData[i].status
                );

                if (!lastDateFound && cDate <= eDate) {
                  use = true;
                  lastDateFound = true;
                } else {
                  use = false;
                }
              } else {
                use = false;
              }

              if (useCapability.length == 0) {
                useCapability[0] = use;
              } else {
                useCapability.push(use);
              }

              if (useCapabilityDate.length == 0) {
                useCapabilityDate[0] = cDate;
              } else {
                useCapabilityDate.push(cDate);
              }
            }
          } else {
            // Find all capability records in the date range

            sDate = new Date(options.startDate);
            eDate = new Date(options.endDate);

            //console.log("getUserResultsFromCapabilities startDate =",sDate);
            //console.log("getUserResultsFromCapabilities endDate = ",eDate);

            capabilityData.forEach((capability) => {
              // Only use completed capapbilities
              currentStatus = this.capabilityService.getCurrentStatus(
                capability.status
              );

              //console.log("getUserResultsFromCapabilities currentStatus = ",currentStatus);

              cDate = null;

              if (
                currentStatus === CapabilityStatusStep.Completed ||
                options.includeInProgress
              ) {
                //cDate = new Date(capability.status[4].timestamp);
                cDate = this.capabilityService.getCurrentTimestamp(
                  capability.status
                );

                if (cDate >= sDate && cDate <= eDate) {
                  use = true;
                } else {
                  use = false;
                }
              } else {
                use = false;
              }

              if (useCapability.length == 0) {
                useCapability[0] = use;
              } else {
                useCapability.push(use);
              }

              if (useCapabilityDate.length == 0) {
                useCapabilityDate[0] = cDate;
              } else {
                useCapabilityDate.push(cDate);
              }
            });
          }

          //console.log(currentUser, currentUserName, startDate, endDate)
          //console.log(useCapability);

          //console.log("getUserResultsFromCapabilities useCapability = ",useCapability);
          //console.log("getUserResultsFromCapabilities useCapabilityDate = ",useCapabilityDate);

          // Check there are any capability records for the specified dates
          capabilitiesFound = false;
          useCapability.forEach((useIt) => {
            if (useIt) {
              capabilitiesFound = true;
            }
          });

          //console.log("getUserResultsFromCapabilities: capabilitiesFound = ", capabilitiesFound);
          //console.log("getUserResultsFromCapabilities: useCapability = ", useCapability);

          if (capabilitiesFound) {
            count = capabilityData.length;

            //console.log("getUserResultsFromCapabilities: capability count = ", count);
            capabilityData.forEach((capability, index) => {
              if (useCapability[index]) {
                usingCapabilities.push(capability);

                switch (capability.track) {
                  case 'Technical': {
                    radarResults = new myRadarResults(this.techResults);
                    break;
                  }
                  case 'Commercial': {
                    radarResults = new myRadarResults(this.commResults);
                    break;
                  }
                  case 'Operations': {
                    radarResults = new myRadarResults(this.opsResults);
                    break;
                  }
                  default: {
                    console.log('Unsupported track: ', capability.track);
                    useCapability[index] = false;
                  }
                }

                skillsData = [];
                valuesData = [];
                knowledgeData = [];

                skillsCount = 0;
                skillsTotal = 0;
                valuesCount = 0;
                valuesTotal = 0;
                knowledgeCount = 0;
                knowledgeTotal = 0;

                c = 0;
                while (capability.capabilities[c]) {
                  behaviourLabel = capability.selectedBehaviourLabels[c];

                  if (behaviourLabel === 'A') {
                    score = 20;
                  } else if (behaviourLabel === 'B') {
                    score = 40;
                  } else if (behaviourLabel === 'C') {
                    score = 60;
                  } else if (behaviourLabel === 'D') {
                    score = 80;
                  } else if (behaviourLabel === 'E') {
                    score = 100;
                  } else {
                    score = 0;
                  }

                  focus = capability.capabilities[c].focus;

                  if (focus === 'Skills') {
                    skillsData.push(score);
                    valuesData.push(0);
                    knowledgeData.push(0);
                    skillsCount += 1;
                    skillsTotal += score;
                  } else if (focus === 'Values') {
                    skillsData.push(0);
                    valuesData.push(score);
                    knowledgeData.push(0);
                    valuesCount += 1;
                    valuesTotal += score;
                  } else if (focus === 'Knowledge') {
                    skillsData.push(0);
                    valuesData.push(0);
                    knowledgeData.push(score);
                    knowledgeCount += 1;
                    knowledgeTotal += score;
                  }

                  c++;
                }

                // Assemble user results
                bubbleDataRec = {
                  x: valuesTotal / valuesCount - 50,
                  y: skillsTotal / skillsCount - 50,
                  r: knowledgeTotal / (knowledgeCount * radiusFactor),
                };
                userResults.bubbleDataSet.push(bubbleDataRec);

                radarResults.id = capability.id;
                radarResults.dateStr = this.datepipe.transform(
                  useCapabilityDate[index],
                  'MMM d, y, h:mm:ss a'
                );
                radarResults.chartData[0] = new myFocusData(
                  skillsData,
                  'Skills'
                );
                radarResults.chartData[1] = new myFocusData(
                  valuesData,
                  'Values'
                );
                radarResults.chartData[2] = new myFocusData(
                  knowledgeData,
                  'Knowledge'
                );
                userResults.radarDataSet.push(radarResults);

                capabilityDates.push(new Date(useCapabilityDate[index]));
              }

              count--;
              if (count === 0) {
                userResults.dates = capabilityDates;
              }
            });

            return { userResults, capabilities: usingCapabilities };
          } else {
            //console.log('null')
            return { userResults: null, capabilities: null };
          }
        })
      );
  }

  getUserProgressFromCapabilities(
    currentUser: string,
    currentUserName: string,
    startIndex: number,
    endIndex: number
  ): Observable<myUserResults> {
    var userResults: myUserResults = new myUserResults();
    var radarResults: myRadarResults;
    var userTrack: string;
    var capabilitiesFound: boolean;
    var startCapabilities: Capability[];
    var endCapabilities: Capability[];
    var c = 0;
    var count: number;
    var reverseIndex: number;
    var index: number;
    var focus: string;
    var startScore: number = 0;
    var endScore: number = 0;
    var startBehaviourLabel: string;
    var endBehaviourLabel: string;
    var skillsData: number[] = [];
    var valuesData: number[] = [];
    var knowledgeData: number[] = [];
    var userCapabilitySubscription: Subscription;

    userResults.userId = currentUser;

    return this.capabilityService.getUserCapabilities(currentUser).pipe(
      map((capabilityData) => {
        //console.log("getUserResultsFromCapabilities about to process for user = ",currentUser);
        //console.log("getUserResultsFromCapabilities about to process for user = ",currentUserName);
        //console.log("getUserResultsFromCapabilities capabilityData",capabilityData);

        if (capabilityData.length) {
          //console.log("getUserResultsFromCapabilities processing for user = ",currentUserName);

          userTrack = capabilityData[0].track;

          //console.log("getUserResultsFromCapabilities userTrack = ",userTrack);

          userResults.radarDataSet = [];

          //console.log("getUserResultsFromCapabilities start capapbilites = ",capabilityData[startIndex].capabilities);
          //console.log("getUserResultsFromCapabilities end capapbilites = ",capabilityData[endIndex].capabilities);

          if (
            capabilityData[startIndex].capabilities !== null &&
            capabilityData[endIndex].capabilities !== null
          ) {
            capabilitiesFound = true;
          } else {
            capabilitiesFound = false;
            console.log('Selected capability sets do not exist');
          }

          //console.log("getUserResultsFromCapabilities: capabilitiesFound = ", capabilitiesFound);

          if (capabilitiesFound) {
            count = capabilityData.length;
            index = 0;

            //console.log("getUserResultsFromCapabilities: capability count = ", count);

            switch (userTrack) {
              case 'Technical': {
                radarResults = new myRadarResults(this.techResults);
                break;
              }
              case 'Commercial': {
                radarResults = new myRadarResults(this.commResults);
                break;
              }
              case 'Operations': {
                radarResults = new myRadarResults(this.opsResults);
                break;
              }
              default: {
                console.log('Unsupported track: ', userTrack);
                //Test patch set to Technical
                radarResults = new myRadarResults(this.techResults);
                //return null;
              }
            }

            skillsData = [];
            valuesData = [];
            knowledgeData = [];

            startCapabilities = capabilityData[startIndex].capabilities;
            endCapabilities = capabilityData[endIndex].capabilities;

            c = 0;
            while (startCapabilities[c] && endCapabilities[c]) {
              startBehaviourLabel =
                capabilityData[startIndex].selectedBehaviourLabels[c];

              if (startBehaviourLabel === 'A') {
                startScore = 1;
              } else if (startBehaviourLabel === 'B') {
                startScore = 2;
              } else if (startBehaviourLabel === 'C') {
                startScore = 3;
              } else if (startBehaviourLabel === 'D') {
                startScore = 4;
              } else if (startBehaviourLabel === 'E') {
                startScore = 5;
              } else {
                startScore = 0;
              }

              endBehaviourLabel =
                capabilityData[endIndex].selectedBehaviourLabels[c];

              if (endBehaviourLabel === 'A') {
                endScore = 1;
              } else if (endBehaviourLabel === 'B') {
                endScore = 2;
              } else if (endBehaviourLabel === 'C') {
                endScore = 3;
              } else if (endBehaviourLabel === 'D') {
                endScore = 4;
              } else if (endBehaviourLabel === 'E') {
                endScore = 5;
              } else {
                endScore = 0;
              }

              focus = capabilityData[startIndex].capabilities[c].focus;

              if (focus === 'Skills') {
                skillsData.push(endScore - startScore);
                valuesData.push(0);
                knowledgeData.push(0);
              } else if (focus === 'Values') {
                skillsData.push(0);
                valuesData.push(endScore - startScore);
                knowledgeData.push(0);
              } else if (focus === 'Knowledge') {
                skillsData.push(0);
                valuesData.push(0);
                knowledgeData.push(endScore - startScore);
              }

              c++;
            }

            // Assemble user results
            radarResults.chartData[0] = new myFocusData(skillsData, 'Skills');
            radarResults.chartData[1] = new myFocusData(valuesData, 'Values');
            radarResults.chartData[2] = new myFocusData(
              knowledgeData,
              'Knowledge'
            );
            userResults.radarDataSet.push(radarResults);

            return userResults;
          }

          return null;
        }
      })
    );
  }

  getUserResults(currentUser: string, currentUserName?: string) {
    var userResults: myUserResults = new myUserResults();
    var bubbleDataRec: BubbleRec[] = [];
    var resultDates: Date[] = [];

    //console.log("getUserResults: current user is ", currentUser);

    //this.uiService.loadingStateChanged.next(true);

    var resultsPath: string = `users/${currentUser}/results`;

    //console.log("Results path is ", resultsPath);

    if (currentUser) {
      //console.log("currentUser = ", currentUser);

      userResults.userId = currentUser;

      if (currentUserName != void 0) {
        userResults.userName = currentUserName;
      }

      //console.log("userResults = ", this.userResults );

      this.fbSubs.push(
        this.db
          .collection(resultsPath, (ref) => ref.orderBy('date'))
          .valueChanges()
          .subscribe((results: RadarResults[]) => {
            //console.log("valuechanges =", results);

            userResults.radarDataSet = results;

            results.forEach((element) => {
              //console.log("element.bubbleData = ", element.bubbleData);
              bubbleDataRec.push(element.bubbleData.data);
              resultDates.push(element.date);
            });

            userResults.bubbleDataSet = bubbleDataRec;
            userResults.dates = resultDates;

            //console.log("userResults = ", userResults );

            this.resultsLoaded.next(userResults);

            //console.log("set next resultsLoaded");
            //this.uiService.loadingStateChanged.next(false);
          })
      );
    } else {
      console.log('Could not get results for null user');
    }
  }

  getUserResultsForDate(
    currentUser: string,
    currentUserName: string,
    selectedDate: Date
  ) {
    var userResults: myUserResults;
    var bubbleDataRec: BubbleRec[] = [];
    var resultDates: Date[] = [];

    //console.log("getUserResultsForDate: current user is ", currentUser);

    //this.uiService.loadingStateChanged.next(true);

    var capabilitiesPath: string = `users/${currentUser}/capabilities`;

    //console.log("Results path is ", resultsPath);

    if (currentUser) {
      //console.log("currentUser = ", currentUser);

      this.db
        .collection(capabilitiesPath, (ref) =>
          ref.where('submittedOn', '<=', selectedDate)
        )
        .valueChanges()
        .subscribe((results: RadarResults[]) => {
          // console.log("valuechanges =", results);

          userResults = new myUserResults();
          userResults.userId = currentUser;
          userResults.userName = currentUserName;

          userResults.radarDataSet = results;

          results.forEach((element) => {
            bubbleDataRec.push(element.bubbleData.data);
            resultDates.push(element.date);
          });

          userResults.bubbleDataSet = bubbleDataRec;
          userResults.dates = resultDates;

          this.resultsLoaded.next(userResults);
        });
    } else {
      console.log('Could not get results for null user');
    }
  }

  getResults(currentUser: string) {
    //console.log("getUserResults: current user is ", currentUser);

    //this.uiService.loadingStateChanged.next(true);

    var resultsPath: string = `users/${currentUser}/results`;

    //console.log("Results path is ", resultsPath);

    if (currentUser) {
      this.fbSubs.push(
        this.db
          .collection(resultsPath)
          .valueChanges()
          .subscribe((results: RadarResults[]) => {
            //console.log("valuechanges =", results);
            this.availableResults = results;
            //this.resultsLoaded.next([ ...this.availableResults ]);

            // Result aggregation code HERE!

            //this.uiService.loadingStateChanged.next(false);
          })
      );
    } else {
      console.log('Could not get results for null user');
    }
  }

  storeResults(currentUser: string, results: RadarResults, resultDate: Date) {
    var resultDocPrefix: string = 'Results-';
    var resultDocName: string;
    var resultsPath: string;
    var resultDate: Date;
    var resultDateStr: string;

    //console.log("current user is ", currentUser);

    resultsPath = `users/${currentUser}/results`;

    //console.log("Results path is ", resultsPath);

    //resultDate = new Date();
    resultDateStr = this.datepipe.transform(
      resultDate,
      'dd-MM-yyyy (hh-mm-ss)'
    );
    resultDocName = resultDocPrefix.concat(resultDateStr);

    if (currentUser) {
      this.db
        .collection(resultsPath)
        .doc(resultDocName)
        .set({
          chartLabels: results.chartLabels,
          chartData: results.chartData,
          bubbleData: results.bubbleData,
          date: resultDate,
          dateStr: resultDateStr,
        })
        .then(() => {
          //Mark capabilities as submitted
        })
        .catch((error) => {
          console.error(
            'Results Failed to Save for User',
            currentUser,
            results.dateStr,
            error
          );
        });
    } else {
      console.log('Could not save results to null user');
    }
  }

  cancelSubscriptions() {
    this.fbSubs.forEach((sub) => sub.unsubscribe());
  }
}

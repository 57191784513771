import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '@core/auth/auth.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '@core/store/app.reducer';

import { Router } from '@angular/router';
import { delay, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {
  isLoading$: Observable<boolean>;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private store: Store<{ ui: fromRoot.State }>
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store.select(fromRoot.getIsLoading);

    this._authService
      .logout()
      .pipe(
        delay(1000),
        take(1),
        tap(() => {
          window.location.href = '/login';
        })
      )
      .subscribe();
  }
}

import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import firebase from 'firebase';

import { UIService } from './ui.service';
import { Team, TeamData, TeamMemberStatus } from '@core/models/teams.model';

import { Store } from '@ngrx/store';
import * as fromRoot from '@core/store/app.reducer';
import { BaseService } from './base.service';
import {
  combineLatest,
  concat,
  defer,
  forkJoin,
  from,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { Member, MemberStatus } from '@core/models/member.model';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { IDocModel } from '@core/models/base.model';
import { UserData } from '@core/models';
import { Invite } from '@core/models/invite.model';
import { stringToSlug } from '@core/utils/slugify';
import { environment } from '@env';

@Injectable()
export class TeamService extends BaseService {
  private _defaultTeamMembership = new ReplaySubject<Member>();

  constructor(
    private db: AngularFirestore,
    private http: HttpClient,
    private uiService: UIService,
    private store: Store<fromRoot.State>,
    public datepipe: DatePipe
  ) {
    super('teams', db);
  }

  // ***************
  // Getters/Setters
  // ***************

  set defaultTeamMembership(value: Member) {
    this._defaultTeamMembership.next(value);
  }

  get defaultTeamMembership$(): Observable<Member> {
    return this._defaultTeamMembership.asObservable();
  }

  //***************
  // Functions:
  //***************

  addMemberFromInvite(user: UserData, invite: Invite): Observable<Member> {
    return from(
      this.addOrUpdate<Member>(
        <Member>{
          id: user.id,
          uid: user.id,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          role: 'member',
          team: invite.team,
          track: user.track,
          photoUrl: user.photoUrl || '',
          joinedOn: new Date(),
          type: 'team',
          status: user.status || {},
        },
        `/teams/${invite.team.id}/members`,
        true
      )
    );
  }

  getTeam(teamId: string): Observable<Team> {
    return this.get<Team>(teamId, `/teams`);
  }

  getTeamByName(
    name: string,
    options: { orgId?: string; uid?: string }
  ): Observable<Team[]> {
    // Because firestore 'where ==' is case sensitive, internally we use the slug
    const slug = stringToSlug(name);

    return this.list<Team>(`teams`, (ref) => {
      if (options.orgId) {
        return ref
          .where('slug', '==', slug)
          .where('orgId', '==', options.orgId);
      } else if (options.uid) {
        return ref
          .where('slug', '==', slug)
          .where('createdByUid', '==', options.uid);
      } else {
        return ref.where('slug', '==', slug);
      }
    });
  }

  getTeamsById(teamIds: string[]): Observable<Team[]> {
    return this.list<Team>(`/teams`, (ref) =>
      ref.where(firebase.firestore.FieldPath.documentId(), 'in', teamIds)
    );
  }

  getTeamsByOrg(orgId: string): Observable<Team[]> {
    return this.list<Team>(`/teams`, (ref) => ref.where('orgId', '==', orgId));
  }

  getMembersOfTeams(
    teamIds: string[],
    roles: string[] = []
  ): Observable<Member[]> {
    return this.listGroup<Member>(`members`, (ref) => {
      return ref
        .where('team.id', 'in', teamIds.slice(0, 9)) // TODO: Firebase only supports up to 10 at a time, need to fix
        .where('type', '==', 'team');
    }).pipe(
      map((members) => {
        return roles.length
          ? members.filter((m) => roles.includes(m.role))
          : members;
      })
    );
  }

  getTeamMembershipsByUserId(
    userId: string,
    types: string[] = ['personal', 'org']
  ): Observable<Member[]> {
    return this.listGroup<Member>(`members`, (ref) =>
      ref
        .where('uid', '==', userId)
        .where('team.type', 'in', types)
        .where('type', '==', 'team')
    );
  }

  getMemberByTeamId(teamId: string, userId: string): Observable<Member> {
    return this.getFirstOrDefault<Member>({
      collection: `teams/${teamId}/members`,
      query: (ref) =>
        ref.where('team.id', '==', teamId).where('uid', '==', userId),
    });
  }

  getMembersByUserRole(userId: string, roles: string[]): Observable<Member[]> {
    return this.listGroup<Member>('members', (ref) =>
      ref
        .where('uid', '==', userId)
        .where('role', 'in', roles)
        .where('type', '==', 'team')
    );
  }

  getMembersByEmail(email: string): Observable<Member[]> {
    return this.listGroup<Member>('members', (ref) =>
      ref.where('email', '==', email).where('type', '==', 'team')
    );
  }

  createTeam(name: string, user: UserData, orgId?: string): Observable<Team> {
    let team = <Team>{
      name: name.trim(),
      slug: stringToSlug(name),
      createdByUid: user.id,
      createdByName: `${user.firstName} ${user.lastName}`,
      type: orgId ? 'org' : 'personal',
      createdOn: new Date(),
    };

    if (orgId) {
      team.orgId = orgId;
    }

    return from(this.addOrUpdate<Team>(team)).pipe(
      switchMap((createdTeam) =>
        from(
          this.addOrUpdate<Member>(
            <Member>{
              id: user.id,
              uid: user.id,
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
              role: 'reviewer',
              team: <any>createdTeam,
              status: user.status,
              track: user.track,
              photoUrl: user.photoUrl || '',
              joinedOn: new Date(),
              type: 'team',
            },
            `/teams/${createdTeam.id}/members`,
            true
          )
        ).pipe(map((member) => ({ createdTeam, member })))
      ),
      map((x) => x.createdTeam)
    );
  }

  updateRole(
    memberId: string,
    teamId: string,
    role: string
  ): Observable<boolean> {
    return from(
      this.update(<Member>{ id: memberId, role }, `teams/${teamId}/members`)
    ).pipe(
      mergeMap((response) => of(true)),
      catchError((error) => of(false))
    );
  }

  updateTeam(
    team: Team,
    reviewer?: { newId: string; existingId: string }
  ): Observable<Team> {
    return this.http
      .patch<{ team: Team }>(`${environment.api}/teams/${team.id}`, {
        name: team.name,
        reviewerUserId: reviewer.newId,
      })
      .pipe(map((response) => response.team));
  }

  addMemberReviewLock(
    userId: string,
    reviewByUid: string,
    reviewByName: string
  ): Observable<boolean> {
    return this.getTeamMembershipsByUserId(userId)
      .pipe(
        switchMap((memberships) => {
          let teamMembers: IDocModel[] = [];

          memberships.forEach((member) => {
            teamMembers.push(<IDocModel>{
              id: member.id,
              collection: `teams/${member.team.id}/members`,
              model: <Member>{
                ...member,
                review: {
                  reviewByUid,
                  reviewByName,
                },
              },
              action: 'update',
            });
          });

          return this.batchUpdate(teamMembers);
        })
      )
      .pipe(
        mergeMap((response) => of(true)),
        catchError((error) => of(false))
      );
  }

  removeMemberReviewLock(userId: string): Observable<boolean> {
    return this.getTeamMembershipsByUserId(userId)
      .pipe(
        switchMap((memberships) => {
          let teamMembers: IDocModel[] = [];

          memberships.forEach((member) => {
            teamMembers.push(<IDocModel>{
              id: member.id,
              collection: `teams/${member.team.id}/members`,
              model: <Member>{
                ...member,
                review: firebase.firestore.FieldValue.delete(),
              },
              action: 'update',
            });
          });

          return this.batchUpdate(teamMembers);
        })
      )
      .pipe(
        mergeMap((response) => of(true)),
        catchError((error) => of(false))
      );
  }

  updateTeamReviewer(
    teamId: string,
    reviewerMemberId: string
  ): Observable<boolean> {
    return this.getMembersOfTeams([teamId]).pipe(
      switchMap((members) => {
        let observables: Observable<any>[] = [];
        let collection: string = `teams/${teamId}/members`;

        // Find current reviewer and store update observable
        let currentReviewer = members.find((m) => m.role === 'reviewer');
        if (currentReviewer) {
          observables.push(
            from(
              defer(() =>
                this.update(
                  <Member>{ id: currentReviewer.id, role: 'member' },
                  collection
                )
              )
            )
          );
        }

        // Find new reviewer
        let newReviewer = members.find((m) => m.id === reviewerMemberId);
        observables.push(
          from(
            defer(() =>
              this.update(
                <Member>{ id: newReviewer.id, role: 'reviewer' },
                collection
              )
            )
          )
        );

        return forkJoin(observables).pipe(
          mergeMap((result) => of(true)),
          catchError((error) => of(false))
        );
      }),
      mergeMap((result) => {
        return of(result);
      })
    );
  }

  updateStatus(
    userId: string,
    status: MemberStatus,
    salaryScore: number
  ): Observable<void> {
    return this.getTeamMembershipsByUserId(userId).pipe(
      switchMap((memberships) => {
        let teamMembers: IDocModel[] = [];

        memberships.forEach((member) => {
          teamMembers.push(<IDocModel>{
            id: member.id,
            collection: `teams/${member.team.id}/members`,
            model: <Member>{
              ...member,
              status,
              salaryScore,
            },
            action: 'update',
          });
        });

        return this.batchUpdate(teamMembers);
      }),
      take(1)
    );
  }

  updateProfileImage(userId: string, photoUrl: string): Observable<void> {
    return this.getTeamMembershipsByUserId(userId).pipe(
      switchMap((memberships) => {
        let teamMembers: IDocModel[] = [];

        memberships.forEach((member) => {
          teamMembers.push(<IDocModel>{
            id: member.id,
            collection: `teams/${member.team.id}/members`,
            model: <Member>{
              ...member,
              photoUrl,
            },
            action: 'update',
          });
        });

        return this.batchUpdate(teamMembers);
      }),
      take(1)
    );
  }

  deleteTeam(team: Team) {
    return (
      this.db
        .collection(`teams/${team.id}/members`)
        .get()
        .toPromise()

        // Delete members
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.delete();
          });
        })

        // Delete team doc
        .then(() => {
          this.delete(team.id);
        })
    );
  }
}

import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, from, Observable, of, ReplaySubject } from 'rxjs';

import { UIService } from './ui.service';
import { BaseService } from './base.service';
import { Member, MemberStatus } from '@core/models/member.model';
import { Organisation, OrganisationPosition } from '@core/models/org.model';

import { Store } from '@ngrx/store';
import * as fromRoot from '@core/store/app.reducer';
import { UserData, UserOrg } from '@core/models';
import { IDocModel } from '@core/models/base.model';
import {
  catchError,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

@Injectable()
export class OrgService extends BaseService {
  private _member: ReplaySubject<Member> = new ReplaySubject<Member>(1);
  private _organisation = new BehaviorSubject<Organisation>(null);
  private _positions = new BehaviorSubject<OrganisationPosition[]>(null);
  private _currentOrgId: string;

  constructor(
    private db: AngularFirestore,
    private http: HttpClient,
    private uiService: UIService,
    private store: Store<fromRoot.State>,
    public datepipe: DatePipe
  ) {
    super('organisations', db);
  }

  // ***************
  // Getters/Setters
  // ***************

  set currentOrgId(value: string) {
    this._currentOrgId = value;
  }

  get currentOrgId(): string {
    return this._currentOrgId;
  }

  set organisation(value: Organisation) {
    this._organisation.next(value);
  }

  get organisation(): Organisation {
    return this._organisation.getValue();
  }

  get organisation$(): Observable<Organisation> {
    return this._organisation.asObservable();
  }

  set member(value: Member) {
    this._member.next(value);
  }

  get member$(): Observable<Member> {
    return this._member.asObservable();
  }

  get positions(): OrganisationPosition[] {
    return this._positions.getValue();
  }

  get positions$(): Observable<OrganisationPosition[]> {
    return this._positions.asObservable();
  }

  set positions(value: OrganisationPosition[]) {
    this._positions.next(value);
  }

  //***************
  // Functions:
  //***************

  getOrganisation(id?): Observable<Organisation> {
    return this.get<Organisation>(id || this._currentOrgId);
  }

  getMembersOfTeams(teams: string[]): Observable<Member[]> {
    return this.list<Member>(
      `/organisations/${this._currentOrgId}/members`,
      (ref) => ref.where('team', 'in', teams)
    );
  }

  getAllMembersByOrg(orgId?: string): Observable<Member[]> {
    return this.list<Member>(
      `/organisations/${orgId || this._currentOrgId}/members`
    );
  }

  getMemberByUserId(userId: string): Observable<Member> {
    return this.getFirstOrDefault<Member>({
      collection: `/organisations/${this._currentOrgId}/members`,
      query: (ref) => ref.where('uid', '==', userId),
    });
  }

  getMembersById(ids: string[]) {
    return this.getManyById(
      ids,
      `/organisations/${this._organisation.getValue().id}/members`
    );
  }

  getPositions(): Observable<OrganisationPosition[]> {
    return this.http
      .get<OrganisationPosition[]>(
        `${environment.api}/organisations/current/positions`
      )
      .pipe(
        map((response: any) => response.positions),
        tap((positions) => {
          this._positions.next(positions);
        })
      );
  }

  addUserAsMember(
    user: UserData,
    orgId: string,
    role: string = 'member'
  ): Observable<Member> {
    return from(
      this.addOrUpdate<Member>(
        <Member>{
          id: user.id,
          uid: user.id,
          orgId,
          name: `${user.firstName} ${user.lastName}`,
          role,
          joinedOn: new Date(),
          type: 'org',
        },
        `/organisations/${orgId}/members`,
        true
      )
    );
  }

  /*
    updateMemberInOrganisations(userMemberships: UserOrg[], member: Member) : Promise<void> {
        let orgMembers: IDocModel[] = [];

        userMemberships.forEach((org) => {
            orgMembers.push(<IDocModel> {
                id: org.memberId,
                collection: `Organisations/${org.orgId}/Members`,
                model: member
            })
        });

        return this.batchUpdate(orgMembers);
    }
    */

  updateOrg(organisation: Organisation) {
    return from(this.update(organisation)).pipe(
      map((org) => this._organisation.next(org)),
      mergeMap((response) => of(true)),
      catchError((error) => of(false))
    );
  }

  updateStatus(userId: string, status: MemberStatus): Observable<Member> {
    return this.getMemberByUserId(userId).pipe(
      first(),
      switchMap((member) =>
        this.update(
          { ...member, status },
          `organisations/${this._currentOrgId}/members`
        )
      )
    );
  }

  updateRole(
    memberId: string,
    orgId: string,
    role: string
  ): Observable<boolean> {
    return from(
      this.update(
        <Member>{ id: memberId, role: role },
        `organisations/${orgId}/members`
      )
    ).pipe(
      mergeMap((response) => of(true)),
      catchError((error) => of(false))
    );
  }

  updateProducts(
    memberId: string,
    orgId: string,
    products: string[]
  ): Observable<boolean> {
    return from(
      this.update(
        <Member>{ id: memberId, products },
        `organisations/${orgId}/members`
      )
    ).pipe(
      mergeMap((response) => of(true)),
      catchError((error) => of(false))
    );
  }

  createPosition(
    position: OrganisationPosition
  ): Observable<OrganisationPosition> {
    return this.http.post<OrganisationPosition>(
      `${environment.api}/organisations/current/positions`,
      position
    );
  }

  updatePosition(position: OrganisationPosition): Observable<boolean> {
    return this.http
      .patch<OrganisationPosition>(
        `${environment.api}/organisations/current/positions/${position.id}`,
        position
      )
      .pipe(
        mergeMap((_) => of(true)),
        catchError((_) => of(false))
      );
  }

  updateMember(member: Member): Observable<boolean> {
    return this.http
      .patch<Member>(
        `${environment.api}/organisations/current/members/${member.id}`,
        member
      )
      .pipe(
        mergeMap((_) => of(true)),
        catchError((_) => of(false))
      );
  }
}

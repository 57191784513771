import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of, Subject, Subscription } from 'rxjs';

import {
  Capability,
  CapabilitySet,
  CapabilitySetAdapter,
  CapabilityStatus,
  CapabilityStatusStep,
  ModelBehaviourUpload,
  NoteCount,
} from '@core/models';
import technicalCapabilities from '@assets/technicalCapabilities.json';
import commercialCapabilities from '@assets/commercialCapabilities.json';
import operationsCapabilities from '@assets/operationsCapabilities.json';
import { BaseService } from './base.service';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class CapabilityService extends BaseService {
  // Track Capabilities
  techCapabilities: Capability[] = technicalCapabilities;
  commCapabilities: Capability[] = commercialCapabilities;
  opsCapabilities: Capability[] = operationsCapabilities;

  private fbSubs: Subscription[] = [];
  capabilitesLoaded = new Subject<CapabilitySet[]>();
  public readonly capabilities: Observable<CapabilitySet[]> =
    this.capabilitesLoaded.asObservable();

  constructor(
    private db: AngularFirestore,
    private adapter: CapabilitySetAdapter,
    public datepipe: DatePipe
  ) {
    super('Capabilities', db);
  }

  getInitialCapabilities(
    track: string,
    orgId: string
  ): Observable<Capability[]> {
    return this.getFirstOrDefault<ModelBehaviourUpload>({
      collection: `/organisations/${orgId}/model-behaviours`,
      query: (ref) =>
        ref
          .where('track', '==', track)
          .where('active', '==', true)
          .orderBy('createdOn', 'desc')
          .limit(1),
    }).pipe(
      map((response) => {
        if (response && response.data) {
          return response.data.sort((a, b) => a.order - b.order);
        }

        switch (track) {
          case 'Technical': {
            return this.techCapabilities;
          }
          case 'Commercial': {
            return this.commCapabilities;
          }
          case 'Operations': {
            return this.opsCapabilities;
          }
          default: {
            console.error('Unsupported track: ', track);
            return null;
          }
        }
      })
    );
  }

  getInitialBehaviours(capabilities: Capability[], track: string): string[] {
    var initialBehaviours: string[] = [];

    capabilities.forEach(() => {
      if (initialBehaviours.length == 0) {
        initialBehaviours[0] = null;
      } else {
        initialBehaviours.push(null);
      }
    });

    return initialBehaviours;
  }

  getUserCapabilities(currentUser: string): Observable<CapabilitySet[]> {
    let capabilitiesPath: string = `users/${currentUser}/capabilities`;
    return this.list<CapabilitySet>(capabilitiesPath, (ref) =>
      ref.orderBy('createdOn', 'desc')
    );
  }

  getUserCapabilitiesForResults(currentUser: string) {
    let capabilitiesPath: string = `users/${currentUser}/capabilities`;
    return this.list<CapabilitySet>(capabilitiesPath, (ref) =>
      ref.orderBy('createdOn', 'desc')
    );

    /*
        .subscribe((capabilitySets: CapabilitySet[]) => {
            //If user has no capabilities send an empty one for result set completeness confirmation
            if (capabilitySets.length == 0) {
                capabilitySets[0] = <CapabilitySet>{
                    track: "",
                    capabilities: [],
                    selectedBehaviourLabels: [],
                    notes: [],
                    createdOn: new Date(),
                    status: [{ status: CapabilityStatusStep.Started, timestamp: new Date() }],
                };
            }
            capabilitySets[0].uid = currentUser;
            this.capabilitesLoaded.next(capabilitySets);
        });
        */
  }

  storeInitialCapabilities(
    currentUser: string,
    track: string,
    initialCapabilities: Capability[],
    initialBehaviours: string[]
  ): Promise<CapabilitySet> {
    let capabilitiesPath: string = `users/${currentUser}/capabilities`;

    return this.addOrUpdate(
      <CapabilitySet>{
        track,
        capabilities: initialCapabilities,
        selectedBehaviourLabels: initialBehaviours,
        status: [
          { status: CapabilityStatusStep.Started, timestamp: new Date() },
        ],
        createdOn: new Date(),
        notes: [],
      },
      capabilitiesPath
    );
  }

  storeCapabilitySelection(
    currentUser: string,
    selectedBehaviours: string[],
    capabilityId: string,
    isReviewedBehaviours: boolean = false
  ): Promise<CapabilitySet> {
    let capabilitiesPath = `users/${currentUser}/capabilities`;

    let model = { id: capabilityId };
    model = Object.assign(
      model,
      !isReviewedBehaviours
        ? {
            selectedBehaviourLabels: selectedBehaviours,
          }
        : {
            reviewedBehaviourLabels: selectedBehaviours,
          }
    );

    return this.update<CapabilitySet>(model as CapabilitySet, capabilitiesPath);
  }

  getByUserId(userId: string): Observable<CapabilitySet[]> {
    return this.list<CapabilitySet>(`users/${userId}/capabilities`);
  }

  getLatestByUserId(userId: string): Observable<CapabilitySet> {
    return this.list<CapabilitySet>(`users/${userId}/capabilities`, (ref) =>
      ref.orderBy('createdOn', 'desc')
    ).pipe(
      tap((m) => console.log(m)),
      map((sets) => (sets.length ? sets[0] : null))
    );
  }

  updateStatus(
    capabilityId: string,
    currentUser: string,
    nextStatusStep: CapabilityStatusStep,
    currentStatus: CapabilityStatus[],
    otherFields: CapabilitySet = <CapabilitySet>{}
  ): Promise<CapabilitySet> {
    let capabilitiesPath = `users/${currentUser}/capabilities`;
    let newStatus = <CapabilityStatus>{
      status: nextStatusStep,
      timestamp: new Date(),
    };

    return this.update<CapabilitySet>(
      <CapabilitySet>Object.assign(
        {
          id: capabilityId,
          status: [...currentStatus, newStatus],
        },
        otherFields
      ),
      capabilitiesPath
    );
  }

  updateCapabilitySet(
    userId: string,
    capabilitySet: CapabilitySet
  ): Promise<CapabilitySet> {
    let capabilitiesPath = `users/${userId}/capabilities`;

    return this.update<CapabilitySet>(
      <CapabilitySet>{ ...capabilitySet },
      capabilitiesPath
    );
  }

  updateNoteCount(
    capabilityId: string,
    currentUser: string,
    noteCounts: NoteCount[]
  ): Promise<CapabilitySet> {
    let capabilitiesPath = `users/${currentUser}/capabilities`;

    return this.update<CapabilitySet>(
      <CapabilitySet>{
        id: capabilityId,
        notes: noteCounts,
      },
      capabilitiesPath
    );
  }

  getCurrentStatus(status: CapabilityStatus[] = []): CapabilityStatusStep {
    // If we have no status then we are in started state
    if (!status.length) return CapabilityStatusStep.Started;

    // Order by date descending
    let orderedStatus = status
      .slice()
      .sort(
        (a: any, b: any) =>
          Date.parse(this._normaliseDate(b.timestamp).toString()) -
          Date.parse(this._normaliseDate(a.timestamp).toString())
      );

    // Take the first status from the array
    let current = orderedStatus[0].status as CapabilityStatusStep;

    // If in discuss, treat this as still in review
    //current = current === CapabilityStatusStep.Discuss ?
    //    CapabilityStatusStep.Reviewed : current;

    return current;
  }

  getCurrentTimestamp(status: CapabilityStatus[] = []): Date {
    // If we have no status then we are in started state
    if (!status.length) return null;

    // Order by date descending
    let orderedStatus = status
      .slice()
      .sort(
        (a: any, b: any) =>
          Date.parse(this._normaliseDate(b.timestamp).toString()) -
          Date.parse(this._normaliseDate(a.timestamp).toString())
      );

    // Take the first status from the array
    let current = this._normaliseDate(orderedStatus[0].timestamp);

    // If in discuss, treat this as still in review
    //current = current === CapabilityStatusStep.Discuss ?
    //    CapabilityStatusStep.Reviewed : current;

    return current;
  }

  getSteps(): CapabilityStatusStep[] {
    return [
      CapabilityStatusStep.Submitted,
      CapabilityStatusStep.Reviewed,
      CapabilityStatusStep.Agreed,
      CapabilityStatusStep.Completed,
    ];
  }

  getNextStep(currentStep): CapabilityStatusStep {
    switch (currentStep) {
      case CapabilityStatusStep.Started:
        return CapabilityStatusStep.Submitted;
      case CapabilityStatusStep.Submitted:
        return CapabilityStatusStep.Reviewed;
      case CapabilityStatusStep.Reviewed:
      case CapabilityStatusStep.Discuss:
        return CapabilityStatusStep.Agreed;
      case CapabilityStatusStep.Agreed:
        return CapabilityStatusStep.Completed;
    }
  }

  cancelSubscriptions() {
    this.fbSubs.forEach((sub) => sub.unsubscribe());
  }

  calculateSalaryScore(capabilitySet: CapabilitySet): number {
    if (!capabilitySet?.selectedBehaviourLabels) return 0;

    let score = 0;
    let total = capabilitySet.selectedBehaviourLabels.length * 5;

    capabilitySet.selectedBehaviourLabels.forEach((label) => {
      switch (label) {
        case 'A':
          score += 1;
          break;
        case 'B':
          score += 2;
          break;
        case 'C':
          score += 3;
          break;
        case 'D':
          score += 4;
          break;
        case 'E':
          score += 5;
          break;
      }
    });

    let percent = (score / total) * 100;
    return percent / 20;
  }

  private _normaliseDate(date): Date {
    if (typeof date === 'string') {
      return new Date(date);
    } else {
      return date.toDate();
    }
  }
}

import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

export class UIService {
    private _navigationState: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('navigationState') || 'collapse');

    set navigationState(value: string) {
        localStorage.setItem('navigationState', value);
        this._navigationState.next(value);
    }

    get navigationState$(): Observable<string> {
        return this._navigationState.asObservable();
    }
}
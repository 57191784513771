<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full">
  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <span class="w-full text-center p-4"
    >Powered by <strong>bod</strong>
    <br />
    <span class="text-xs text-gray-400"
      >BOD Technology Ltd |
      <a href="/legal">License Agreement & Privacy Statement</a></span
    >
  </span>
</div>

import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//import { format } from 'path';
import { AuthService } from '@core/auth/auth.service';
import { UIService } from '@core/services';
import { Store } from '@ngrx/store';
import * as fromRoot from '@core/store/app.reducer';

import { AlertDialog } from '@shared/components/alert-dialog/alert-dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
})
export class ForgottenPasswordComponent implements OnInit {
  forgottenPasswordForm: FormGroup;
  isLoading$: Observable<boolean>;
  ssoEnabled: boolean;

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private store: Store<{ ui: fromRoot.State }>
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store.select(fromRoot.getIsLoading);
    this.ssoEnabled = this.authService.ssoEnabled();

    this.forgottenPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    this.forgottenPasswordForm.disable();

    this.authService
      .resetPassword(this.forgottenPasswordForm.value.email)
      .then(() => {
        this._router.navigate(['./login']);
      })
      .catch((error) => {
        const dialogRef = this._dialog.open(AlertDialog, {
          width: '350px',
          data: {
            title: `Ooops`,
            message: error.message,
          },
        });

        this.forgottenPasswordForm.enable();
      });
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { BaseService } from './base.service';
import { Product } from '@core/models/product.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class ProductService extends BaseService {
  private _products: BehaviorSubject<Product[]> = new BehaviorSubject<
    Product[]
  >([]);

  constructor(private db: AngularFirestore) {
    super('products', db);
  }

  // ***************
  // Getters/Setters
  // ***************

  get products(): Product[] {
    return this._products.getValue();
  }

  set products(value: Product[]) {
    this._products.next(value);
  }

  //***************
  // Functions:
  //***************

  getProducts(): Observable<Product[]> {
    return this.list<Product>(`/products`).pipe(
      tap((products) => this._products.next(products))
    );
  }

  hasAccess(productSlug: string) {
    return !!this._products.getValue().find((p) => p.slug === productSlug);
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AuthService } from '@core/auth/auth.service';
import { Store } from '@ngrx/store';
import { catchError, tap } from 'rxjs/operators';
import * as fromRoot from '@core/store/app.reducer';
import * as UI from '@core/store/ui.actions';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'settings-security',
  templateUrl: './security.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsSecurityComponent implements OnInit {
  @Output() saved = new EventEmitter<any>();
  securityForm: FormGroup;
  isLoading$: Observable<boolean>;

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _store: Store<{ ui: fromRoot.State }>
  ) {}

  ngOnInit(): void {
    // Create the form
    this.securityForm = this._formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });

    this.securityForm.setValidators(this.passwordMatchValidator);
  }

  passwordMatchValidator(control: AbstractControl): ValidatorFn {
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('confirmPassword').value;

    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ NoPasswordMatch: true });
    } else {
      return null;
    }
  }

  save() {
    if (this.securityForm.valid) {
      this.securityForm.disable();
      this._authService
        .updatePassword(this.securityForm.get('confirmPassword').value)
        .pipe(
          catchError((err) => {
            this.securityForm.enable();
            return of(err);
          })
        )
        .subscribe((result) => {
          this.securityForm.enable();
          this.saved.emit(result);
        });
    }
  }
}

import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  QueryFn,
} from '@angular/fire/firestore';
import firebase from 'firebase';
import { IBaseModel, IDocModel } from '@core/models/base.model';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IBaseService } from './ibase.service';

export abstract class BaseService {
  protected collection: AngularFirestoreCollection;
  private path: string;

  constructor(path: string, protected afs: AngularFirestore) {
    this.path = path;
    if (path.length) {
      this.collection = this.afs.collection(path);
    }
  }

  getCollection(collection?: string, query?: QueryFn) {
    if (collection || query) {
      return this.afs.collection(collection || this.path, query);
    }

    return this.collection;
  }

  get<T extends IBaseModel>(
    identifier: string,
    collection?: string
  ): Observable<T> {
    return this.getCollection(collection)
      .doc<T>(identifier)
      .snapshotChanges()
      .pipe(
        map((doc) => {
          if (doc.payload.exists) {
            const data = doc.payload.data() as T;
            const id = doc.payload.id;
            return { id, ...data };
          }
        })
      );
  }

  getFirstOrDefault<T extends IBaseModel>({
    query,
    collection,
  }: {
    query: QueryFn<firebase.firestore.DocumentData>;
    collection: string;
  }): Observable<T> {
    return this.getCollection(collection, query)
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as T;
            data.id = a.payload.doc.id;
            return data;
          });
        }),
        map((result) => {
          if (result.length) return result.shift();
          return null;
        })
      );
  }

  list<T extends IBaseModel>(
    collection?: string,
    query?: QueryFn
  ): Observable<T[]> {
    return this.getCollection(collection, query)
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as T;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }

  listGroup<T extends IBaseModel>(
    collection?: string,
    query?: QueryFn
  ): Observable<T[]> {
    return this.afs
      .collectionGroup(collection, query)
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as T;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }

  getManyById(ids, path) {
    return new Promise((res) => {
      // don't run if there aren't any ids or a path for the collection
      if (!ids || !ids.length || !path) return res([]);

      console.log(path);

      const collectionPath = this.afs.collection(path);
      let batches = [];

      while (ids.length) {
        // firestore limits batches to 10
        const batch = ids.splice(0, 10);

        // add the batch request to to a queue
        batches.push(
          new Promise((response) => {
            collectionPath.ref
              .where(firebase.firestore.FieldPath.documentId(), 'in', [
                ...batch,
              ])
              .get()
              .then((results) =>
                response(results.docs.map((result) => result.data()))
              );
          })
        );
      }

      // after all of the data is fetched, return it
      Promise.all(batches).then((content) => res(content.flat()));
    });
  }

  addOrUpdate<T extends IBaseModel>(
    item: T,
    collection?: string,
    addWithExplicitId: boolean = false
  ): Promise<T> {
    const promise = new Promise<T>((resolve, reject) => {
      if (!addWithExplicitId && item.id) {
        this.getCollection(collection)
          .doc(item.id)
          .set(item)
          .then((ref) => {
            const newItem = {
              ...item,
            };
            resolve(newItem);
          });
      } else {
        let newDoc = this.getCollection(collection).doc(item.id);
        let id = item.id ? item.id : newDoc.ref.id;
        newDoc.set({ id, ...item }).then((ref) => {
          const newItem = {
            id,
            ...item,
          };
          resolve(newItem);
        });
      }
    });
    return promise;
  }

  update<T extends IBaseModel>(item: T, collection?: string): Promise<T> {
    const promise = new Promise<T>((resolve, reject) => {
      this.getCollection(collection)
        .doc<T>(item.id)
        .update(item)
        .then(() => {
          resolve({
            ...item,
          });
        });
    });
    return promise;
  }

  delete<T extends IBaseModel>(id: string, collection?: string): Promise<void> {
    const docRef = this.getCollection(collection).doc<T>(id);
    return docRef.delete();
  }

  batchUpdate<T extends IBaseModel>(entities: IDocModel[]): Promise<void> {
    let batch = this.afs.firestore.batch();

    entities.forEach((entity) => {
      let docRef: AngularFirestoreDocument = this.afs
        .collection(entity.collection)
        .doc<T>(entity.id);
      batch.update(docRef.ref, entity.model);
    });

    return batch.commit();
  }
}

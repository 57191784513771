import { Component, OnInit, Input } from '@angular/core';
import { BubbleChartDataArray } from '@core/models';
import * as Highcharts from 'highcharts';

declare var require: any;
const More = require('highcharts/highcharts-more');
More(Highcharts);

/*
const Exporting = require('highcharts/modules/exporting');
Exporting(Highcharts);

const ExportData = require('highcharts/modules/export-data');
ExportData(Highcharts);
*/

@Component({
  selector: 'hc-donut-chart',
  templateUrl: './hc-donut-chart.component.html',
  styleUrls: ['./hc-donut-chart.component.css'],
})
export class HcDonutChartComponent implements OnInit {
  @Input() bubbleChartData: BubbleChartDataArray[] = [];

  donutColors: any[] = ['#a9d08e', '#f4b084', '#8ea9db', '#FFE48D'];
  //donutColors: any[] = ['#FCE4D6','#D9E1F2','#E1CCFF','#FFF2CC'];

  highcharts = Highcharts;
  chartOptions = {
    chart: {
      type: 'donut',
      plotBorderWidth: 0,
      plotBackgroundColor: null,
      plotShadow: false,
      style: {
        fontFamily: 'Inter var',
      },
    },

    title: {
      text: 'Maturity Mix',
      align: 'center',
      verticalAlign: 'middle',
      y: 0,
    },

    legend: {
      symbolWidth: 16,
      symbolRadius: 0,
      squareSymbol: true,
    },

    tooltip: {
      useHTML: true,
      headerFormat: '<table>',
      pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>',
      footerFormat: '</table>',
      followPointer: true,
    },

    credits: {
      enabled: false,
    },

    plotOptions: {
      pie: {
        point: {
          events: {
            legendItemClick: function () {
              return false;
            },
          },
        },
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (this.y > 0) {
              return (
                this.point.name +
                ': <br/>' +
                Highcharts.numberFormat(this.point.percentage, 1) +
                ' %'
              );
            }
          },
          //format: '{point.name}: <b>{point.percentage:.1f}%</b>',
          distance: -40,
          style: {
            color: 'black',
          },
        },
        startAngle: 0,
        //endAngle: -180,
        center: ['50%', '50%'],
        size: '90%',
        showInLegend: true,
      },
    },

    exporting: {
      enabled: false,
    },

    series: [],
  };

  constructor() {}

  ngOnInit(): void {
    var growCount = 0;
    var challengeCount = 0;
    var championCount = 0;
    var masterCount = 0;
    var totalCount = 0;
    var x: number;
    var y: number;

    //console.log("bubbleChartData = ", this.bubbleChartData);

    this.bubbleChartData.forEach((userBubble) => {
      x = userBubble.data[0].x;
      y = userBubble.data[0].y;
      totalCount++;

      if (x < 0 && y < 0) {
        growCount++;
      } else {
        if (x < 0 && y >= 0) {
          challengeCount++;
        } else {
          if (x >= 0 && y < 0) {
            championCount++;
          } else {
            masterCount++;
          }
        }
      }
    });

    this.chartOptions.series[0] = {
      type: 'pie',
      innerSize: '50%',
      data: [
        {
          name: 'Growing',
          color: this.donutColors[0],
          y: (growCount / totalCount) * 100,
        },
        {
          name: 'Challenging',
          color: this.donutColors[1],
          y: (challengeCount / totalCount) * 100,
        },
        {
          name: 'Championing',
          color: this.donutColors[2],
          y: (championCount / totalCount) * 100,
        },
        {
          name: 'Mastering',
          color: this.donutColors[3],
          y: (masterCount / totalCount) * 100,
        },
      ],
    };
  }
}

import {
  RoleActions,
  SET_USER_ROLE,
  SET_REVIEWER_ROLE,
  SET_MANAGER_ROLE,
  SET_ADMIN_ROLE,
} from './role.actions';
import * as roles from '@core/models/role.model';

export interface State {
  role: string;
}

const initialState: State = {
  role: 'member',
};

export function roleReducer(state = initialState, action: RoleActions) {
  switch (action.type) {
    case SET_USER_ROLE:
      return {
        role: roles.userRole,
      };
    case SET_REVIEWER_ROLE:
      return {
        role: roles.reviewerRole,
      };
    case SET_MANAGER_ROLE:
      return {
        role: roles.managerRole,
      };
    case SET_ADMIN_ROLE:
      return {
        role: roles.adminRole,
      };
    default: {
      return state;
    }
  }
}

export const getRole = (state: State) => state.role;

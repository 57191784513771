import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MemberService,
  OrgService,
  StorageService,
  TeamService,
  UserService,
} from '@core/services';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as fromRoot from '@core/store/app.reducer';
import * as UI from '@core/store/ui.actions';
import { UserData } from '@core/models';
import { Member } from '@core/models/member.model';
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'settings-account',
  templateUrl: './account.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsAccountComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @Output() saved = new EventEmitter<any>();
  @Output() error = new EventEmitter<any>();
  isLoading$: Observable<boolean>;
  user: UserData;
  member: Member;
  accountForm: FormGroup;
  nameForm: FormGroup;
  profileImageUrl: string;
  profileImageChanged: boolean = false;
  imageLoaded: boolean = false;
  uploading: boolean = false;
  selectedFile: File;
  fileSizeError: boolean = false;
  fileTypeError: boolean = false;
  DEFAULT_PROFILE_IMAGE: string = 'assets/defaultProfile.png';

  /**
   * Constructor
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _userService: UserService,
    private _memberService: MemberService,
    private _storageService: StorageService,
    private _store: Store<{ ui: fromRoot.State }>,
    private _changeRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    //this.store.dispatch(new UI.StartLoading());
    this.isLoading$ = this._store.select(fromRoot.getIsLoading);

    this._userService.currentUser$
      .pipe(
        map((user) => {
          this.user = user;

          // Create the form
          this.accountForm = this._formBuilder.group({
            firstName: [user.firstName, Validators.required],
            lastName: [user.lastName, Validators.required],
            //organisation: [user.organisations[0].orgId],
            photo: [user.photoUrl ? user.photoUrl : this.DEFAULT_PROFILE_IMAGE],
            track: [user.track],
            position: [this._memberService.defaultMembership?.position?.name],
          });

          this.profileImageUrl = user.photoUrl;

          //this.store.dispatch(new UI.StopLoading());
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe();
  }

  save() {
    if (this.accountForm.valid) {
      this.accountForm.disable();
      let firstName = this.accountForm.get('firstName').value;
      let lastName = this.accountForm.get('lastName').value;

      let promises: any = [
        this._userService.updateUser({
          ...this.user,
          firstName,
          lastName,
        }),
      ];

      if (this.profileImageChanged) {
        const mediaFolderPath = `profileImages/${this.user.id}`;
        const { downloadUrl$ } = this._storageService.uploadFileAndGetMetadata(
          mediaFolderPath,
          this.selectedFile
        );

        promises.push(downloadUrl$.toPromise());
      }

      Promise.all(promises)
        .then((values: any) => {
          this.accountForm.enable();
          this.saved.emit('Saved!');

          if (values.length > 1 && values[1] !== undefined) {
            this._userService
              .updateUser({
                ...this.user,
                photoUrl: values[1],
              })
              .then(() => {
                this.uploading = false;
                this._changeRef.detectChanges();
              });
          }
        })
        .catch((error) => {
          this.saved.emit(error.message);
          this.accountForm.enable();
        });
    }
  }

  handleFileInput(event: any) {
    let reader = new FileReader();
    this.selectedFile = event.target.files[0];

    // Selected file validations
    this.fileSizeError = false;
    this.fileTypeError = false;

    if (this.selectedFile.size > 500000) {
      this.fileSizeError = true;
      this.error.emit('File is too big');
      return;
    } else if (
      this.selectedFile.type !== 'image/jpeg' &&
      this.selectedFile.type !== 'image/png'
    ) {
      this.error.emit('File is must be a png or jpg');
      this.fileTypeError = true;
      return;
    }

    reader.onload = (ev: any) => {
      this.profileImageUrl = ev.target.result;
      this.profileImageChanged = true;

      this._changeRef.markForCheck();
    };

    reader.onerror = (ev: any) => {
      console.log('File could not be read: ' + ev.target.error.code);
    };

    reader.readAsDataURL(this.selectedFile);
  }
}

<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0">
  <div class="w-full sm:w-auto py-8 px-4">
    <div class="w-full max-w-100 flex flex-col items-center justify-center">
      <mat-progress-spinner
        [diameter]="50"
        [mode]="'indeterminate'"
      ></mat-progress-spinner>
      <span class="mt-2">Logging out...</span>
    </div>
  </div>
</div>

<app-loading *ngIf="isLoading$ | async"></app-loading>
<div class="flex flex-col flex-auto" *ngIf="!(isLoading$ | async)">
  <!-- Header -->
  <div
    class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-8 pb-0 dark:bg-transparent"
  >
    <div class="flex flex-1 min-w-0 justify-center">
      <div class="flex flex-1 min-w-0 w-full max-w-screen-lg">
        <!-- Title -->
        <div class="mt-2">
          <h2
            class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate"
          >
            Reviews
          </h2>
        </div>
      </div>
      <!-- Actions -->
      <div class="mt-4 sm:mt-0"></div>
    </div>
  </div>

  <!-- Main -->
  <div class="flex flex-auto justify-center px-6 py-6 md:px-8">
    <div class="w-full max-w-screen-lg">
      <div class="grid grid-cols-1 gap-6 w-full min-w-0">
        <p *ngIf="!memberGroups?.length">
          You are not currently reviewing any teams.
        </p>
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg max-w-screen-lg w-full"
          *ngIf="memberGroups?.length"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                >
                  Team/Track
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ productTerm }} # / Status
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                >
                  Last activity
                </th>
                <th
                  *ngIf="checkForProduct('salary-banding')"
                  scope="col"
                  class="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                >
                  Salary band
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr *ngFor="let group of memberGroups">
                <td class="px-6 py-3 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                      <img
                        class="h-10 w-10 rounded-full"
                        *ngIf="group.members[0].photoUrl"
                        src="{{ getProfilePic(group.members[0].photoUrl) }}"
                        alt=""
                      />
                      <mat-icon
                        class="h-10 w-10 rounded-full opacity-30"
                        *ngIf="!group.members[0].photoUrl"
                        [svgIcon]="'heroicons_outline:user-circle'"
                      ></mat-icon>
                    </div>
                    <div class="ml-4">
                      <div class="text-md font-medium text-gray-900">
                        {{ group.name }}
                      </div>
                      <div class="text-md text-gray-500">
                        {{ group.members[0].email }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-3 whitespace-nowrap">
                  <div class="text-md text-gray-900">
                    {{ joinTeams(group.members) }}
                  </div>
                  <div class="text-md text-gray-500">
                    {{ group.members[0].track }}
                  </div>
                </td>
                <td class="px-6 py-3 whitespace-nowrap">
                  <span
                    *ngIf="group.members[0]?.status?.capabilityCount"
                    class="px-3 py-1 mr-2 inline-flex text-md leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                  >
                    {{ group.members[0]?.status?.capabilityCount.toString() }}
                  </span>
                  <span
                    *ngIf="group.members[0]?.status?.capabilityStatus"
                    class="px-3 py-1 inline-flex text-md leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                    [ngClass]="
                      getStatusColor(group.members[0]?.status?.capabilityStatus)
                    "
                  >
                    {{
                      getStatusLabel(
                        group.members[0]?.status?.capabilityStatus,
                        group.members[0]
                      )
                    }}
                  </span>
                </td>
                <td class="px-6 py-3 whitespace-nowrap text-md text-gray-500">
                  <span *ngIf="group.members[0]?.status?.lastActivity">
                    {{
                      group.members[0]?.status?.lastActivity.toDate()
                        | amTimeAgo
                    }}
                  </span>
                </td>
                <td
                  *ngIf="checkForProduct('salary-banding')"
                  class="text-center w-40"
                  align="center"
                >
                  <salary-score
                    size="medium"
                    [value]="group.members[0]?.salaryScore"
                  ></salary-score>
                </td>
                <td
                  class="px-6 py-1 whitespace-nowrap text-right text-md font-medium"
                >
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Actions"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      *ngIf="
                        group.members[0].status?.capabilityStatus ===
                          'Reviewed' ||
                        group.members[0].status?.capabilityStatus ===
                          'Discuss' ||
                        !group.members[0]?.status?.capabilityStatus
                      "
                    >
                      <span>Send email reminder</span>
                    </button>
                    <button
                      *ngIf="
                        group.members[0]?.status?.capabilityStatus !==
                          'Submitted' &&
                        group.members[0]?.status?.capabilityStatus !==
                          'Agreed' &&
                        group.members[0]?.status?.capabilityStatus !== 'Discuss'
                      "
                      mat-menu-item
                      (click)="onView(group.members[0])"
                    >
                      <span>Go to {{ productTerm }}</span>
                    </button>
                    <button
                      *ngIf="
                        group.members[0]?.status?.capabilityStatus ===
                          'Submitted' && !group.members[0]?.review
                      "
                      mat-menu-item
                      (click)="onReview(group.members[0])"
                    >
                      <span>Start review</span>
                    </button>
                    <button
                      *ngIf="
                        (group.members[0]?.status?.capabilityStatus ===
                          'Submitted' ||
                          group.members[0]?.status?.capabilityStatus ===
                            'Agreed' ||
                          group.members[0]?.status?.capabilityStatus ===
                            'Discuss') &&
                        group.members[0]?.review?.reviewByUid === manager.uid
                      "
                      mat-menu-item
                      (click)="onReview(group.members[0])"
                    >
                      <span>Continue review</span>
                    </button>
                    <button
                      *ngIf="group.members[0]?.status"
                      mat-menu-item
                      (click)="onViewDashboard(group.members[0])"
                    >
                      <span>Go to dashboard</span>
                    </button>
                  </mat-menu>
                </td>
              </tr>

              <!-- More people... -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  combineLatest,
  defer,
  forkJoin,
  from,
  Observable,
  of,
  Subscription,
  throwError,
} from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthData } from './auth-data.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  UserService,
  TeamService,
  MemberService,
  ProductService,
} from '@core/services';
import { Store } from '@ngrx/store';
import * as fromRoot from '@core/store/app.reducer';
import * as UI from '@core/store/ui.actions';
import * as Admin from '@core/store/admin.actions';
import * as Role from '@core/store/role.actions';
import { EmailVerificationDialog } from './email-verification-dialog';
import { ConfigData, UserData, UserOrg } from '@core/models';
import configSettings from '../../resources/config.json';
import firebase from 'firebase/app';
import 'firebase/auth';
import { OrgService } from '@core/services/org.service';
import { BaseService } from '@core/services/base.service';
import { Invite } from '@core/models/invite.model';
import Bugsnag from '@bugsnag/js';
import { environment } from '@env';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService extends BaseService {
  private _authenticated: boolean = false;
  private currentUserId: string = null;

  teamSubscription: Subscription;

  // Get organisation name from app config file
  configData: ConfigData = configSettings;

  //MS Azure Active Directory Auth
  //private provider = new firebase.auth.OAuthProvider('microsoft.com');
  private provider =
    this.configData.ssoProvider != null
      ? new firebase.auth.OAuthProvider(this.configData.ssoProvider)
      : null;

  constructor(
    private db: AngularFirestore,
    public dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
    private afAuth: AngularFireAuth,
    private productService: ProductService,
    private memberService: MemberService,
    private orgService: OrgService,
    private teamService: TeamService,
    private userService: UserService,
    private store: Store<{ ui: fromRoot.State }>
  ) {
    super('users', db);

    if (this.configData.ssoProvider != null && this.configData.ssoID != null) {
      this.provider.setCustomParameters({
        // Optional "tenant" parameter in case you are using an Azure AD tenant.
        // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
        // or "common" for tenant-independent tokens.
        // The default value is "common".
        //tenant: 'c8335001-5bba-4f91-813c-e9a9ea2b4615'
        tenant: this.configData.ssoID,
      });
    }
  }

  get authenticated(): boolean {
    return this._authenticated;
  }

  signInFirebase(): Observable<boolean> {
    return this.afAuth.authState.pipe(
      switchMap((fireUser) => {
        if (!fireUser) {
          return of(false);
        }

        return combineLatest([
          this.userService.getUserData(fireUser.uid),
          this.productService.getProducts(),
          this.teamService.getTeamMembershipsByUserId(fireUser.uid),
        ]).pipe(
          //tap(console.log),
          switchMap(([user, products, teamMemberships]) =>
            this.memberService
              .getDefaultMembership(user.id, user.currentOrgId)
              .pipe(
                switchMap((member) => {
                  // If the default team is an organisation team, look it up
                  if (member.type === 'org') {
                    return this.orgService.getOrganisation(member.orgId).pipe(
                      map((org) => ({
                        member,
                        products,
                        org,
                        teamMemberships,
                      }))
                    );

                    // Default team is a personal one
                  } else {
                    return of({ member, products, org: null, teamMemberships });
                  }
                }),
                map(({ member, products, org }) => ({
                  user,
                  member,
                  products,
                  org,
                  teamMemberships,
                }))
              )
          ),
          switchMap(({ user, member, products, org, teamMemberships }) => {
            // Set org
            if (org) {
              this.orgService.organisation = org;

              // Store product this user has access to
              this.productService.products = products.filter((p) =>
                member.products?.find((productId) => p.id === productId)
              );

              // Lookup any teams the user is a reviewer of
              let isTeamReviewer =
                teamMemberships.filter((t) => t.role === 'reviewer').length > 0;

              // Store users roleId
              this.setRole(member.role, isTeamReviewer);

              // Set product terms
              this.store.dispatch(
                new UI.SetProductInfo({
                  name: org.productName,
                  term: org.productTerm,
                  termPlural: '',
                })
              );

              // Log org with Bugsnag
              Bugsnag.addMetadata('organisation', {
                id: org.id,
                name: org.name,
              });

              let tawk = window['Tawk_API'];
              tawk && tawk.addTags([org.name]);
            }

            // Store user
            this.userService.currentUser = user;
            this.currentUserId = user.id;

            // Could be a team or organisation
            this.memberService.defaultMembership = member;

            // Flag user as authenticated
            this._authenticated = true;

            console.log(this._authenticated);

            // Log user with Bugsnag
            Bugsnag.addMetadata('user', {
              uid: user.id,
              organisations: org.name,
              currentOrgId: user.currentOrgId,
              track: user.track,
              registered: user.registered,
            });

            return of(true);
          })
        );
      }),
      catchError(() => {
        // Return false
        return of(false);
      })
    );
  }

  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    return this.signInFirebase();
  }

  registerUserByInvite(
    inviteId: string,
    firstName: string,
    lastName: string,
    password: string
  ): Observable<UserData> {
    return this.http
      .post<{ user: UserData }>(
        `${environment.api}/users/invite/${inviteId}/create`,
        {
          firstName,
          lastName,
          password,
          role: 'member',
        }
      )
      .pipe(map((response) => response.user));
  }

  login(authData: AuthData) {
    let emailVerified: boolean = false;

    return from(
      this.afAuth.signInWithEmailAndPassword(authData.email, authData.password)
    );

    /*
      .then(() => {
        this.afAuth.currentUser.then((user) => {
          //console.log("login: user = ", user);
          //emailVerified = user.emailVerified;
          emailVerified = true;
          if (emailVerified) {
            this.router.navigate(['./dashboard']);
          } else {
            const dialogRef = this.dialog.open(EmailVerificationDialog);
            this.router.navigate(['./login']);
          }
          this.store.dispatch(new UI.StopLoading());
        });
      });
      */
  }

  loginWithMS() {
    this.store.dispatch(new UI.StartLoading());

    //firebase.auth().signInWithRedirect(this.provider);

    firebase
      .auth()
      .signInWithPopup(this.provider)
      .then((result) => {
        //console.log("MS Login as ", result);
        this.currentUserId = result.user.uid;
        //this.checkIsAdmin(this.currentUserId);
        //this.checkRoleId(this.currentUserId);
        this.store.dispatch(new UI.StopLoading());
        this.router.navigate(['./welcome']);
      })
      .catch((error) => {
        this.store.dispatch(new UI.StopLoading());
      });
  }

  ssoEnabled() {
    if (this.provider != null) {
      return true;
    } else {
      return false;
    }
  }

  setRole(role: string, isTeamReviewer: boolean) {
    switch (role) {
      case 'admin':
        this.store.dispatch(new Role.SetAdminRole());
        break;
      case 'manager':
        this.store.dispatch(new Role.SetManagerRole());
        break;
      default:
        if (isTeamReviewer) {
          this.store.dispatch(new Role.SetReviewerRole());
        } else {
          this.store.dispatch(new Role.SetUserRole());
        }
    }
  }

  logout() {
    return from(this.afAuth.signOut());
  }

  getCurrentUserId() {
    //console.log("Auth service current user is", this.currentUserId);
    return this.currentUserId;
  }

  getAuth() {
    return this.afAuth;
  }

  // Please note: the url below is overridden by the Firebase default on the console
  resetPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email, {
      url: `https://${environment.firebase.authDomain}/password-reset`,
      //url: `http://localhost:4200/password-reset`,
      handleCodeInApp: true,
    });
  }

  updatePassword(password: string): Observable<boolean | string> {
    let fbUser$ = from(this.afAuth.currentUser);
    return fbUser$.pipe(
      switchMap((user) => user.updatePassword(password)),
      switchMap(() => of(true)),
      catchError((error) => of(error))
    );
  }

  updateEmail(email: string): Promise<void> {
    return this.afAuth.currentUser.then((user) => user.updateEmail(email));
  }
}

<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0">
  <div
    class="
      w-full
      sm:w-auto
      py-8
      px-4
      sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card
    "
  >
    <div
      class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0"
      *ngIf="!signInLinkEmailSent; else signInLinkSent"
    >
      <!-- Title -->
      <div class="text-4xl font-extrabold tracking-tight leading-tight">
        Sign in
      </div>

      <!-- Sign in form -->
      <form class="mt-6" [formGroup]="loginForm" #signInNgForm="ngForm">
        <p class="mb-6" *ngIf="signInLink">
          Enter your email below and we'll send you a one-time login link:
        </p>

        <!-- Email field -->
        <mat-form-field class="w-full">
          <mat-label>Email address</mat-label>
          <input
            data-cy="email"
            id="email"
            matInput
            [formControlName]="'email'"
          />
          <mat-error *ngIf="loginForm.get('email').hasError('required')">
            Email address is required
          </mat-error>
          <mat-error *ngIf="loginForm.get('email').hasError('email')">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <!-- Password field -->
        <mat-form-field class="w-full" *ngIf="!signInLink">
          <mat-label>Password</mat-label>
          <input
            data-cy="password"
            id="password"
            matInput
            type="password"
            [formControlName]="'password'"
            #passwordField
          />
          <button
            mat-icon-button
            type="button"
            (click)="
              passwordField.type === 'password'
                ? (passwordField.type = 'text')
                : (passwordField.type = 'password')
            "
            matSuffix
          >
            <mat-icon
              class="icon-size-5"
              *ngIf="passwordField.type === 'password'"
              [svgIcon]="'heroicons_solid:eye'"
            ></mat-icon>
            <mat-icon
              class="icon-size-5"
              *ngIf="passwordField.type === 'text'"
              [svgIcon]="'heroicons_solid:eye-off'"
            ></mat-icon>
          </button>
          <mat-error> Password is required </mat-error>
        </mat-form-field>

        <!-- Actions -->
        <div
          class="inline-flex items-end justify-center w-full mt-1"
          *ngIf="!signInLink"
        >
          <a
            class="text-md font-medium text-primary-500 hover:underline"
            href="/forgotten-password"
            >Reset password?
          </a>
        </div>

        <!-- Submit button -->
        <button
          data-cy="submit"
          class="app-mat-button-large w-full mt-6"
          mat-flat-button
          [color]="'primary'"
          [disabled]="loginForm.disabled"
          (click)="onSubmit()"
        >
          <span *ngIf="!loginForm.disabled && !signInLink"> Sign in </span>
          <span *ngIf="!loginForm.disabled && signInLink">
            Get sign in link</span
          >
          <mat-progress-spinner
            *ngIf="loginForm.disabled"
            [diameter]="24"
            [mode]="'indeterminate'"
          ></mat-progress-spinner>
        </button>
      </form>

      <button
        class="app-mat-button-small w-full mt-6"
        mat-stroked-button
        [color]="'primary'"
        (click)="onSignInLink()"
      >
        <span *ngIf="!signInLink">Get a sign-in link</span>
        <span *ngIf="signInLink">Back to login</span>
      </button>
    </div>

    <ng-template #signInLinkSent>
      <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
        <!-- Title -->
        <div class="text-4xl font-extrabold tracking-tight leading-tight">
          Check your inbox
        </div>
        <p class="mt-6">We've sent you an email with your login link.</p>
      </div>
    </ng-template>
  </div>
</div>

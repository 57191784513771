import { logging } from 'protractor';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromRoot from '@core/store/app.reducer';
import * as UI from '@core/store/ui.actions';

import { BaseService } from './base.service';
import { UserAccess, UserData, UserOrg } from '@core/models/user.model';
import { MemberStatus } from '@core/models';
import { switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class UserService extends BaseService {
  userDataLoaded = new Subject<UserData>();
  selectedUserId = new Subject<string>();
  private fbSubs: Subscription[] = [];
  private userData: UserData;
  private selectedUser: string;
  public readonly user: Observable<UserData> =
    this.userDataLoaded.asObservable();
  private _currentUser: ReplaySubject<UserData> = new ReplaySubject<UserData>(
    1
  );

  constructor(
    private db: AngularFirestore,
    private store: Store<fromRoot.State>,
    public datepipe: DatePipe
  ) {
    super('users', db);
  }

  // ***************
  // Getters/Setters
  // ***************

  set currentUser(value: UserData) {
    // Store the value
    this._currentUser.next(value);
  }

  get currentUser$(): Observable<UserData> {
    return this._currentUser.asObservable();
  }

  //***************
  // Functions:
  //***************

  createUser(user: UserData, orgId?: string) {
    return from(
      this.addOrUpdate<UserData>(<UserData>{
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        registered: new Date(),
        track: user.track,
        //organisations: orgId ? [<UserOrg>{ orgId }]:[]
      })
    );
  }

  getUserData(uid: string, store: boolean = true): Observable<UserData> {
    this.store.dispatch(new UI.StartLoading());

    let user$ = this.get<UserData>(uid);

    if (store) {
      user$.subscribe((userData: UserData) => {
        //console.log("userData =", userData);
        this.userData = userData;
        this.userDataLoaded.next({ ...this.userData });
        this.store.dispatch(new UI.StopLoading());
      });
    }

    return user$;
  }

  getUserByEmail(email: string): Observable<UserData[]> {
    return this.list<UserData>('users', (ref) =>
      ref.where('email', '==', email)
    );
  }

  setSelectedUser(userId: string) {
    this.selectedUser = userId;
    this.selectedUserId.next(this.selectedUser);
  }

  getSelectedUser() {
    return this.selectedUser;
  }

  setNextUserTimePeriod(currentUser: string, newTimePeriod: number) {
    this.db.collection('users').doc(currentUser).update({
      nextTimePeriod: newTimePeriod,
    });
  }

  updateUserTimePeriods(currentUser: string, updatedTimePeriods: string[]) {
    this.db.collection('users').doc(currentUser).update({
      timePeriods: updatedTimePeriods,
    });
  }

  updateUser(user: UserData): Promise<void> {
    return this.db
      .collection('users')
      .doc(user.id)
      .update(user)
      .then(() => this._currentUser.next(user));
  }

  updateOrganisations(uid: string, organisations: UserOrg[]): Promise<void> {
    return this.db.collection('users').doc(uid).update({
      organisations,
    });
  }

  updateDefaultMember(uid: string, memberId: string): Promise<void> {
    return this.db.collection('users').doc(uid).update({
      defaultMemberId: memberId,
    });
  }

  updateStatus(
    userId: string,
    status: MemberStatus,
    salaryScore?: number
  ): Observable<UserData> {
    let model: any = {
      status,
    };

    if (salaryScore) {
      model.salaryScore = salaryScore;
    }

    return from(this.db.collection('users').doc(userId).update(model)).pipe(
      switchMap(() => this.currentUser$),
      take(1),
      tap((user) => this._currentUser.next({ ...user, status }))
    );
  }

  cancelSubscriptions() {
    this.fbSubs.forEach((sub) => sub.unsubscribe());
  }
}
function runTransaction(db: any, arg1: (transaction: any) => Promise<void>) {
  throw new Error('Function not implemented.');
}

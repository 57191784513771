<app-loading *ngIf="isLoading$ | async"></app-loading>
<div class="flex flex-col flex-auto min-w-0" *ngIf="!(isLoading$ | async)">
  <!-- Header -->
  <div
    class="relative flex-0 py-8 px-4 sm:p-16 overflow-hidden bg-gray-800 dark"
  >
    <!-- Background - @formatter:off -->

    <!-- @formatter:on -->
    <div class="z-10 relative flex flex-col items-center">
      <div
        class="
          mt-1
          text-4xl
          sm:text-7xl
          font-extrabold
          tracking-tight
          leading-tight
          text-center
        "
      >
        Welcome to {{ productName }}
      </div>
      <div
        class="
          max-w-2xl
          mt-6
          sm:text-2xl
          text-center
          tracking-tight
          text-secondary
        "
      >
        Your Personal and Professional Development Ecosystem
      </div>
    </div>
  </div>

  <!-- Main -->
  <div class="flex justify-center flex-auto px-6 py-3 md:px-8">
    <div class="w-full max-w-screen-sm">
      <div class="grid grid-cols-1 gap-6 w-full min-w-0">
        <div
          class="
            sm:col-span-6
            bg-card
            shadow
            rounded-lg
            overflow-hidden
            p-8
            mt-6
          "
        >
          <p>
            Self-review your skills, knowledge and values in use everyday in
            your role by selecting <strong>{{ productTerm }}</strong> from the
            side menu.
          </p>
          <br />
          <p>
            Check out the visual representation in your own personal radar in
            your Dashboard.
          </p>
          <br />
          <p>Share and discuss your selections with a reviewer.</p>
          <br />
          <p>
            Plan your professional development and the development of your focus
            areas.
          </p>
          <br />
          <h2 class="text-xl font-bold mb-2">How to use this tool</h2>
          <p>
            Select <strong>{{ productTerm }}</strong> from the side menu to
            start a self-review of three key focus areas - skills, knowledge and
            values.
          </p>
          <br />
          <p>
            Under each focus area there are a range of capabilities, each with a
            definition and 5 model behaviour statements.
          </p>
          <br />
          <p>
            Tick a single statement in each capability that best matches the way
            you work in your role.
          </p>
          <br />
          <p>
            Use the add a note feature when selecting a Model Behaviour to help
            a Reviewer understand your choices, achievements or any queries.
            Once you have finished making all your selections click the Submit
            button at the bottom of the page. Your Reviewer will be alerted to
            “start a review” of the selections you have made.
          </p>
          <br />
          <p>
            Your reviewer will be able to suggest and highlight alternative
            selections and comment/respond to any notes you have made.
          </p>
          <br />
          <p>
            Once your reviewer has finished reviewing your selections you will
            be alerted to discuss any changes.
          </p>
          <br />
          <p>
            You are then able to use the Agree button to agree and your reviewer
            can use the Commit button to confirm and save your agreed choices.
            Your visual radar will be ready to view in your dashboard.
          </p>

          <br />
          <div class="flex justify-center border-gray-200 border-t">
            <button
              class="app-mat-button-rounded mt-4"
              mat-flat-button
              [color]="'primary'"
              (click)="onStart()"
            >
              Get started
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

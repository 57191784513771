<div class="w-full">
  <app-loading *ngIf="isLoading$ | async"></app-loading>

  <!-- Form -->
  <form [formGroup]="emailForm" *ngIf="!(isLoading$ | async) && emailForm">
    <div
      class="flex flex-col flex-auto mt-2 bg-card shadow rounded overflow-hidden"
    >
      <div class="p-8">
        <div class="grid sm:grid-cols-4 gap-6 w-full">
          <!-- Email -->
          <div class="sm:col-span-4">
            <mat-form-field class="app-mat-no-subscript w-full">
              <mat-label>Email</mat-label>
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:mail'"
                matPrefix
              ></mat-icon>
              <input [formControlName]="'email'" matInput />
            </mat-form-field>
          </div>
          <!-- Password -->
          <div class="sm:col-span-4">
            <mat-form-field class="app-mat-no-subscript w-full">
              <mat-label>Enter account password </mat-label>
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:key'"
                matPrefix
              ></mat-icon>
              <input [formControlName]="'password'" type="password" matInput />
            </mat-form-field>
            <mat-error *ngIf="emailForm.get('password').hasError('password')">
              Please confirm your password
            </mat-error>
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-center justify-end mt-10">
      <button
        class="px-6 ml-3"
        mat-flat-button
        [color]="'primary'"
        (click)="save()"
        [disabled]="!emailForm.valid"
      >
        <span *ngIf="!emailForm.disabled"> Save </span>
        <mat-progress-spinner
          *ngIf="emailForm.disabled"
          [diameter]="24"
          [mode]="'indeterminate'"
        ></mat-progress-spinner>
      </button>
    </div>
  </form>
</div>

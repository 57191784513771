import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as fromRoot from '@core/store/app.reducer';
import * as roleIds from '@core/models';
import { AuthService } from '@core/auth/auth.service';
import { Store } from '@ngrx/store';
import { OrgService, UIService } from '@core/services';
import { take, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @Input() mode = 'side';
  @Input() opened: boolean = true;
  navigationState: 'open' | 'collapse' = 'open';
  isScreenSmall: boolean;
  currentYear: string;
  isAuth: boolean;
  role$: Observable<string>;
  productName: string;
  productTerm: string;
  orgName: string;

  constructor(
    private _route: ActivatedRoute,
    private _uiService: UIService,
    private _orgService: OrgService,
    private _store: Store<fromRoot.State>
  ) {
    this.currentYear = new Date().getFullYear().toString();
  }

  ngOnInit(): void {
    this.role$ = this._store.select(fromRoot.getRole);

    this._store
      .select(fromRoot.getProductInfo)
      .pipe(
        take(1),
        tap((product) => {
          (this.productName = product.name), (this.productTerm = product.term);
        })
      )
      .subscribe();

    this._orgService.organisation$.pipe(take(1)).subscribe((org) => {
      this.orgName = org.name;
    });

    /*
    this._uiService.navigationState$
      .pipe(
        tap((state: 'open' | 'collapse') => (this.navigationState = state)),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe();
    */
  }

  checkRoleForReviewer(role) {
    if (role === 'reviewer' || role === 'manager' || role === 'admin') {
      return true;
    }

    return false;
  }

  checkRoleForManagement(role) {
    if (role === 'manager' || role === 'admin') {
      return true;
    }

    return false;
  }

  toggleNavigationState(state: string) {
    //this._uiService.navigationState = state;
  }
}

import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppConfig, appConfig } from '@core/config/app.config';
import { OrgService, UIService } from '@core/services';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Layout } from './layout.types';

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  config: AppConfig;
  scheme: 'dark' | 'light';
  navigationState: 'open' | 'collapse' = 'open';
  theme: string;
  layout: Layout = 'empty';

  constructor(
    @Inject(DOCUMENT) private _document: any,
    private _activatedRoute: ActivatedRoute,
    private _orgService: OrgService,
    private _uiService: UIService,
    private _router: Router
  ) {
    this.config = appConfig;

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this._updateLayout();
      });

    this._orgService.organisation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((org) => {
        this.theme = org.theme;
        this._updateTheme();
      });
  }

  ngOnInit(): void {
    /*
    this._uiService.navigationState$.pipe(
      tap((state: 'open' | 'collapse') => this.navigationState = state),
      takeUntil(this._unsubscribeAll)
    ).subscribe();
    */
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private _updateLayout(): void {
    // Get the current activated route
    let route = this._activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    this.layout = this.config.layout;

    const layoutFromQueryParam = route.snapshot.queryParamMap.get(
      'layout'
    ) as Layout;
    if (layoutFromQueryParam) {
      this.layout = layoutFromQueryParam;
      if (this.config) {
        this.config.layout = layoutFromQueryParam;
      }
    }

    const paths = route.pathFromRoot;
    paths.forEach((path) => {
      if (
        path.routeConfig &&
        path.routeConfig.data &&
        path.routeConfig.data.layout
      ) {
        // Set the layout
        this.layout = path.routeConfig.data.layout;
      }
    });
  }

  private _updateScheme(): void {
    // Remove class names for all schemes
    this._document.body.classList.remove('light', 'dark');

    // Add class name for the currently selected scheme
    this._document.body.classList.add(this.scheme);
  }

  private _updateTheme(): void {
    // Find the class name for the previously selected theme and remove it
    this._document.body.classList.forEach((className: string) => {
      if (className.startsWith('theme-')) {
        this._document.body.classList.remove(
          className,
          className.split('-')[1]
        );
      }
    });

    // Add class name for the currently selected theme
    this._document.body.classList.add(`theme-${this.theme}`);
  }
}

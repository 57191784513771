import { Action } from '@ngrx/store';
import { State } from './ui.reducer';

export const START_LOADING = '[UI] Start Loading';
export const STOP_LOADING = '[UI] Stop Loading';
export const SET_PRODUCT_INFO = '[UI] Set Product Info';

export class StartLoading implements Action {
    readonly type = START_LOADING;
}

export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}

export class SetProductInfo implements Action {
    readonly type = SET_PRODUCT_INFO;

    constructor(public payload: any) {}
}

export type UIActions = StartLoading | StopLoading | SetProductInfo;
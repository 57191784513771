import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DefaultLayoutComponent implements OnInit {
  @Input() mode = 'side';
  @Input() opened: boolean = true;
  isScreenSmall: boolean;
  
  constructor() { }

  get currentYear(): number {
    return new Date().getFullYear();
  }

  ngOnInit(): void {
  }

  toggleNavigation(name: string): void {
  }
}

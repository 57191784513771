import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HighchartsChartModule } from 'highcharts-angular';
import { FlexLayoutModule } from '@angular/flex-layout';

import * as fromComponents from './components';
import * as fromMaterial from './material.components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { SalaryScoreComponent } from './components/salary-score/salary-score.component';

@NgModule({
  declarations: [...fromComponents.components, SalaryScoreComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HighchartsChartModule,
    FlexLayoutModule,
    MomentModule,
    ...fromMaterial.modules,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    FlexLayoutModule,
    MomentModule,
    ...fromComponents.components,
    ...fromMaterial.modules,
  ],
  providers: [CurrencyPipe],
})
export class SharedModule {}

import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUi from './ui.reducer';
import * as fromAuth from './auth.reducer';
import * as fromAdmin from './admin.reducer';
import * as fromRole from './role.reducer';

export interface State {
    ui: fromUi.State;
    auth: fromAuth.State;
    admin: fromAdmin.State;
    role: fromRole.State;
}

export const reducers: ActionReducerMap<State> = {
    ui: fromUi.uiReducer,
    auth: fromAuth.authReducer,
    admin: fromAdmin.adminReducer,
    role: fromRole.roleReducer
};

// Get state slices and selectors for dispatch calls
export const getUiState = createFeatureSelector<fromUi.State>('ui');
export const getIsLoading = createSelector(getUiState, fromUi.getIsLoading);
export const getProductInfo = createSelector(getUiState, fromUi.getProductInfo);

export const getAuthState = createFeatureSelector<fromAuth.State>('auth');
export const getIsAuth = createSelector(getAuthState, fromAuth.getIsAuth);

export const getAdminState = createFeatureSelector<fromAdmin.State>('admin');
export const getIsAdmin = createSelector(getAdminState, fromAdmin.getIsAdmin);

export const getRoleState = createFeatureSelector<fromRole.State>('role');
export const getRole = createSelector(getRoleState, fromRole.getRole);
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '@core/store/app.reducer';
import { Router } from '@angular/router';
import { AlertDialog } from '@shared/components/alert-dialog/alert-dialog';

@Component({
  selector: 'app-login-link',
  templateUrl: './login-link.component.html',
})
export class LoginLinkComponent implements OnInit {
  loginForm: FormGroup;
  isLoading$: Observable<boolean>;

  constructor(
    public afAuth: AngularFireAuth,
    private _dialog: MatDialog,
    private _router: Router,
    private formBuilder: FormBuilder,
    private store: Store<{ ui: fromRoot.State }>
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store.select(fromRoot.getIsLoading);

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.confirmSignIn(this._router.url);
  }

  async onSubmit() {
    this.loginForm.disable();

    try {
      window.localStorage.setItem(
        'emailForSignIn',
        this.loginForm.get('email').value
      );
      this.confirmSignIn(this._router.url);
    } catch (err) {
      const dialogRef = this._dialog.open(AlertDialog, {
        width: '350px',
        data: {
          title: `Ooops`,
          message: err.message,
        },
      });
      this.loginForm.enable();
    }
  }

  async confirmSignIn(url) {
    try {
      if (this.afAuth.isSignInWithEmailLink(url)) {
        let email = window.localStorage.getItem('emailForSignIn');

        // If missing email, prompt user for it
        if (!email) {
          return;
        }

        this.loginForm.disable();

        // Signin user and remove the email localStorage
        const result = await this.afAuth.signInWithEmailLink(email, url);
        window.localStorage.removeItem('emailForSignIn');

        // Redirect to dashboard
        this._router.navigateByUrl('/dashboard');
      }
    } catch (err) {
      const dialogRef = this._dialog.open(AlertDialog, {
        width: '350px',
        data: {
          title: `Ooops`,
          message: err.message,
        },
      });

      this.loginForm.enable();
    }
  }
}

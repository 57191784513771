import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

import { DatePipe } from '@angular/common';

// Modules
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

// Components/routes
import { AppComponent } from './app.component';
import { SurveyCompleteDialog } from './routes/checkin/survey-complete-dialog/survey-complete-dialog';
import { ConfirmDeleteDialog } from './routes/checkin/confirm-delete-dialog/confirm-delete-dialog';
import { LoginComponent } from './routes/auth/login/login.component';
import { LoginLinkComponent } from './routes/auth/login/login-link.component';
import { ForgottenPasswordComponent } from './routes/auth/forgotten-password/forgotten-password.component';
import { InviteComponent } from './routes/auth/invite/invite.component';
import { TermsAndConditionsDialog } from './routes/auth/invite/terms-and-conditions-dialog';
import { EmailVerificationDialog } from '@core/auth/email-verification-dialog';
import { PasswordResetComponent } from './routes/auth/password-reset/password-reset.component';

// Routes
import {
  AdminComponent,
  TeamAdminComponent,
  ReportsComponent,
  CheckinComponent,
  WelcomeComponent,
} from '@routes';

// Env
import { environment } from '@env';

// Functions
import { reducers } from '@core/store/app.reducer';
import { AuthService } from '@core/auth/auth.service';
import { StatusStepperComponent } from './routes/checkin/status-stepper/status-stepper.component';
import { ActionBarComponent } from './routes/checkin/action-bar/action-bar.component';
import { NotesComponent } from './routes/checkin/notes/notes.component';
import { LayoutComponent } from './layout/layout.component';
import { EmptyLayoutComponent } from './layout/empty/empty.component';
import { DefaultLayoutComponent } from './layout/default/default.component';
import { UserMenuComponent } from './layout/common/user-menu/user-menu.component';
import { NavigationComponent } from './layout/common/navigation/navigation.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { LogoutComponent } from './routes/auth/logout/logout.component';
import { ActionHandlerComponent } from './routes/auth/action-handler/action-handler';
import { AuthTokenHttpInterceptorProvider } from '@core/http/auth-token.inteceptor';

// Configure Bugsnag
Bugsnag.start({
  apiKey: '5b25c130dbaf6b59931fe60809b1fd54',
});

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    TeamAdminComponent,
    ReportsComponent,
    CheckinComponent,
    WelcomeComponent,
    ActionHandlerComponent,
    LoginComponent,
    LoginLinkComponent,
    LogoutComponent,
    ForgottenPasswordComponent,
    InviteComponent,
    TermsAndConditionsDialog,
    SurveyCompleteDialog,
    ConfirmDeleteDialog,
    EmailVerificationDialog,
    PasswordResetComponent,
    StatusStepperComponent,
    ActionBarComponent,
    NotesComponent,
    LayoutComponent,
    EmptyLayoutComponent,
    DefaultLayoutComponent,
    UserMenuComponent,
    NavigationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    DragDropModule,
    StoreModule.forRoot(reducers),
  ],
  providers: [
    AuthService,
    DatePipe,
    {
      // Use the 'fill' appearance on Angular Material form fields by default
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill',
      },
    },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    AuthTokenHttpInterceptorProvider,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    TermsAndConditionsDialog,
    SurveyCompleteDialog,
    EmailVerificationDialog,
  ],
})
export class AppModule {}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CapabilityStatusStep } from '@core/models';

@Component({
  selector: 'app-status-stepper',
  templateUrl: './status-stepper.component.html',
  styleUrls: ['./status-stepper.component.scss']
})
export class StatusStepperComponent implements OnChanges {
  @Input() currentStep: CapabilityStatusStep;
  @Input() steps: CapabilityStatusStep[]
  currentStepIndex = -1;

  constructor() { }

  ngOnChanges(): void {
    this.currentStepIndex = -1;

    if (this.currentStep === CapabilityStatusStep.Discuss) {
      this.currentStep = CapabilityStatusStep.Reviewed;
    }
    
    if (this.steps) {
      for (let i = 0; i < this.steps.length; i++) {
        let statusStep = this.steps[i] as CapabilityStatusStep;
        if (statusStep === this.currentStep) {
          this.currentStepIndex = i;
        }
      }
    }
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AuthService } from '@core/auth/auth.service';
import { Store } from '@ngrx/store';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';
import * as fromRoot from '@core/store/app.reducer';
import * as UI from '@core/store/ui.actions';
import { Observable, of, Subject } from 'rxjs';
import { UserService } from '@core/services';
import { UserData } from '@core/models';

@Component({
  selector: 'settings-email',
  templateUrl: './email.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsEmailComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @Output() saved = new EventEmitter<string>();
  emailForm: FormGroup;
  user: UserData;
  isLoading$: Observable<boolean>;

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _userService: UserService,
    private _store: Store<{ ui: fromRoot.State }>
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this._store.select(fromRoot.getIsLoading);

    this._userService.currentUser$
      .pipe(
        map((user) => {
          this.user = user;
          this.emailForm = this._formBuilder.group({
            email: [user.email, [Validators.email, Validators.required]],
            password: ['', Validators.required],
          });
        }),
        take(1)
      )
      .subscribe();
  }

  save() {
    if (this.emailForm.valid) {
      this.emailForm.disable();
      let email = this.emailForm.get('email').value;
      let password = this.emailForm.get('password').value;

      try {
        this._authService
          .getAuth()
          .signInWithEmailAndPassword(this.user.email, password)
          .then(() => {
            let promises = [];

            promises.push(
              this._userService.updateUser({
                ...this.user,
                email,
              })
            );

            promises.push(this._authService.updateEmail(email));

            Promise.all(promises).then((values: any) => {
              this.user.email = email;
              this.emailForm.enable();
              this.saved.emit('Saved!');
            });
          })
          .catch((err) => {
            this.emailForm.enable();
            let message = 'Something unexpected happened!';
            switch (err.code) {
              case 'auth/wrong-password':
                message = 'Looks like you enter the wrong password!';
                break;
              case 'auth/user-not-found':
                message = 'We cant seem to find your account!';
                break;
            }

            this.saved.emit(message);
          });
      } catch (error) {
        this.emailForm.enable();
        this.saved.emit(error);
      }
    }
  }
}

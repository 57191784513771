<app-loading *ngIf="isLoading$ | async"></app-loading>

<ng-container
  *ngIf="!(isLoading$ | async) && userData != null && capabilityData != null"
>
  <app-action-bar
    class="checkin__actionbar"
    [productTerm]="productTerm"
    [capabilities]="capabilities"
    [submitBadgeNumber]="submitBadgeNumber"
    [isReviewer]="isReviewer"
    [isViewer]="isViewer"
    [selectionsComplete]="selectionsComplete"
    [currentStatusStep]="currentStatusStep"
    (statusStepChange)="onStatusStepChange($event)"
    (startNew)="onStartNew($event)"
    (overlayPrevious)="onOverlayPrevious($event)"
    (gotoDashboard)="onGoToDashboard($event)"
  >
  </app-action-bar>
  <mat-drawer-container class="checkin__container" autosize>
    <mat-drawer
      #drawer
      class="checkin__drawer"
      [mode]="sideBarOverlayActive ? 'side' : 'over'"
      position="end"
    >
      <app-notes
        *ngIf="drawerMode === 'Notes'"
        [userId]="currentUser"
        [capability]="currentNoteTakingCapability"
        [capabilityDocId]="capabilityData.id"
        [author]="viewerReviewerData || userData"
        (notesChanged)="onNotesChanged($event)"
        (close)="drawer.toggle()"
      ></app-notes>
      <app-capability-selector
        class="checkin__drawer"
        *ngIf="drawerMode === 'Capabilities'"
        [multiSelect]="true"
        [excludeCurrent]="true"
        [capabilities]="capabilities"
        (overlayChange)="onOverlaysChange($event)"
        (delete)="onDeleteCapabilitySet($event)"
      ></app-capability-selector>
    </mat-drawer>

    <button
      class="absolute top-4 right-9 z-999"
      mat-icon-button
      (click)="onOverlayPrevious($event)"
    >
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>

    <div
      class="checkin"
      [ngClass]="{
        'checkin--readonly': currentStatusComplete || isViewer,
        'checkin--sidebar': sideBarOverlayActive
      }"
      fxLayout="column"
    >
      <div class="checkin__focus-group skills parent">
        <div class="focus-1" fxLayout="row" fxLayoutAlign="left center">
          <div class="checkin__focus-group-wrap">
            <span class="focus-title"
              ><span *ngIf="isReviewer || isViewer"
                >{{ userData.firstName }} {{ userData.lastName }}'s -
              </span>
              Skills:</span
            >
            <span class="focus-subtitle">How I apply my knowledge</span>
          </div>
        </div>
        <div
          class="checkin__capabilities"
          *ngFor="
            let capability of filterByFocus(
              capabilityData.capabilities,
              'Skills'
            );
            index as skillsIndex
          "
        >
          <div class="capability-header">
            <div class="capability-header-wrap">
              <div
                class="checkin__cap-heading capability-heading-div"
                fxLayout="row"
              >
                <h3 class="capability-1 text-xl font-semibold">
                  {{ capability.capabilityName }}
                </h3>
                <button
                  class="note-button"
                  fxLayout="row"
                  matTooltip="Add a note"
                  matTooltipPosition="before"
                  matBadgeOverlap="true"
                  matBadgeSize="small"
                  matBadgePosition="above"
                  matBadgeColor="warn"
                  [matBadge]="
                    applyNoteCount(
                      capabilityData.notes,
                      capability.id || capability.capabilityName
                    )
                  "
                  [matBadgeHidden]="
                    applyNoteCount(
                      capabilityData.notes,
                      capability.id || capability.capabilityName
                    ) === 0
                  "
                  (click)="onNote(capability)"
                >
                  <mat-icon class="note-icon-1" [inline]="true">chat</mat-icon>
                  <!--mat-icon class="note-done-icon-1" [inline]="true">done</mat-icon-->
                  <!--mat-icon class="note-done-icon-1" [inline]="true">done_all</mat-icon-->
                </button>
              </div>
              <p class="checkin__cap-desc">
                {{ capability.capabilityDefinition }}
              </p>
            </div>
          </div>
          <div class="capability-wrap">
            <ng-container *ngFor="let behaviour of capability.behaviours">
              <div
                class="checkin__behaviour behaviour-1"
                (click)="
                  onBehaviourClick(capability.capabilityName, behaviour.label)
                "
                [ngClass]="{
                  'checkin__behaviour--selected': applyBehaviourSelected(
                    capability.id,
                    behaviour.label
                  ),
                  'checkin__behaviour--reviewed': applyBehaviourReview(
                    capability.id,
                    behaviour.label
                  )
                }"
              >
                <div class="optionCard" fxLayout="row">
                  <div class="optionLabel">
                    <b>{{ behaviour.label }}</b>
                  </div>
                  <div class="optionText">
                    {{ replaceOrgName(behaviour.description) }}
                  </div>
                </div>
                <div
                  class="checkin__previous"
                  *ngIf="overlayCapabilities.length"
                >
                  <mat-chip-list aria-label="Status">
                    <div *ngFor="let overlay of overlayCapabilities">
                      <mat-chip
                        *ngIf="
                          applyBehaviourOverlay(
                            overlay,
                            skillsIndex,
                            behaviour.label
                          )
                        "
                        color="unknown"
                        class="checkin__chip checkin__chip--behaviour-1"
                        selected
                        >{{ capabilities.length - overlay.index }}</mat-chip
                      >
                    </div>
                    <mat-chip
                      *ngIf="
                        behaviour.label ===
                        capabilityData.selectedBehaviourLabels[skillsIndex]
                      "
                      color="unknown"
                      class="checkin__chip checkin__chip--behaviour-1"
                      selected
                      >Current</mat-chip
                    >
                  </mat-chip-list>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="checkin__focus-group values parent">
        <div class="focus-2" fxLayout="row" fxLayoutAlign="left center">
          <div class="checkin__focus-group-wrap">
            <span class="focus-title"
              ><span *ngIf="isReviewer || isViewer"
                >{{ userData.firstName }} {{ userData.lastName }}'s - </span
              >Values:</span
            >
            <span class="focus-subtitle">My attitude towards my work</span>
          </div>
        </div>
        <div
          class="checkin__capabilities"
          *ngFor="
            let capability of filterByFocus(
              capabilityData.capabilities,
              'Values'
            );
            index as valuesIndex
          "
        >
          <div class="capability-header">
            <div class="capability-header-wrap">
              <div
                class="checkin__cap-heading capability-heading-div"
                fxLayout="row"
              >
                <h3 class="capability-2 text-xl font-semibold">
                  {{ capability.capabilityName }}
                </h3>
                <button
                  class="note-button"
                  matTooltip="Add a note"
                  matTooltipPosition="before"
                  matBadgeOverlap="true"
                  matBadgeSize="small"
                  matBadgePosition="above"
                  matBadgeColor="warn"
                  [matBadge]="
                    applyNoteCount(
                      capabilityData.notes,
                      capability.id || capability.capabilityName
                    )
                  "
                  [matBadgeHidden]="
                    applyNoteCount(
                      capabilityData.notes,
                      capability.id || capability.capabilityName
                    ) === 0
                  "
                  (click)="onNote(capability)"
                >
                  <mat-icon class="note-icon-2" [inline]="true">chat</mat-icon>
                </button>
              </div>
              <p class="checkin__cap-desc">
                {{ capability.capabilityDefinition }}
              </p>
            </div>
          </div>
          <div class="capability-wrap">
            <ng-container *ngFor="let behaviour of capability.behaviours">
              <div
                class="checkin__behaviour behaviour-2"
                (click)="
                  onBehaviourClick(capability.capabilityName, behaviour.label)
                "
                [ngClass]="{
                  'checkin__behaviour--selected': applyBehaviourSelected(
                    capability.id,
                    behaviour.label
                  ),
                  'checkin__behaviour--reviewed': applyBehaviourReview(
                    capability.id,
                    behaviour.label
                  )
                }"
              >
                <div class="optionCard" fxLayout="row">
                  <div class="optionLabel">
                    <b>{{ behaviour.label }}</b>
                  </div>
                  <div class="optionText">
                    {{ replaceOrgName(behaviour.description) }}
                  </div>
                </div>
                <div
                  class="checkin__previous"
                  *ngIf="overlayCapabilities.length"
                >
                  <mat-chip-list aria-label="Status">
                    <div *ngFor="let overlay of overlayCapabilities">
                      <mat-chip
                        *ngIf="
                          applyBehaviourOverlay(
                            overlay,
                            valuesIndex,
                            behaviour.label
                          )
                        "
                        color="unknown"
                        class="checkin__chip checkin__chip--behaviour-2"
                        selected
                        >{{ capabilities.length - overlay.index }}</mat-chip
                      >
                    </div>
                    <mat-chip
                      *ngIf="
                        behaviour.label ===
                        capabilityData.selectedBehaviourLabels[valuesIndex]
                      "
                      color="unknown"
                      class="checkin__chip checkin__chip--behaviour-2"
                      selected
                      >Current</mat-chip
                    >
                  </mat-chip-list>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="checkin__focus-group knowledge parent">
        <div class="focus-3" fxLayout="row" fxLayoutAlign="left center">
          <div class="checkin__focus-group-wrap">
            <span class="focus-title"
              ><span *ngIf="isReviewer || isViewer"
                >{{ userData.firstName }} {{ userData.lastName }}'s - </span
              >Knowledge:</span
            >
            <span class="focus-subtitle"
              >The {{ userData.track }} things I need to know</span
            >
          </div>
        </div>
        <div
          class="checkin__capabilities"
          *ngFor="
            let capability of filterByFocus(
              capabilityData.capabilities,
              'Knowledge'
            );
            index as knowledgeIndex
          "
        >
          <div class="capability-header">
            <div class="capability-header-wrap">
              <div
                class="checkin__cap-heading capability-heading-div"
                fxLayout="row"
              >
                <h3 class="capability-3 text-xl font-semibold">
                  {{ capability.capabilityName }}
                </h3>
                <button
                  class="note-button"
                  matTooltip="Add a note"
                  matTooltipPosition="before"
                  matBadgeOverlap="true"
                  matBadgeSize="small"
                  matBadgePosition="above"
                  matBadgeColor="warn"
                  [matBadge]="
                    applyNoteCount(
                      capabilityData.notes,
                      capability.id || capability.capabilityName
                    )
                  "
                  [matBadgeHidden]="
                    applyNoteCount(
                      capabilityData.notes,
                      capability.id || capability.capabilityName
                    ) === 0
                  "
                  (click)="onNote(capability)"
                >
                  <mat-icon class="note-icon-3" [inline]="true">chat</mat-icon>
                </button>
              </div>
              <p class="checkin__cap-desc">
                {{ capability.capabilityDefinition }}
              </p>
            </div>
          </div>
          <div class="capability-wrap">
            <ng-container *ngFor="let behaviour of capability.behaviours">
              <div
                class="checkin__behaviour behaviour-3"
                (click)="
                  onBehaviourClick(capability.capabilityName, behaviour.label)
                "
                [ngClass]="{
                  'checkin__behaviour--selected': applyBehaviourSelected(
                    capability.id,
                    behaviour.label
                  ),
                  'checkin__behaviour--reviewed': applyBehaviourReview(
                    capability.id,
                    behaviour.label
                  )
                }"
              >
                <div class="optionCard" fxLayout="row">
                  <div class="optionLabel">
                    <b>{{ behaviour.label }}</b>
                  </div>
                  <div class="optionText">
                    {{ replaceOrgName(behaviour.description) }}
                  </div>
                </div>
                <div
                  class="checkin__previous"
                  *ngIf="overlayCapabilities.length"
                >
                  <mat-chip-list aria-label="Status">
                    <div *ngFor="let overlay of overlayCapabilities">
                      <mat-chip
                        *ngIf="
                          applyBehaviourOverlay(
                            overlay,
                            knowledgeIndex,
                            behaviour.label
                          )
                        "
                        color="unknown"
                        class="checkin__chip checkin__chip--behaviour-3"
                        selected
                        >{{ capabilities.length - overlay.index }}</mat-chip
                      >
                    </div>
                    <mat-chip
                      *ngIf="
                        behaviour.label ===
                        capabilityData.selectedBehaviourLabels[knowledgeIndex]
                      "
                      color="unknown"
                      class="checkin__chip checkin__chip--behaviour-3"
                      selected
                      >Current</mat-chip
                    >
                  </mat-chip-list>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </mat-drawer-container>
</ng-container>

import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'confirm-delete-dialog',
    templateUrl: 'confirm-delete-dialog.html',
  })
  export class ConfirmDeleteDialog {
  
    data: any;

    constructor(public dialogRef: MatDialogRef<ConfirmDeleteDialog>, @Inject(MAT_DIALOG_DATA) data) {
      this.data = data;
    }
  
    onClose(): void {
      this.dialogRef.close('close');
    }

    onDelete(): void {
      this.dialogRef.close('delete');
    }
  }
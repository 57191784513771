import { Member } from './member.model';

// Data array for one focus area - either Skills, Values or Knowledge - which one is stored in label
export interface FocusData {
  data: number[];
  label: string;
}

export interface BubbleRec {
  x: number;
  y: number;
  r: number;
}

// Initial bubble data stored with each radar
export interface BubbleData {
  data: {
    x: number;
    y: number;
    r: number;
  };
  label: string;
}

// Not used - originally intended for bubble component but got type errors so did not specify type in component
/*
export interface BubbleDataArray {
    data: BubbleRec[];
}
*/

export interface BubbleDataArray {
  data: [
    {
      x: number;
      y: number;
      r: number;
    }
  ];
}

export interface BubbleChartDataArray {
  data: BubbleRec[];
  userId: string;
  userName: string;
  dates: Date[];
  member?: Member;
}

// Complete results set for each radar survey
export interface RadarResults {
  id: string;
  date: Date;
  dateStr: string;
  chartLabels: string[]; // Behaviour names for radar labels
  chartData: FocusData[]; // Focus data arrays for Skills, Values and Knowledge (3 elements)
  bubbleData: BubbleData; // Single bubble dataset
}

// Results aggregated for specific user to display in charts
export interface UserResults {
  userId: string;
  userName: string;
  dates: Date[];
  radarDataSet: RadarResults[];
  bubbleDataSet: BubbleRec[]; // All bubble data for user
}

export class myUserResults implements UserResults {
  userId: string;
  userName: string;
  dates: Date[];
  radarDataSet: RadarResults[];
  bubbleDataSet: BubbleRec[];
}
